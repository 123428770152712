import { GUID, ILabel } from "@faro-lotv/ielement-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

/**
 * Removes a label from the project and from all iElements using it
 */
export interface MutationRemoveAllowedLabel extends BaseMutation {
  type: MutationTypes.MutationRemoveAllowedLabel;

  /** True if the label should be removed from all the iElements */
  removeLabelFromAllElements: boolean;

  /** The id of the label to remove */
  labelId: ILabel["id"];
}

/**
 * @returns a mutation to remove a label from the project and from all the iElements that use it
 * @param labelId id of the label to remove
 */
export function createMutationRemoveAllowedLabel(
  labelId: GUID,
): MutationRemoveAllowedLabel {
  return {
    ...createBaseMutation(MutationTypes.MutationRemoveAllowedLabel, undefined),
    removeLabelFromAllElements: true,
    labelId,
  };
}
