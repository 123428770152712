import { assert } from "@faro-lotv/foundation";
import { Group, ObjectLoader } from "three";
import { loadWorker } from "./Workers";

/** Url for the draco wasm plugin to use while decoding compressed GLTF files */
export const DRACO_WASM_URL = "https://www.gstatic.com/draco/versioned/decoders/1.5.6/";

export type GltfLoadRequest = string;

export type GltfLoadSuccess = {
	type: "Success";
	url: string;
	scene: Record<string, unknown>;
};

export type GltfLoadFail = {
	type: "Fail";
	url: string;
	error: Error;
};

export type GltfLoadResponse = GltfLoadFail | GltfLoadSuccess;

/** Callback function to signal that GUI is about to freeze to load the model */
export type ModelAboutToLoad = () => Promise<void>;

type EventListener = (ev: MessageEvent<GltfLoadResponse>) => Promise<void>;

/**
 * Load a GLTF asynchronously offloading the fetching and parsing to a web worker
 *
 * @param url of the gltf file to load
 * @param modelAboutToLoad callback to report 3D model object loading status before potential freezing
 * @param abortSignal optional signal to abort the loading
 * @returns the loaded ThreeJS scene
 * @throws an Error if the loading failed
 */
export async function loadGltf(
	url: string,
	modelAboutToLoad?: ModelAboutToLoad,
	abortSignal?: AbortSignal,
): Promise<Group> {
	const worker = await loadWorker("GltfLoading");

	// Tracking which event listener is added to the worker, to remove it at the end of gltf loading.
	let eventListener: EventListener | undefined = undefined;

	function removeEventListener(): void {
		if (eventListener) {
			worker.removeEventListener("message", eventListener);
			eventListener = undefined;
		}
	}

	const promise: Promise<Group> = new Promise((resolve, reject) => {
		async function el(ev: MessageEvent<GltfLoadResponse>): Promise<void> {
			// If 'loadGltf' is called symultaneously, avoid race conditions by answering only
			// to worker messages that carry the same URL of the requests.
			if (ev.data.url !== url) return;
			switch (ev.data.type) {
				case "Success": {
					if (abortSignal?.aborted) {
						reject(abortSignal.reason);
					} else {
						if (modelAboutToLoad) {
							await modelAboutToLoad();
						}
						const obj = new ObjectLoader().parse(ev.data.scene);
						assert(obj instanceof Group);
						resolve(obj);
					}
					removeEventListener();
					break;
				}
				case "Fail": {
					reject(ev.data.error);
					removeEventListener();
					break;
				}
			}
		}

		worker.addEventListener("message", el);
		eventListener = el;
		worker.postMessage(url);
	});

	return promise;
}
