import { StyledComponent } from "@emotion/styled";
import {
  FormHelperText,
  FormHelperTextProps,
  Input,
  InputLabel,
  InputLabelProps,
  InputProps,
  OutlinedInput,
  OutlinedInputProps,
  styled,
} from "@mui/material";
import { INPUT_DARK_COLORS, INPUT_LIGHT_COLORS } from "./input-colors";
import {
  createOutlinedInputStyles,
  createUnderlinedInputStyles,
} from "./input-styles";

/**
 * @returns An underlined version of the Input base component
 * @param props The props forwarded to the Input
 */
export const UnderlinedInput: StyledComponent<InputProps> = styled(
  Input,
)<InputProps>(({ theme }) =>
  createUnderlinedInputStyles(theme, INPUT_LIGHT_COLORS),
);

/**
 * @returns A dark variant of the Input
 * @param props The props forwarded to the Input
 */
export const UnderlinedInputDark: StyledComponent<InputProps> = styled(
  UnderlinedInput,
)<InputProps>(({ theme }) =>
  createUnderlinedInputStyles(theme, INPUT_DARK_COLORS),
);

/**
 * @returns A dark variant of the OutlinedInput
 * @param props The props forwarded to the OutlinedInput
 */
export const OutlinedInputDark: StyledComponent<OutlinedInputProps> = styled(
  OutlinedInput,
)<OutlinedInputProps>(({ theme }) =>
  createOutlinedInputStyles(theme, INPUT_DARK_COLORS),
);

/**
 * @returns A dark variant of the InputLabel
 * @param props The props forwarded to the InputLabel
 */
export const InputLabelDark: StyledComponent<InputLabelProps> = styled(
  InputLabel,
)<InputLabelProps>(() => ({
  color: INPUT_DARK_COLORS.text,

  "&.Mui-focused": {
    color: INPUT_DARK_COLORS.text,
  },

  "&.Mui-disabled": {
    color: INPUT_DARK_COLORS.text,
  },

  "&.Mui-error": {
    color: INPUT_DARK_COLORS.error,
  },
}));

/**
 * Props definition for the FaroFormHelperText component
 */
export type FaroFormHelperText = FormHelperTextProps & {
  /** Optional text color to use */
  color?: string;
};

/**
 * @returns Our custom variant of the FormHelperText component
 */
export const FaroFormHelperText = styled(FormHelperText)<FaroFormHelperText>(
  ({ color }) => ({
    color,

    "&.Mui-disabled": {
      color,
      opacity: 0.5,
    },
  }),
);

/**
 * @returns A dark variant of the FaroFormHelperText, using neutral[300] as default color
 */
export function FormHelperTextDark({
  color = INPUT_DARK_COLORS.helpText,
  ...rest
}: FaroFormHelperText): JSX.Element {
  return <FaroFormHelperText color={color} {...rest} />;
}

/**
 * @returns A dark variant of the FaroFormHelperText, using neutral[600] as default color
 */
export function FormHelperTextLight({
  color = INPUT_LIGHT_COLORS.helpText,
  ...rest
}: FaroFormHelperText): JSX.Element {
  return <FaroFormHelperText color={color} {...rest} />;
}

/**
 * @returns A dark variant of the FaroFormHelperText for errors, using red[300] as default color
 */
export function FormHelperErrorTextDark({
  color = INPUT_DARK_COLORS.error,
  ...rest
}: FaroFormHelperText): JSX.Element {
  return <FaroFormHelperText color={color} {...rest} />;
}

/**
 * @returns A light variant of the FaroFormHelperText for errors, using red[500] as default color
 */
export function FormHelperErrorTextLight({
  color = INPUT_LIGHT_COLORS.error,
  ...rest
}: FaroFormHelperText): JSX.Element {
  return <FaroFormHelperText color={color} {...rest} />;
}
