import { OrthographicCamera, PerspectiveCamera, Vector3 } from "three";
import { clamp } from "../Utils/Math";
import { memberWithPrivateData } from "../Utils/MemoryUtils";

/**
 * This source file contains code used only by WalkOrbitControls, it should not be exported
 * out of the lotv library.
 */

/** Distance to keep the pivot when the camera is moving "with" the pivot (fly mode) */
export const PIVOT_DISABLED_DISTANCE = 0.01;

/** This constant determines how soon the inertia rotation stops and the camera is steady again. */
const MIN_ROT_INERTIA_SPEED = 5e-4;

/** This constant determines how soon the inertia movement stops and the camera is steady again. */
const MIN_MOV_INERTIA_SPEED = 1e-2;

/** Initial values for a motion state */
const DEFAULT_MOVEMENT_SPEED = new Vector3();
const DEFAULT_CAM_PIVOT_DISTANCE_SPEED = 0;
const DEFAULT_PHI_SPEED = 0;
const DEFAULT_THETA_SPEED = 0;

/**
 * In this object we store the current movement properties of the different motion dimensions of the WalkOrbitControls.
 * Including Translation, Rotation and Pivot-distance speeds.
 */
export class MotionState {
	/** Desired next update movement in 3d space m/s */
	movementSpeed = DEFAULT_MOVEMENT_SPEED.clone();

	/** Desired next update movement toward the pivot in m/s */
	camPivotDistanceSpeed = DEFAULT_CAM_PIVOT_DISTANCE_SPEED;

	/** Desired next update pitch rotation in radians/s */
	phiSpeed = DEFAULT_PHI_SPEED;

	/** Desired next update yaw rotation in radians/s */
	thetaSpeed = DEFAULT_THETA_SPEED;

	/** Resets the motion state to the default values */
	reset(): void {
		this.movementSpeed.copy(DEFAULT_MOVEMENT_SPEED);
		this.camPivotDistanceSpeed = DEFAULT_CAM_PIVOT_DISTANCE_SPEED;
		this.phiSpeed = DEFAULT_PHI_SPEED;
		this.thetaSpeed = DEFAULT_THETA_SPEED;
	}

	/**
	 * Copies the values of a motion state
	 *
	 * @param motionState The motion state to copy
	 */
	assign(motionState: MotionState): void {
		this.movementSpeed.copy(motionState.movementSpeed);
		this.camPivotDistanceSpeed = motionState.camPivotDistanceSpeed;
		this.phiSpeed = motionState.phiSpeed;
		this.thetaSpeed = motionState.thetaSpeed;
	}

	/**
	 * Add the given motionState to this one.
	 *
	 * @param motionState The motionState to add.
	 */
	add(motionState: MotionState): void {
		this.movementSpeed.add(motionState.movementSpeed);
		this.camPivotDistanceSpeed += motionState.camPivotDistanceSpeed;
		this.phiSpeed += motionState.phiSpeed;
		this.thetaSpeed += motionState.thetaSpeed;
	}

	/**
	 * Subtract the given motionState from this one.
	 *
	 * @param motionState The motionState to subtract.
	 */
	sub(motionState: MotionState): void {
		this.movementSpeed.sub(motionState.movementSpeed);
		this.camPivotDistanceSpeed -= motionState.camPivotDistanceSpeed;
		this.phiSpeed -= motionState.phiSpeed;
		this.thetaSpeed -= motionState.thetaSpeed;
	}

	/**
	 * Computes a new value for the input speed, decreasing it according to the
	 * given inertia parameter. If the speed value is below the threshold t,
	 * then the returned speed is zero and the inertial movement stops.
	 *
	 * @param speed The initial speed value
	 * @param inertia The inertia factor used to decrease speed
	 * @param t The minimum speed threshold below which the speed is zero and the camera stops.
	 * @returns The new speed value
	 */
	decreaseSpeed(speed: number, inertia: number, t: number): number {
		if (Math.abs(speed) > t) return speed * inertia;
		return 0;
	}

	/**
	 * Applies friction to the current inertial state.
	 *
	 * @param movementInertia Fraction of movement inertia kept after 1s of friction
	 * @param rotationInertia Fraction of rotation inertia kept after 1s of friction
	 * @param deltaTime simulation deltaTime
	 */
	applyFriction(movementInertia: number, rotationInertia: number, deltaTime: number): void {
		const movementInertiaTick = Math.pow(movementInertia, deltaTime);
		const rotationInertiaTick = Math.pow(rotationInertia, deltaTime);

		// Slow down movement
		this.camPivotDistanceSpeed = this.decreaseSpeed(
			this.camPivotDistanceSpeed,
			movementInertiaTick,
			MIN_MOV_INERTIA_SPEED,
		);
		this.movementSpeed.x = this.decreaseSpeed(this.movementSpeed.x, movementInertiaTick, MIN_MOV_INERTIA_SPEED);
		this.movementSpeed.y = this.decreaseSpeed(this.movementSpeed.y, movementInertiaTick, MIN_MOV_INERTIA_SPEED);
		this.movementSpeed.z = this.decreaseSpeed(this.movementSpeed.z, movementInertiaTick, MIN_MOV_INERTIA_SPEED);

		// Slow down rotation
		this.phiSpeed = this.decreaseSpeed(this.phiSpeed, rotationInertiaTick, MIN_ROT_INERTIA_SPEED);
		this.thetaSpeed = this.decreaseSpeed(this.thetaSpeed, rotationInertiaTick, MIN_ROT_INERTIA_SPEED);
	}

	/**
	 * Accelerates the pivotDistanceSpeed towards a targetSpeed
	 *
	 * @param targetSpeed The target speed of the pivotDistance
	 * @param acceleration The acceleration to use
	 * @param deltaTime simulation deltaTime
	 */
	acceleratePivotDistanceTowards(targetSpeed: number, acceleration: number, deltaTime: number): void {
		const pivotDistanceMaxSpeed = acceleration * deltaTime;

		const camPivotDistanceDiff = targetSpeed - this.camPivotDistanceSpeed;
		this.camPivotDistanceSpeed += clamp(camPivotDistanceDiff, -pivotDistanceMaxSpeed, pivotDistanceMaxSpeed);
	}

	/**
	 * Accelerates the movement speed towards the given target speed.
	 *
	 * @param targetSpeed The target to accelerate towards.
	 * @param acceleration How quickly the target speed will be reached.
	 * @param deltaTime The time since the last frame, to make this framerate independent.
	 */
	accelerateMovementSpeedTowards = memberWithPrivateData(() => {
		// Reuse the same vector to avoid allocations
		const movementSpeedDiff = new Vector3();

		return (targetSpeed: Vector3, acceleration: number, deltaTime: number): void => {
			movementSpeedDiff.copy(targetSpeed).sub(this.movementSpeed);
			this.movementSpeed.add(movementSpeedDiff.clampLength(0, acceleration * deltaTime));
		};
	});

	/**
	 * Accelerates the rotation inertia towards a target rotation speed
	 *
	 * @param targetThetaSpeed The target speed of the theta-rotation
	 * @param targetPhiSpeed The target speed of the phi-rotation
	 * @param acceleration The acceleration to use
	 * @param deltaTime simulation deltaTime
	 */
	accelerateRotationSpeedTowards(
		targetThetaSpeed: number,
		targetPhiSpeed: number,
		acceleration: number,
		deltaTime: number,
	): void {
		const angleAcceleration = acceleration * deltaTime;

		const thetaDiff = targetThetaSpeed - this.thetaSpeed;
		this.thetaSpeed += clamp(thetaDiff, -angleAcceleration, angleAcceleration);

		const phiDiff = targetPhiSpeed - this.phiSpeed;
		this.phiSpeed += clamp(phiDiff, -angleAcceleration, angleAcceleration);
	}
}

/**
 * This class contains all parameters that the interactor tracks to build the current
 * camera pose from them. The interactor remembers the rotation pivot coordinates,
 * the distance camera eye -> rotation pivot along the camera focal axis, the camera
 * pitch angle, the camera yaw angle.
 */
export class CameraParams {
	/** Current pivot point the camera is targeting */
	rotationPivot = new Vector3();

	/** Desired distance from the pivot point, the pivot is considered disabled if this is < PIVOT_DISABLED_DISTANCE */
	camPivotDistance = 1;

	/** camera pitch angle, in radians. */
	phi = 0;

	/** camera yaw angle, in radians */
	theta = 0;

	/**
	 * Assigns the argument object's data to this object.
	 *
	 * @param rhs The assignment's right-hand side
	 */
	assign(rhs: CameraParams): void {
		this.rotationPivot.copy(rhs.rotationPivot);
		this.camPivotDistance = rhs.camPivotDistance;
		this.phi = rhs.phi;
		this.theta = rhs.theta;
	}

	/**
	 *
	 * @param rhs Other camera params to compare movement to
	 * @returns whether this camera is in a different position than rhs
	 */
	moved(rhs: CameraParams): boolean {
		return (
			Math.abs(this.camPivotDistance - rhs.camPivotDistance) > PIVOT_DISABLED_DISTANCE ||
			Math.abs(this.rotationPivot.x - rhs.rotationPivot.x) > PIVOT_DISABLED_DISTANCE ||
			Math.abs(this.rotationPivot.y - rhs.rotationPivot.y) > PIVOT_DISABLED_DISTANCE ||
			Math.abs(this.rotationPivot.z - rhs.rotationPivot.z) > PIVOT_DISABLED_DISTANCE
		);
	}
}

/** Value used to decrease the ortho camera's frustum planes by 10% */
const DEC_ORTHO_PLANE = 0.9;

/** Value used to increase the ortho camera's frustum planes by 10% */
const INC_ORTHO_PLANE = 1.1;

/** Value used in onWheel when calculating the factor to multiply with the ortho camera's frustum planes */
const ONWHEEL_ORTHO_PLANE_MULTIPLIER = 0.0005;

/**
 * Zooms the orthographic field of view of the given camera according to the mouse wheel delta
 *
 * @param camera Orthocamera to zoom
 * @param wheelDeltaY By how much the mouse wheel was scrolled
 */
export function zoomOrthocamera(camera: OrthographicCamera, wheelDeltaY: number): void {
	// When I spin by mouse wheel forward of *one* tick, ev.deltaY is -100!
	const f = clamp(1 + wheelDeltaY * ONWHEEL_ORTHO_PLANE_MULTIPLIER, DEC_ORTHO_PLANE, INC_ORTHO_PLANE);
	camera.top *= f;
	camera.bottom *= f;
	camera.left *= f;
	camera.right *= f;
	camera.updateProjectionMatrix();
}

const ONWHEEL_PERSP_ZOOM_MULTIPLIER = 0.001;

/**
 * Zooms the given perspective camera according to the mouse wheel delta, only if the
 * camera's FOV is not the default one, and the mouse wheel sign contributes to bringing
 * the camera's fov to the default one.
 *
 * @param camera The perspective camera to be perhaps zoomed
 * @param deltaY By how much the mouse wheel was scrolled
 * @param defaultPerspFov Default perspective camera field of view angle, in degrees
 * @returns Whether the camera was zoomed.
 */
export function zoomPerspectiveCamera(camera: PerspectiveCamera, deltaY: number, defaultPerspFov: number): boolean {
	const currFov = camera.getEffectiveFOV();
	// Camera is zoomed iff
	// 1) the current fov is not the default one, and
	// 2) The camera is zoomed in too much AND the wheel action is positive (zoom out)
	//    OR the camera is zoomed out too much AND the wheel action is negative (zoom in)
	const ret = currFov !== defaultPerspFov && deltaY * (defaultPerspFov - currFov) > 0;
	if (ret) {
		camera.fov *= 1 + deltaY * ONWHEEL_PERSP_ZOOM_MULTIPLIER;
		if (deltaY * (defaultPerspFov - camera.getEffectiveFOV()) < 0) {
			camera.fov = defaultPerspFov;
			camera.zoom = 1;
		}
		camera.updateProjectionMatrix();
	}
	return ret;
}

/**
 * Zooms the given perspective camera according to the pinch and zoom gesture ratio, only if the
 * camera's FOV is not the default one, and the pinch and zoom gesture contributes to bringing
 * the camera's fov to the default one.
 *
 * @param camera The perspective camera to be perhaps zoomed
 * @param ratio The zoom ratio. < 1 if the user is zooming in, > 1 if the user is zooming out.
 * @param defaultPerspFov Default perspective camera field of view angle, in degrees
 * @returns Whether the camera was zoomed.
 */
export function pinchAndZoomPerspectiveCamera(
	camera: PerspectiveCamera,
	ratio: number,
	defaultPerspFov: number,
): boolean {
	const currFov = camera.getEffectiveFOV();
	// Camera is zoomed iff
	// 1) the current fov is not the default one, and
	// 2) The camera is zoomed in too much AND the pinch and zoom gesture is zooming out
	//    OR the camera is zoomed out too much AND the pinch and zoom gesture is zooming in
	const ret = currFov !== defaultPerspFov && (ratio - 1) * (defaultPerspFov - currFov) > 0;
	if (ret) {
		camera.fov *= ratio;
		if ((ratio - 1) * (defaultPerspFov - camera.getEffectiveFOV()) < 0) {
			camera.fov = defaultPerspFov;
			camera.zoom = 1;
		}
		camera.updateProjectionMatrix();
	}
	return ret;
}
