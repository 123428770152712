import { CSSInterpolation, SxProps, Theme } from "@mui/material";
import { InputColors } from "./input-colors";

/**
 * @returns the css styles for a MuiOutlinedInput to match the design system
 * @param theme the (partial) theme to use to determine spacings
 * @param colors colors to use for the styles
 */
export function createOutlinedInputStyles(
  theme: Theme,
  colors: InputColors,
): CSSInterpolation {
  return {
    fontSize: "0.875rem",
    color: colors.text,
    background: colors.backgroundOutlined,

    ".MuiInputBase-input": {
      padding: `${theme.spacing(5 / 4)} ${theme.spacing(3 / 2)}`,
    },

    "input::placeholder, textarea::placeholder": {
      color: colors.placeholder,
      fontStyle: "italic",
    },

    "&:hover": {
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: colors.borderHover,
      },
    },

    "&.Mui-focused": {
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: colors.borderFocused,
      },
    },

    "&.Mui-readOnly": {
      color: colors.textReadOnly,
      background: colors.backgroundOutlinedReadOnly,

      ".MuiOutlinedInput-notchedOutline": {
        borderColor: colors.borderReadOnly,
      },
    },

    "&.Mui-error": {
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: colors.error,
        borderWidth: 2,
      },
    },

    "&.Mui-disabled": {
      opacity: 0.5,
      background: colors.backgroundOutlinedDisabled,

      ".MuiOutlinedInput-notchedOutline": {
        borderColor: colors.borderDisabled,
      },

      ".MuiOutlinedInput-input": {
        color: colors.text,
        // This browser specific prop overrides the normal color prop in the default theme
        WebkitTextFillColor: colors.text,

        "::placeholder": {
          color: colors.placeholder,
          WebkitTextFillColor: colors.placeholder,
          opacity: 1,
        },
      },
    },

    ".MuiOutlinedInput-notchedOutline": {
      borderColor: colors.borderIdle,
    },

    "&.MuiInputBase-multiline": {
      padding: 0,

      textarea: {
        fontSize: "0.75rem",
        lineHeight: "initial",
      },
    },
  };
}

/**
 * @returns the css styles for a UnderlinedInput to match the design system
 * @param theme the (partial) theme to use to determine spacings
 * @param colors colors to use for the styles
 */
export function createUnderlinedInputStyles(
  theme: Theme,
  colors: InputColors,
): CSSInterpolation {
  return {
    color: colors.text,
    fontSize: "0.875rem",

    ".MuiInputBase-input": {
      padding: `${theme.spacing(1 / 2)} ${theme.spacing(3 / 2)}`,
    },

    "input::placeholder, textarea::placeholder": {
      color: colors.placeholder,
      fontStyle: "italic",
    },

    "&.MuiInputBase-multiline": {
      padding: 0,
      "textarea, input": {
        fontSize: "0.75rem",
        lineHeight: "initial",

        "::placeholder": {
          fontStyle: "italic",
          color: colors.placeholder,
          opacity: 1,
        },
      },
    },

    "&.MuiInput-underline": inputUnderlineStyle(colors.borderIdle),

    "&:hover": {
      "&.MuiInput-underline": inputUnderlineStyle(colors.borderHover, 1),
    },

    "&.Mui-focused": {
      "&.MuiInput-underline": inputUnderlineStyle(colors.borderFocused, 2),
    },

    "&.Mui-readOnly": {
      "&.MuiInput-underline": inputUnderlineStyle(colors.borderReadOnly),
    },

    "&.Mui-error": {
      "&.MuiInput-underline": inputUnderlineStyle(colors.error),

      "&.Mui-focused": {
        color: colors.text,
        "&.MuiInput-underline": inputUnderlineStyle(colors.error),
      },
    },

    "&.Mui-disabled": {
      opacity: 0.5,

      "&.MuiInput-underline": {
        ...inputUnderlineStyle(colors.borderDisabled),
        "&.Mui-error": inputUnderlineStyle(colors.error),
      },

      "textarea, input": {
        color: colors.text,
        // This browser specific prop overrides the normal color prop in the default theme
        WebkitTextFillColor: colors.text,
      },
    },
  };
}

/**
 * @returns the style rules for the underline of the Input
 * @param color the color to use for the underline
 * @param size optional size(px) of the underline
 */
function inputUnderlineStyle(color: string, size?: number): SxProps {
  return {
    ":before, :after": {
      borderBottomColor: color,
      borderBottomStyle: "solid",
      ...(size && { borderBottomWidth: size }),
    },
  };
}
