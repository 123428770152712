import {
  TomographicModelPass as LotvTomographicModel,
  TOMOGRAPHIC_COLOR,
  TOMOGRAPHIC_OPACITY,
  TOMOGRAPHIC_POINT_COUNT_BIAS,
} from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { useEffect, useState } from "react";
import { Camera, Color, ColorRepresentation, Object3D, Scene } from "three";
import {
  useForwardProp,
  useForwardPropIfDefined,
} from "../../hooks/use-forward-prop";
import { attachPass } from "../attach-utils";

export type TomographicModelPassProps = {
  /** The camera that is rendering the scene */
  camera?: Camera;
  /** The scene containing the point cloud */
  scene?: Scene;
  /** the opacity that the tomographic rendering has when the minimum point count is met on a pixel */
  opacity?: number;
  /** the color used to render the walls and edges of the point cloud */
  tomographicColor?: ColorRepresentation;
  /** Set the bias used for thresholding the number of points */
  bias?: number;

  /** The function used to filter object to be processed in the pass*/
  filter?(o: Object3D): boolean;
};

/**
 * @returns An EffectPipeline pass suitable to render a point cloud or another model
 * from a nadiral orthographic view drawing the walls and edges and discarding floors and ceilings.
 */
export function TomographicModelPass({
  camera,
  scene,
  opacity = TOMOGRAPHIC_OPACITY,
  tomographicColor = TOMOGRAPHIC_COLOR,
  bias = TOMOGRAPHIC_POINT_COUNT_BIAS,
  filter,
}: TomographicModelPassProps): JSX.Element {
  const defaultCamera = useThree((s) => s.camera);
  const effectCamera = camera ?? defaultCamera;
  const defaultScene = useThree((s) => s.scene);
  const effectScene = scene ?? defaultScene;
  const [pass] = useState(
    () => new LotvTomographicModel(effectScene, effectCamera, filter),
  );

  useForwardProp(pass, "camera", effectCamera);
  useForwardProp(pass, "opacity", opacity);
  useForwardProp(pass, "bias", bias);
  useForwardProp(pass, "scene", effectScene);
  useForwardPropIfDefined(pass, "filter", filter);

  useEffect(() => {
    pass.tomographicColor = new Color(tomographicColor);
  }, [pass, tomographicColor]);

  return <primitive object={pass} attach={attachPass} />;
}
