import { validateNotNullishObject } from "@faro-lotv/foundation";

/**
 * One of the possible types of point cloud stream sent by the backend.
 * These values are defined on the backend side.
 * 0: Potree point cloud
 */
export enum PointCloudStreamTypes {
  Potree = 0,
}

/** Format sent by the backend to describe a generic point cloud stream */
export type PointCloudStreamDescription = {
  /** The type of point cloud stream, i.e. 0 is a Potree cloud */
  type: PointCloudStreamTypes;
  /** The url to the metadata json describing the cloud */
  metaDataUrl: string;
  /** The url to the binary file describing the tree hierarchy */
  hierarchyUrl: string;
  /** The url to the binary file containing the point cloud data */
  octreeURL: string;
};

/**
 * @returns True if the generic record describes the urls of a point cloud stream
 * @param data The gneric input record
 */
export function isPotreeURLs(
  data: unknown,
): data is PointCloudStreamDescription {
  return (
    validateNotNullishObject(data, "PointCloudStreamDescription") &&
    typeof data.type === "number" &&
    typeof data.metaDataUrl === "string" &&
    typeof data.hierarchyUrl === "string" &&
    typeof data.octreeURL === "string"
  );
}
