const LOGGING = {
  enabled: false,
};

/**
 * Enable or disable the diagnostic logging for the IElement type guards
 * It can be useful to investigate why a type guard fails but imposes a too big overhead
 * in production
 *
 * @param enabled true to enable logging of failure for IElement type guards
 */
export function toggleGuardsLogging(enabled: boolean): void {
  LOGGING.enabled = enabled;
}

/** @returns True if IElement type guards logging is enabled */
export function isGuardsLoggingEnabled(): boolean {
  return LOGGING.enabled;
}

/**
 * Log a type failure to log if LOGGING is enabled
 *
 * @param message message to log
 * @param args additional arguments to log
 */
export function log(message: string, ...args: unknown[]): void {
  // TODO: Re enable when we implement a proper logging system https://faro01.atlassian.net/browse/SWEB-459
  // eslint-disable-next-line no-console
  if (LOGGING.enabled) console.error(message, ...args);
}
