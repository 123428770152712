import { MaterialParameters, ShaderMaterial, Texture, Vector2 } from "three";
import frag from "../Shaders/Fxaa.frag";
import vert from "../Shaders/TexturedQuad.vert";
import { makeUniform } from "./Uniforms";

export interface FxaaMaterialParameters extends MaterialParameters {
	/**
	 * Resolution to set initially to the material.
	 *
	 * @defaultValue 0X000000 (black/none)
	 */
	resolution?: Vector2 | undefined;
}

/**
 * A ThreeJS effect pass to apply Fx Anti-Aliasing
 */
export class FxaaMaterial extends ShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;
	override uniforms = {
		uColorTex: makeUniform<Texture | null>(null),
		uResolution: makeUniform(new Vector2()),
	};

	/**
	 * Default constructor
	 *
	 * @param newResolution - Screen resolution to set in the shader
	 */
	setResolution(newResolution: Vector2): void {
		this.uniforms.uResolution.value = newResolution;
	}

	/**
	 * Default constructor
	 *
	 * @param parameters An object with one or more properties defining the material's appearance.
	 */
	constructor(parameters: Partial<FxaaMaterialParameters> = {}) {
		super();
		this.setResolution(parameters.resolution ?? new Vector2(0, 0));
	}
}
