import { EMPTY_ARRAY } from "@faro-lotv/foundation";
import { Divider, Stack, StackProps } from "@mui/material";
import { Fragment, forwardRef, useMemo } from "react";
import { neutral } from "../colors";

type ToolbarProps = StackProps & {
  /**
   * true if the toolbar should allow overflow (e.g. if a badge on a button may overflow the width of the toolbar)
   * Otherwise, the toolbar will hide overflow.
   */
  allowOverflow?: boolean;
};

/**
 * @returns a wrapper for ToolGroups with the proper FARO style
 */
export const Toolbar = forwardRef<HTMLDivElement, ToolbarProps>(
  function Toolbar(
    { children, sx = [], allowOverflow, direction = "column" },
    ref,
  ): JSX.Element {
    // Don't add separators when there are empty children
    const filteredChildren = useMemo(() => {
      if (!children) return EMPTY_ARRAY;
      if (Array.isArray(children)) {
        return children.filter((child) => !!child);
      }
      return [children];
    }, [children]);

    return (
      <Stack
        ref={ref}
        direction={direction}
        sx={[
          {
            backdropFilter: "blur(4px) brightness(40%)",
            borderRadius: 1.5,
            overflow: allowOverflow ? undefined : "hidden",
            backgroundColor: neutral[999],
          },
          // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {filteredChildren.map((group, index) => (
          <Fragment key={index}>
            {group}
            {filteredChildren.length > 1 &&
              index !== filteredChildren.length - 1 && (
                <Separator direction={direction} />
              )}
          </Fragment>
        ))}
      </Stack>
    );
  },
);

type SeparatorProps = {
  direction?: ToolbarProps["direction"];
};

/** @returns a separator that can be used to separate ToolGroups in a Toolbar */
function Separator({ direction = "column" }: SeparatorProps): JSX.Element {
  const columnDirection =
    direction === "column" || direction === "column-reverse";

  return (
    <Stack
      sx={
        columnDirection
          ? {
              width: "100%",
              my: 0.25,
            }
          : {
              // height: "100%" does not work in this direction
              alignSelf: "stretch",
              mx: 0.25,
            }
      }
      justifyContent="center"
    >
      <Divider
        orientation={columnDirection ? "horizontal" : "vertical"}
        sx={{
          width: columnDirection ? "80%" : undefined,
          height: columnDirection ? undefined : "80%",
          margin: "auto",
          opacity: 0.2,
          backgroundColor: "white",
          borderWidth: "1px",
        }}
      />
    </Stack>
  );
}
