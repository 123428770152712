/**
 * The (known) error codes for failing background tasks coming from the SCENE worker
 *
 * See https://faro01.atlassian.net/wiki/spaces/HOLO/pages/3539435793/Error+Codes+Overview.
 */
export enum SceneWorkerErrorCode {
  /**
   * When the error does not match any of the predefined error codes. It acts as a catch-all for unexpected or unclassified errors
   */
  unknown = "SCENE_WORKER_Unknown",

  /**
   * When a required element is not found or cannot be retrieved from the project API.
   * This might happen if the element does not exist or there is an issue with the API request.
   */
  elementNotFound = "SCENE_WORKER_ElementNotFound",

  /**
   * While the overall processing was successful, some individual scans failed to process correctly.
   * This might be due to issues with specific scan files or data.
   */
  partialScanProcessingFailure = "SCENE_WORKER_PartialScanProcessingFailure",

  /**
   * Failure during the scan processing stage, resulting in the overall processing being unsuccessful.
   * This is a more severe error compared to PartialScanProcessingFailure.
   */
  scanProcessingFailure = "SCENE_WORKER_ScanProcessingFailure",

  /**
   * When there is a failure in generating the overview map.
   * This might happen for example during the tile generation or while uploading the generated map.
   */
  mapGenerationFailure = "SCENE_WORKER_MapGenerationFailure",
}

/**
 * @returns Wether or not the provided string is a valid SCENE worker error code
 * @param errorCode String to check
 */
export function isSceneWorkerErrorCode(
  errorCode?: string,
): errorCode is SceneWorkerErrorCode {
  return (
    !!errorCode &&
    Object.values<string>(SceneWorkerErrorCode).includes(errorCode)
  );
}

/**
 * @returns An error message for an error code returned by the SCENE worker.
 * @param errorCode The error code to generate an error message for. If not given, a generic message will be shown.
 */
export function sceneWorkerTaskErrorToUserMessage(
  errorCode?: SceneWorkerErrorCode,
): string {
  switch (errorCode) {
    case SceneWorkerErrorCode.scanProcessingFailure:
      return "Conversion failed.";
    case SceneWorkerErrorCode.partialScanProcessingFailure:
      return "Conversion partially failed.";
    case SceneWorkerErrorCode.mapGenerationFailure:
      return "Overview map failed to generate.";
    case SceneWorkerErrorCode.elementNotFound:
      return "The element was not found";
    case SceneWorkerErrorCode.unknown:
    case undefined:
      return "An unexpected error occurred.";
  }
}
