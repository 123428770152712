import { TypedEvent, exponentialBackOff, retry } from "@faro-lotv/foundation";
import { GUID } from "@faro-lotv/ielement-types";
import SparkMD5 from "spark-md5";
import { BackgroundTaskState } from "../progress-api";
import { CoreApiClient } from "./core-api-client";
import {
  ChunkUploadDescription,
  ChunkUploadRequestDescription,
} from "./core-api-responses";

const SMALLEST_ERROR_CODE = 300;

/**
 * Callback called by the upload to finalize an upload. If it fails the upload is considered failed
 *
 * @param downloadUrl is the URL of the uploaded file at the remote location
 * @param md5 the hash of the uploaded file
 */
export type FinalizerCallback = (
  downloadUrl: string,
  md5: string,
) => Promise<void>;

/**
 * This class stores information about why a file upload failed.
 */
export class UploadFailedError extends Error {
  /**
   * Constructs a new report of a failed upload
   *
   * @param status The response error status
   * @param statusText The response error status text
   * @param failedChunkStartByte The start byte of the chunk that failed to upload
   * If the start byte is equal to the total size, it means that the upload
   * finalization request failed.
   * @param totSizeBytes Total size of the file to upload
   */
  constructor(
    public status: number,
    public statusText: string,
    public failedChunkStartByte: number,
    public totSizeBytes: number,
  ) {
    super("Error while uploading a file");
  }
}

/**
 * A class to upload a file to a given holobuilder project through the Core API.
 * Useful to e.g. upload a point cloud to a project.
 *
 * Possible future developments of this class:
 * * Add support for retrying uploads of single chunks when they fail for timeout
 * * Add support for retrying the whole upload
 * * Improve error handling since first two API calls may also fail
 * * Add support for interrupting and resuming deliberately an upload.
 */
export class CoreFileUploader {
  #file: File;
  #projectId: GUID;
  #coreApi: CoreApiClient;
  #uploadStart = Date.now();
  #bytesUploaded = 0;
  #progress = 0;
  #state: BackgroundTaskState;

  /** Event emitted when the upload progress advanced. Argument is the current progress from 0 to 100. */
  progressChanged = new TypedEvent<{
    percentage: number;
    expectedEnd: number;
  }>();

  /** Event emitted when the upload completed. The argument is the URL at which the file can be downloaded and the md5 hash of the file. */
  uploadCompleted = new TypedEvent<{ downloadUrl: string; md5: string }>();

  /** Event emitted when the upload fails. The argument conveys available information about the upload error. */
  uploadFailed = new TypedEvent<Error>();

  /**
   *
   * @param file The file to upload
   * @param projectId The ID of the project to upload the file to
   * @param coreApi The Core API client to be used for uploading.
   */
  constructor(file: File, projectId: GUID, coreApi: CoreApiClient) {
    this.#file = file;
    this.#projectId = projectId;
    this.#coreApi = coreApi;
    this.#state = BackgroundTaskState.created;
  }

  /**
   *
   * @param signal An optional AbortSignal to optionally cancel the upload
   * @returns Whether the upload has been canceled by the user.
   */
  #checkCanceled(signal?: AbortSignal): boolean {
    if (signal?.aborted) this.#state = BackgroundTaskState.aborted;
    return this.#state === BackgroundTaskState.aborted;
  }

  /**
   * Upload a single chunk to the backend
   *
   * @param chunk to upload
   * @param spark buffer to compute entire file MD5
   * @param signal to abort the upload
   * @returns true if the chunk was uploaded successfully
   */
  private async uploadChunk(
    chunk: ChunkUploadRequestDescription,
    spark: SparkMD5.ArrayBuffer,
    signal?: AbortSignal,
  ): Promise<boolean> {
    // upload chunk
    const startByte = chunk.bytes.start;
    const endByte = chunk.bytes.start + chunk.bytes.length;
    const blob = this.#file.slice(startByte, endByte);
    const blobData = await blob.arrayBuffer();
    spark.append(blobData);
    const response = await retry(
      () =>
        fetch(chunk.url, {
          method: chunk.method,
          body: blobData,
          headers: {
            ...chunk.headers,
          },
          signal,
        }),
      {
        max: 5,
        delay: exponentialBackOff,
      },
    );
    // check cancellation
    if (this.#checkCanceled(signal)) return false;
    // handle error
    if (!response.ok || response.status >= SMALLEST_ERROR_CODE) {
      this.#state = BackgroundTaskState.failed;
      this.uploadFailed.emit(
        new UploadFailedError(
          response.status,
          response.statusText,
          startByte,
          this.#file.size,
        ),
      );
      return false;
    }
    // update progress
    this.#bytesUploaded += chunk.bytes.length;
    this.#progress = Math.floor((this.#bytesUploaded * 100) / this.#file.size);

    const speed = this.#bytesUploaded / (Date.now() - this.#uploadStart);
    const remainingTime = (this.#file.size - this.#bytesUploaded) / speed;
    const expectedEnd = Date.now() + remainingTime;

    this.progressChanged.emit({ percentage: this.#progress, expectedEnd });
    return true;
  }

  /**
   * Finalize the upload to the backend
   *
   * @param chunkUploadDescription the descriptor for the entire upload
   * @param spark buffer to compute entire file MD5
   * @param signal to abort the upload
   * @param finalizer function to commit this upload to the project
   */
  private async finalizeUpload(
    chunkUploadDescription: ChunkUploadDescription,
    spark: SparkMD5.ArrayBuffer,
    signal?: AbortSignal,
    finalizer?: FinalizerCallback,
  ): Promise<void> {
    // Finalize
    const { finalize } = chunkUploadDescription;
    const ret = await fetch(finalize.url, {
      method: finalize.method,
      headers: { ...finalize.headers },
      body: finalize.body,
      signal,
    });

    // check cancellation
    if (this.#checkCanceled(signal)) return;
    // check error
    if (!ret.ok || ret.status >= SMALLEST_ERROR_CODE) {
      this.#state = BackgroundTaskState.failed;
      this.uploadFailed.emit(
        new UploadFailedError(
          ret.status,
          ret.statusText,
          this.#file.size,
          this.#file.size,
        ),
      );
      return;
    }

    const { downloadUrl } = chunkUploadDescription;
    const md5 = spark.end();
    if (finalizer) {
      await finalizer(downloadUrl, md5);
    }

    // Finished!
    this.#state = BackgroundTaskState.succeeded;
    this.uploadCompleted.emit({
      downloadUrl,
      md5,
    });
  }

  /**
   * Performs the uploading. This function does not throw any exceptions,
   * all exceptions are caught internally and sent via the 'uploadFailed' signal.
   *
   * @param signal An optional AbortSignal to optionally cancel the upload
   * @param finalizer An optional function to call to finalize an upload, if it fails the upload is considered failed
   */
  async doUpload(
    signal?: AbortSignal,
    finalizer?: FinalizerCallback,
  ): Promise<void> {
    if (
      this.#state !== BackgroundTaskState.created &&
      this.#state !== BackgroundTaskState.scheduled
    ) {
      return;
    }

    this.#state = BackgroundTaskState.started;
    this.#uploadStart = Date.now();

    try {
      // Get token for project
      const token = await this.#coreApi.getUserProjectToken(this.#projectId);
      // check cancellation
      if (this.#checkCanceled(signal)) return;

      // Get description on how to split the chunked upload.
      const chunkUploadDescription = await this.#coreApi.getChunkUploadData(
        this.#projectId,
        this.#file.type || "application/octet-stream",
        this.#file.name,
        this.#file.size,
        token,
      );
      // check cancellation
      if (this.#checkCanceled(signal)) return;

      // initialize MD5 computation
      const spark = new SparkMD5.ArrayBuffer();

      // progressively upload all file chunks
      for (const chunk of chunkUploadDescription.chunks) {
        if (!(await this.uploadChunk(chunk, spark, signal))) {
          return;
        }
      }

      await this.finalizeUpload(
        chunkUploadDescription,
        spark,
        signal,
        finalizer,
      );
    } catch (err) {
      if (this.#checkCanceled(signal)) return;
      this.#state = BackgroundTaskState.failed;
      if (err instanceof Error) {
        this.uploadFailed.emit(err);
      } else {
        this.uploadFailed.emit(
          new Error("Upload failed because of an unknown error."),
        );
      }
    }
  }

  /** @returns The upload progress from 0 to 100. */
  get progress(): number {
    return this.#progress;
  }

  /** @returns the file upload state */
  get state(): BackgroundTaskState {
    return this.#state;
  }
}
