import { assert } from "@faro-lotv/foundation";
import {
	Camera,
	EventDispatcher,
	MOUSE,
	OrthographicCamera,
	PerspectiveCamera,
	Vector2,
	Vector3,
	Vector4,
} from "three";
import { pixels2m } from "../Utils";
import { clamp } from "../Utils/Math";
import { DoubleTouch, PointerCoords, TouchEvents } from "./TouchEvents";

const DEFAULT_INERTIA = 0.9;
const MIN_INTERTIA_SPEED = 1e-4;
const MIN_TRANSLATING_SPEED = 1e-6;

// Minimum duration of the zoom animation, in seconds
const MIN_ZOOM_DURATION = 0.25;

// Zoom speed on mouse wheel in ratios / second
// For example, zoomSpeed = 2 would mean that each second
// of 'zooming in' would roughly divide by two the size of
// the orthographic projection.
const ZOOM_SPEED = 1.7;

// Min and max values for FOV
const MIN_FOV = 20;
const MAX_FOV = 150;

// Value used for the planes when creating an ortho camera
const ORTHO_FRUSTUM_HALF_SIZE = 50;

enum InteractionState {
	ViewStill = 0,
	UserDraggingMouse = 1,
	SingleTouch = 2,
	DoubleTouch = 3,
	MovingByInertia = 4,
}

enum MovementType {
	Translating = 0,
	Rotating = 1,
}

class CameraParams {
	// Rotation angle around the Z axis
	theta = 0;
	// camera position
	position = new Vector3();

	assign(rhs: CameraParams): void {
		this.theta = rhs.theta;
		this.position.copy(rhs.position);
	}
}

class InertiaParams {
	movementSpeed = new Vector3();
	thetaSpeed = 0;
	movementInertia = DEFAULT_INERTIA;
	rotationInertia = DEFAULT_INERTIA;

	computeInertialSpeed(m: MovementType): boolean {
		switch (m) {
			case MovementType.Translating:
				this.movementSpeed.multiplyScalar(this.movementInertia);
				return (
					Math.abs(this.movementSpeed.x) > MIN_INTERTIA_SPEED ||
					Math.abs(this.movementSpeed.y) > MIN_INTERTIA_SPEED
				);
			case MovementType.Rotating:
				this.thetaSpeed *= this.rotationInertia;
				return Math.abs(this.thetaSpeed) > MIN_INTERTIA_SPEED;
		}
	}
}

/**
 * Mouse/touch controls suitable for overview maps. The camera always looks in the nadiral direction.
 * It can be translated, rotated only around the vertical direction, and zoomed.
 */
export class Map2DControls extends EventDispatcher {
	// Handling touch events
	#touchEvents = new TouchEvents();
	// The camera to be controlled
	#camera: Camera;
	// Pointer down pos
	#pointerDownPos = new Vector2();
	// Point position at the previous event
	#prevPos = new Vector2();
	// Interaction state
	#interactionState = InteractionState.ViewStill;
	// Movement type
	#movementType = MovementType.Translating;
	// the data in the member #currCamera are always synced with the current camera
	#currCamera = new CameraParams();
	// Camera parameters when the movement started
	#camOnPointerDown = new CameraParams();
	// lastCamera is needed to compute the movement speed between lastCamera and currCamera for the inertia effect
	#lastCamera = new CameraParams();
	// How we convert movements in pixels from movements in meters
	#pix2meters = 1;
	// Params needed for inertia movement computation.
	#inertiaParams = new InertiaParams();

	// zoom sign. 1 stands for zoom out, -1 for zoom in.
	#zoomSign = 1;
	// Seconds since last mouse wheel event
	#timeSinceZoomed = 1000;

	/** Enable free rotation when dragging the right mouse button */
	enableRotation = true;

	/** The viewport height of the scene this control is working on, defaults to canvas height */
	viewportHeight?: number;

	/** The height of the reference plane we want to pan over, default to 0 */
	referencePlaneHeight?: number;

	/**
	 * Whether this is the primary control of the view.
	 *
	 * If set to `false`, interaction with the left mouse button will be disabled.
	 *
	 * @default true
	 */
	isPrimaryControl = true;

	/**
	 * Construct the control class
	 *
	 * @param camera The camera to control
	 * @param target The element to listen for events
	 */
	constructor(camera?: Camera, target?: HTMLElement) {
		super();

		this.#camera =
			camera ??
			new OrthographicCamera(
				-ORTHO_FRUSTUM_HALF_SIZE,
				ORTHO_FRUSTUM_HALF_SIZE,
				ORTHO_FRUSTUM_HALF_SIZE,
				-ORTHO_FRUSTUM_HALF_SIZE,
				1,
				1000,
			);
		this.#syncCameraWithInteractor();

		this.onTouchStart = this.onTouchStart.bind(this);
		this.onTouchEnd = this.onTouchEnd.bind(this);
		this.onSingleTouchMove = this.onSingleTouchMove.bind(this);
		this.onDoubleTouchRotate = this.onDoubleTouchRotate.bind(this);
		this.onPinchAndZoom = this.onPinchAndZoom.bind(this);
		this.onMouseDown = this.onMouseDown.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
		this.onMouseDrag = this.onMouseDrag.bind(this);
		this.onWheel = this.onWheel.bind(this);

		this.#touchEvents.touchStarted.on(this.onTouchStart);
		this.#touchEvents.touchEnded.on(this.onTouchEnd);
		this.#touchEvents.singleTouchMoved.on(this.onSingleTouchMove);
		this.#touchEvents.doubleTouchRotated.on(this.onDoubleTouchRotate);
		this.#touchEvents.pinchAndZoomed.on(this.onPinchAndZoom);
		this.#touchEvents.mousePressed.on(this.onMouseDown);
		this.#touchEvents.mouseReleased.on(this.onMouseUp);
		this.#touchEvents.mouseDragged.on(this.onMouseDrag);
		this.#touchEvents.mouseWheel.on(this.onWheel);

		if (target) {
			this.attach(target);
		}
	}

	/**
	 * This function computes the constant that converts pixel movements into meter movements
	 * needed for computation of touch interactions such as pinch and dolly and two touch translate.
	 */
	#updatePix2Meters(): void {
		const height = this.viewportHeight ?? this.#touchEvents.elementHeight;
		const distanceToPlane = this.camera.up.dot(this.camera.position) - (this.referencePlaneHeight ?? 0);
		this.#pix2meters = pixels2m(1, this.#camera, height, distanceToPlane);
	}

	/** @returns whether the user mouse drag is really moving the camera with a given speed. */
	#movingWithSpeed(): boolean {
		switch (this.#movementType) {
			case MovementType.Translating:
				return this.#inertiaParams.movementSpeed.lengthSq() > MIN_TRANSLATING_SPEED;
			case MovementType.Rotating:
				return Math.abs(this.#inertiaParams.thetaSpeed) > MIN_INTERTIA_SPEED;
		}
	}

	/**
	 *
	 */
	#updateCamera(): void {
		if (!this.enabled) return;
		this.#camera.position.copy(this.#currCamera.position);
		// We need to rotate around the camera's Z axis regardless
		// of whether we are Z up or Y up. In fact, the openGL convention
		// that the camera looks along the -Z direction is valid also
		// if the global vertical is the Y axis.
		this.#camera.rotation.z = this.#currCamera.theta;
	}

	/**
	 * Copy the camera status into the current camera of the controls
	 */
	#syncCameraWithInteractor(): void {
		this.#currCamera.position.copy(this.#camera.position);
		this.#currCamera.theta = this.#camera.rotation.z;
	}

	/**
	 *
	 * @param deltaTime Elapsed time since last update
	 */
	#storeMovementSpeed(deltaTime: number): void {
		const invD = 1 / deltaTime;
		this.#inertiaParams.movementSpeed.subVectors(this.#currCamera.position, this.#lastCamera.position);
		this.#inertiaParams.movementSpeed.multiplyScalar(invD);
		this.#inertiaParams.thetaSpeed = (this.#currCamera.theta - this.#lastCamera.theta) * invD;
		this.#lastCamera.assign(this.#currCamera);
	}

	/**
	 *
	 * @param deltaTime Elapsed time since last update
	 */
	#computeInertialMovement(deltaTime: number): void {
		switch (this.#movementType) {
			case MovementType.Rotating:
				this.#currCamera.theta += this.#inertiaParams.thetaSpeed * deltaTime;
				break;
			case MovementType.Translating:
				{
					const ds = this.#inertiaParams.movementSpeed.clone();
					ds.multiplyScalar(deltaTime);
					this.#currCamera.position.add(ds);
				}
				break;
		}
	}

	/**
	 *
	 * @param camera The persepective camera used by the control
	 * @param fov The new fov to assign to the perspective camera after a zoom event
	 */
	#assignClampedPerspFov(camera: PerspectiveCamera, fov: number): void {
		const cfov = clamp(fov, MIN_FOV, MAX_FOV);
		camera.fov = cfov;
	}

	/**
	 *
	 * @param x New x coordinate of pointer that moved
	 * @param y New y coordinate of pointer that moved
	 */
	#doTranslate(x: number, y: number): void {
		this.#updatePix2Meters();
		const me = this.#camera.matrixWorld.elements;
		const Xaxis = new Vector3(me[0], me[1], me[2]);
		const Yaxis = new Vector3(me[4], me[5], me[6]);
		const dy = (this.#pointerDownPos.x - x) * this.#pix2meters;
		const dx = (y - this.#pointerDownPos.y) * this.#pix2meters;
		this.#currCamera.position.copy(this.#camOnPointerDown.position);
		this.#currCamera.position.add(Xaxis.multiplyScalar(dy));
		this.#currCamera.position.add(Yaxis.multiplyScalar(dx));
	}

	/**
	 * Rotate the camera around the screen center
	 *
	 * @param x The x coordinate of the mouse position (in pixels)
	 * @param y The y coordinate of the mouse position (in pixels)
	 */
	#doRotate(x: number, y: number): void {
		if (!this.enableRotation) {
			return;
		}

		const domRect = this.#touchEvents.element?.getBoundingClientRect();
		if (!domRect) {
			return;
		}

		/** Rotate aroun the screen center */
		const screenCenter = new Vector2(domRect.width * 0.5, domRect.height * 0.5);
		const initialPosition = new Vector2().subVectors(this.#prevPos, screenCenter).normalize();
		const currentPosition = new Vector2().subVectors(new Vector2(x, y), screenCenter).normalize();

		const factor = initialPosition.cross(currentPosition) < 0 ? -1 : 1;
		const dotProduct = Math.min(1, Math.max(-1, initialPosition.dot(currentPosition)));
		const thetaIncrement = factor * Math.acos(dotProduct);

		if (Number.isNaN(thetaIncrement)) {
			console.warn("Invalid angle");
			return;
		}

		this.#currCamera.theta += thetaIncrement;
	}

	/**
	 * Zoom on the mouse cursor or on the center of the double touch gesture
	 *
	 * @param mouse The position on which the camera is zoomed (in piexels)
	 * @param ratio The amount of zooming
	 */
	#doZoom(mouse: Vector2, ratio: number): void {
		assert(
			this.#camera instanceof PerspectiveCamera || this.#camera instanceof OrthographicCamera,
			"Unsupported camera",
		);
		if (!this.#touchEvents.element) {
			return;
		}

		// Compute the new camera position in order to keep the central point at the same pixel
		const domRect = this.#touchEvents.element.getBoundingClientRect();
		const ndcTarget = new Vector3((mouse.x / domRect.width) * 2 - 1, 1 - (2 * mouse.y) / domRect.height, 0);

		// Target in camera coordinates before zooming
		const target = ndcTarget.clone().applyMatrix4(this.#camera.projectionMatrixInverse);
		target.z = 0;

		// Zoom
		if (this.#camera instanceof PerspectiveCamera) {
			const fov = this.#camera.fov * ratio;
			this.#assignClampedPerspFov(this.#camera, fov);
			this.#camera.updateProjectionMatrix();
		} else {
			this.#camera.top *= ratio;
			this.#camera.bottom *= ratio;
			this.#camera.left *= ratio;
			this.#camera.right *= ratio;
			this.#camera.updateProjectionMatrix();
		}

		// The same target in camera coordinates after zooming
		const targetAfterZoom = ndcTarget.clone().applyMatrix4(this.#camera.projectionMatrixInverse);
		targetAfterZoom.z = 0;

		// Compute the camera movement
		const movement = target.sub(targetAfterZoom);

		// Movement in 3D
		const worldMovement = new Vector4(movement.x, movement.y, movement.z, 0).applyMatrix4(this.#camera.matrixWorld);

		// The wheel event does not trigger the "#updateCamera" function
		// so we need to manually update both "#currCamera" and "#camera"
		this.#currCamera.position.copy(this.#camera.position);
		this.#currCamera.position.add(new Vector3(worldMovement.x, worldMovement.y, worldMovement.z));
		this.#camera.position.copy(this.#currCamera.position);
	}

	/**
	 *
	 * @param deltaTime Time since last frame, in seconds.
	 */
	#zoomAnimation(deltaTime: number): void {
		const MAX_ZOOM_RATIO = 0.3;

		this.#timeSinceZoomed += deltaTime;
		if (this.#timeSinceZoomed < MIN_ZOOM_DURATION) {
			this.#doZoom(
				this.#touchEvents.mouseCoords.position,
				1.0 + clamp(this.#zoomSign * ZOOM_SPEED * deltaTime, -MAX_ZOOM_RATIO, MAX_ZOOM_RATIO),
			);
		}
	}

	/**
	 * Synchronizes the internal interaction state with the number of touches that are
	 * currently being followed. Called whenever a touch starts or ends.
	 */
	#touchesCount2InteractionState(): void {
		switch (this.#touchEvents.pointersCount) {
			case 1:
				this.#interactionState = InteractionState.SingleTouch;
				break;
			case 2:
				this.#interactionState = InteractionState.DoubleTouch;
				break;
			default:
				this.#interactionState = InteractionState.ViewStill;
				break;
		}
	}

	/**
	 *
	 * @param touch The touch started
	 */
	private onTouchStart(touch: PointerCoords): void {
		this.#touchesCount2InteractionState();
		if (this.#interactionState === InteractionState.SingleTouch) {
			this.#pointerDownPos.copy(touch.position);
			this.#camOnPointerDown.assign(this.#currCamera);
		}
	}

	/**
	 *
	 */
	private onTouchEnd(): void {
		this.#touchesCount2InteractionState();
	}

	/**
	 *
	 * @param e Info about the touch moved
	 */
	private onSingleTouchMove(e: PointerCoords): void {
		if (this.#interactionState !== InteractionState.SingleTouch) return;
		if (!e.target) return;
		this.#doTranslate(e.position.x, e.position.y);
	}

	/**
	 *
	 * @param e Info about double touch rotate event
	 */
	private onDoubleTouchRotate(e: DoubleTouch): void {
		if (!this.enableRotation) {
			return;
		}
		this.#currCamera.theta += e.rotationAngle;
	}

	/**
	 *
	 * @param e Info about the double touch event
	 */
	private onPinchAndZoom(e: DoubleTouch): void {
		if (!e.mainTouch.target) return;
		const midPoint = new Vector2().addVectors(e.mainTouch.position, e.secondTouch.position).multiplyScalar(0.5);
		this.#doZoom(midPoint, e.ratio);
	}

	/**
	 *
	 * @param e The mouse event
	 */
	private onMouseDown(e: MouseEvent): void {
		this.#interactionState = InteractionState.UserDraggingMouse;
		switch (e.button) {
			case MOUSE.LEFT:
				if (this.isPrimaryControl) {
					this.#movementType = MovementType.Translating;
				} else {
					this.#interactionState = InteractionState.ViewStill;
				}
				break;
			case MOUSE.MIDDLE:
			case MOUSE.RIGHT:
				this.#movementType = MovementType.Translating;
				break;
		}
		if (e.target === null) return;
		const mouse = this.#touchEvents.mouseCoords.position;
		this.#pointerDownPos.set(mouse.x, mouse.y);
		this.#prevPos.set(mouse.x, mouse.y);
		this.#lastCamera.assign(this.#currCamera);
		this.#camOnPointerDown.assign(this.#currCamera);
	}

	/**
	 *
	 * @param e The mouse event
	 */
	private onMouseDrag(e: MouseEvent): void {
		if (this.#interactionState !== InteractionState.UserDraggingMouse) return;
		if (!e.target) return;
		const mouse = this.#touchEvents.mouseCoords.position;
		switch (this.#movementType) {
			case MovementType.Rotating:
				this.#doRotate(mouse.x, mouse.y);
				break;
			case MovementType.Translating:
				this.#doTranslate(mouse.x, mouse.y);
				break;
		}
		this.#prevPos.set(mouse.x, mouse.y);
	}

	/**
	 *
	 * @param e The mouse event
	 */
	private onMouseUp(e: MouseEvent): void {
		const finishedTranslation =
			((e.button === MOUSE.LEFT && this.isPrimaryControl) || e.button === MOUSE.MIDDLE) &&
			this.#movementType === MovementType.Translating;
		const finishedRotation = e.button === MOUSE.RIGHT && this.#movementType === MovementType.Rotating;
		const userDragFinished = finishedTranslation || finishedRotation;
		if (!userDragFinished) return;
		this.#interactionState = this.#movingWithSpeed()
			? InteractionState.MovingByInertia
			: InteractionState.ViewStill;
	}

	/**
	 *
	 * @param e The mouse event
	 */
	private onWheel(e: WheelEvent): void {
		if (e.target === null) return;
		this.#timeSinceZoomed = 0;
		this.#zoomSign = Math.sign(e.deltaY);
	}

	/**
	 * Attach this controls to an element to receive events from
	 *
	 * @param target The element to listen for events
	 */
	attach(target: HTMLElement): void {
		this.#touchEvents.attach(target);
	}

	/**
	 * If attached to an element, detach all event linsteners
	 */
	detach(): void {
		this.#touchEvents.detach();
	}

	/**
	 * @returns the handler of the touch events. Useful if another class wants to be
	 * notified e.g. when a tap gesture happens.
	 */
	get touchEvents(): TouchEvents {
		return this.#touchEvents;
	}

	/**
	 *
	 * @param deltaTime Time elapsed since last interactor update.
	 */
	public update(deltaTime: number): void {
		this.#zoomAnimation(deltaTime);
		switch (this.#interactionState) {
			case InteractionState.ViewStill:
				this.#syncCameraWithInteractor();
				return;
			case InteractionState.SingleTouch:
			case InteractionState.DoubleTouch:
				this.#updateCamera();
				break;
			case InteractionState.UserDraggingMouse:
				this.#storeMovementSpeed(deltaTime);
				this.#updateCamera();
				break;
			case InteractionState.MovingByInertia:
				if (this.#inertiaParams.computeInertialSpeed(this.#movementType)) {
					this.#computeInertialMovement(deltaTime);
					this.#updateCamera();
				} else {
					this.#interactionState = InteractionState.ViewStill;
				}
				break;
		}
	}

	/** Enable/Disable this control class */
	set enabled(e: boolean) {
		this.#touchEvents.enabled = e;
	}

	/** @returns true if the controls are enabled */
	get enabled(): boolean {
		return this.#touchEvents.enabled;
	}

	/** @returns The camera that the controls are managing */
	get camera(): Camera {
		return this.#camera;
	}

	/** Sets the camera that the controls are managing */
	set camera(c: Camera) {
		this.#camera = c;
		this.#syncCameraWithInteractor();
	}

	/** @returns the rotation inertia coefficient, between 0 and 1 */
	get rotationInertia(): number {
		return this.#inertiaParams.rotationInertia;
	}

	/**
	 * Sets the rotation inertia coefficient. 'r' should be greater or equal to zero and lower than 1.
	 * Zero means no rotation inertia. The higher, the longer the inertia lasts.
	 */
	set rotationInertia(r: number) {
		if (r >= 0 && r < 1) {
			this.#inertiaParams.rotationInertia = r;
		} else {
			console.warn(`Map2DControls: rotation inertia parameter ${r} is out of bounds [0, 1).`);
		}
	}

	/** @returns the movement inertia coefficient, between 0 and 1 */
	get movementInertia(): number {
		return this.#inertiaParams.movementInertia;
	}

	/**
	 * Sets the movement inertia coefficient. 'm' should be greater or equal to zero and lower than 1.
	 * Zero means no movement inertia. The higher, the longer the inertia lasts.
	 */
	set movementInertia(m: number) {
		if (m >= 0 && m < 1) {
			this.#inertiaParams.movementInertia = m;
		} else {
			console.warn(`Map2DControls: rovement inertia parameter ${m} is out of bounds [0, 1).`);
		}
	}

	/** Disposes all resources and releases all event listeners registered by this object. */
	dispose(): void {
		this.detach();
		this.#touchEvents.touchStarted.off(this.onTouchStart);
		this.#touchEvents.touchEnded.off(this.onTouchEnd);
		this.#touchEvents.singleTouchMoved.off(this.onSingleTouchMove);
		this.#touchEvents.doubleTouchRotated.off(this.onDoubleTouchRotate);
		this.#touchEvents.pinchAndZoomed.off(this.onPinchAndZoom);
		this.#touchEvents.mousePressed.off(this.onMouseDown);
		this.#touchEvents.mouseReleased.off(this.onMouseUp);
		this.#touchEvents.mouseDragged.off(this.onMouseDrag);
		this.#touchEvents.mouseWheel.off(this.onWheel);
		this.#touchEvents.dispose();
	}
}
