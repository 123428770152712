import {
  Divider,
  ListSubheader,
  Menu,
  MenuItem,
  Stack,
  Theme,
} from "@mui/material";
import { CSSSelectorObjectOrCssVariables } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { blue, cyan, neutral, red } from "../colors";
import { ArrowDown2Icon } from "../icons";
import { InputLabelTag } from "../input/input-label-tag";
import { FaroText } from "../text/faro-text/faro-text";
import { NoTranslate } from "../translation";
import {
  DropdownProps,
  DropdownSelectProps,
  isDivider,
  isOption,
  Option,
} from "./dropdown-types";
import { SingleSelectItem } from "./single-select-item";

type Colors = {
  /** The color of the text in the dropdown */
  color: string;
  /** The color of the input label */
  inputColor: string;
  /** The color of the input label, if disabled */
  inputDisabledColor: string;
  /** The color of the select component */
  selectColor: string;
  /** The color of the select when the component has the focus */
  selectFocusColor: string;
  /** The background color of the select */
  selectBackgroundColor: string;
  /** The backgrounf color of the select, if disabled */
  selectDisabledBackgroundColor: string;
  /** The fill color of the input in the select, if disabled */
  selectInputFillDisabledColor: string;
  /** The color of the outline */
  outlineColor: string;
  /** The color of the outline when hovering */
  outlineHoverColor: string;
  /** The color of the outline when the component has the focus */
  outlineFocusColor: string;
  /** The color of the outline in case of error */
  outlineErrorColor: string;
  /** The color of the icon in the select */
  selectIconColor: string;
  /** The color of the icon in the select, if disabled */
  selectIconDisabledColor: string;
  /** The color of text in the paper */
  paperColor: string;
  /** The background color of the paper */
  paperBackgroundColor: string;
  /** The color of the shadow of the paper */
  paperShadowColor: string;
  /** The color of the border of the paper */
  paperBorderColor: string;
  /** The color of the help text */
  helpColor: string;
};

const DARK_COLORS: Colors = {
  color: neutral[100],
  inputColor: neutral[100],
  inputDisabledColor: neutral[500],
  selectColor: neutral[100],
  selectFocusColor: neutral[100],
  selectBackgroundColor: neutral[999],
  selectDisabledBackgroundColor: neutral[900],
  selectInputFillDisabledColor: neutral[500],
  outlineColor: neutral[500],
  outlineHoverColor: neutral[100],
  outlineFocusColor: cyan[400],
  outlineErrorColor: red[300],
  selectIconColor: neutral[100],
  selectIconDisabledColor: neutral[500],
  paperColor: neutral[100],
  paperBackgroundColor: neutral[999],
  paperShadowColor: neutral[500],
  paperBorderColor: neutral[800],
  helpColor: neutral[300],
};

const LIGHT_COLORS: Colors = {
  color: neutral[800],
  inputColor: neutral[800],
  inputDisabledColor: neutral[500],
  selectColor: neutral[500],
  selectFocusColor: neutral[800],
  selectBackgroundColor: neutral[0],
  selectDisabledBackgroundColor: neutral[100],
  selectInputFillDisabledColor: neutral[500],
  outlineColor: neutral[500],
  outlineHoverColor: neutral[800],
  outlineFocusColor: blue[500],
  outlineErrorColor: red[500],
  selectIconColor: neutral[800],
  selectIconDisabledColor: neutral[500],
  paperColor: neutral[800],
  paperBackgroundColor: neutral[0],
  paperShadowColor: neutral[500],
  paperBorderColor: neutral[200],
  helpColor: neutral[600],
};

function Placeholder({
  placeholder,
}: Pick<DropdownProps, "placeholder">): JSX.Element {
  return (
    <FaroText
      variant="bodyM"
      sx={{
        fontStyle: "italic",
        mr: 1,
        p: 0,
        color: neutral[500],
        whiteSpace: "nowrap",
      }}
    >
      {placeholder ?? "Select option"}
    </FaroText>
  );
}

const DrowdownVariants: Record<
  "outlined" | "underlined",
  (
    colors: Colors,
    isOpen: boolean,
    isDisabled?: boolean,
    isError?: boolean,
  ) => CSSSelectorObjectOrCssVariables<Theme>
> = {
  outlined: (colors, isOpen, isDisabled, isError) => {
    const outlineColor = isError
      ? colors.outlineErrorColor
      : colors.outlineColor;
    const outlineFocusColor = isError
      ? colors.outlineErrorColor
      : colors.outlineFocusColor;
    const outlineHoverColor = isError
      ? colors.outlineErrorColor
      : colors.outlineHoverColor;
    const outlineWidth = isError ? "2px" : "1px";
    return {
      backgroundColor: isDisabled
        ? colors.selectDisabledBackgroundColor
        : colors.selectBackgroundColor,
      padding: "9px 12px 9px 12px",
      borderRadius: "4px",
      boxShadow: isOpen
        ? `inset 0px 0px 0px 2px ${isDisabled ? "transparent" : outlineFocusColor}`
        : `inset 0px 0px 0px ${outlineWidth} ${isDisabled ? "transparent" : outlineColor}`,
      "&:hover": {
        cursor: "pointer",
        boxShadow: `inset 0px 0px 0px ${outlineWidth} ${isDisabled ? "transparent" : outlineHoverColor}`,
      },
    };
  },
  underlined: (colors, isOpen, isDisabled, isError) => {
    const outlineColor = isError
      ? colors.outlineErrorColor
      : colors.outlineColor;
    const outlineFocusColor = isError
      ? colors.outlineErrorColor
      : colors.outlineFocusColor;
    const outlineHoverColor = isError
      ? colors.outlineErrorColor
      : colors.outlineHoverColor;
    const outlineWidth = isError ? "2px" : "1px";
    return {
      backgroundColor: "transparent",
      padding: "4px 2px 4px 2px",
      boxShadow: isOpen
        ? `inset 0 -2px 0  ${isDisabled ? "transparent" : outlineFocusColor}`
        : `inset 0 -${outlineWidth} 0  ${isDisabled ? "transparent" : outlineColor}`,
      "&:hover": {
        cursor: "pointer",
        boxShadow: `inset 0 -${outlineWidth} 0 ${isDisabled ? "transparent" : outlineHoverColor}`,
      },
    };
  },
};

/** @returns The select component of the dropdown */
export function DropdownSelect({
  value: selectedValue,
  label,
  disabled: isDisabled,
  error: isError,
  fullWidth = true,
  children,
  dark,
  helpText,
  tag,
  sx,
  renderValue,
  onClose,
  onOpen,
  variant = "outlined",
}: DropdownSelectProps): JSX.Element {
  const colors = dark ? DARK_COLORS : LIGHT_COLORS;
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsOpen(false);
  }, [selectedValue]);

  useEffect(() => {
    if (isOpen) {
      onOpen?.();
    }
  }, [isOpen, onOpen]);

  return (
    <Stack
      gap="4px"
      sx={{
        float: fullWidth ? undefined : "left",
        opacity: isDisabled ? "50%" : "100%",
      }}
    >
      {label &&
        (typeof label === "string" ? (
          <Stack direction="row">
            <FaroText
              variant="labelM"
              sx={{
                color: colors.inputColor,
              }}
            >
              {label}
            </FaroText>
            {tag && <InputLabelTag tag={tag} dark={dark} />}
          </Stack>
        ) : (
          label
        ))}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          ...DrowdownVariants[variant](colors, isOpen, isDisabled, isError),
          ...sx,
        }}
        onClick={() => {
          if (isDisabled) {
            return;
          }
          setIsOpen(!isOpen);
        }}
        ref={inputRef}
        role="combobox"
      >
        {renderValue(selectedValue)}
        <ArrowDown2Icon
          sx={{
            width: "16px",
            height: "16px",
            color: isDisabled
              ? colors.selectIconDisabledColor
              : colors.selectIconColor,
            transform: isOpen ? "rotate(180deg);" : undefined,
          }}
        />
      </Stack>
      <Menu
        id="menu-"
        open={isOpen}
        anchorEl={inputRef.current}
        onClose={() => {
          onClose?.();
          setIsOpen(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: {
            minWidth: fullWidth ? inputRef.current?.offsetWidth : undefined,
          },
        }}
        sx={{
          marginTop: 0.5,
          "& .MuiPaper-root": {
            color: colors.paperColor,
            backgroundColor: colors.paperBackgroundColor,
            boxShadow: "none",
            border: `1px solid ${colors.paperBorderColor}`,

            "& .MuiList-root": {
              display: "flex",
              flexFlow: "column",
              p: 0.5,
              gap: 0.5,
            },
          },
        }}
      >
        {children}
      </Menu>
      {(!!isError || !!helpText) && (
        <FaroText
          variant="bodyS"
          sx={{
            color: isError ? colors.outlineErrorColor : colors.helpColor,
            lineHeight: "18px",
          }}
        >
          {isError ? "Enter a valid selection" : helpText}
        </FaroText>
      )}
    </Stack>
  );
}

/**
 * @returns The reusable dropdown component styled according to the design guidelines
 */
export function Dropdown({
  dark,
  options,
  placeholder,
  value: selectedValue,
  label,
  disabled,
  error,
  helpText,
  fullWidth = true,
  shouldCapitalize = true,
  onChange,
  ...props
}: DropdownProps): JSX.Element {
  const colors = dark ? DARK_COLORS : LIGHT_COLORS;

  return (
    <DropdownSelect
      value={selectedValue}
      label={label}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      dark={dark}
      helpText={helpText}
      {...props}
      renderValue={(selected) => {
        if (!selected) {
          return <Placeholder placeholder={placeholder} />;
        }

        const option = options.find(
          (o): o is Option => isOption(o) && o.value === selected,
        );

        return (
          <FaroText
            component="div"
            variant="bodyM"
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              textTransform: shouldCapitalize ? "capitalize" : "none",
              whiteSpace: "nowrap",
              mr: 1.5,
              color: colors.color,
            }}
          >
            {option?.label ?? <NoTranslate>{selected}</NoTranslate>}
          </FaroText>
        );
      }}
    >
      {
        // This invisible item is used to avoid having the first element of the list always focused
        // when the menu is opened and no value has been selected yet.
        // This is a bug in MaterialUI https://github.com/mui/material-ui/issues/23747
        //
      }
      <MenuItem sx={{ display: "none" }} />
      {options.map((option, index) => {
        if (isDivider(option)) {
          return (
            <Divider
              key={`divider-${index}`}
              component="li"
              sx={{
                // !important is needed to override a competing style from MuiMenuItem
                m: "0 !important",
              }}
            />
          );
        }

        const { key, value, label, secondaryText, isDisabled, isHeader } =
          option;

        if (isHeader) {
          // Header margin when there is a divider
          const MARGIN_TOP_WITH_DIVIDER = 1.5;

          return (
            <ListSubheader
              key={key}
              sx={{ backgroundColor: "transparent", p: 0 }}
            >
              {index !== 0 && <Divider />}

              <FaroText
                component="div"
                variant="bodyM"
                sx={{
                  color: colors.color,
                  fontSize: "0.75rem",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                  mx: 1.5,
                  mt: index === 0 ? 1 : MARGIN_TOP_WITH_DIVIDER,
                  mb: 1,
                }}
              >
                {label}
              </FaroText>
            </ListSubheader>
          );
        }

        return (
          <SingleSelectItem
            key={key}
            value={value}
            label={label}
            secondaryText={secondaryText}
            disabled={isDisabled}
            shouldCapitalize={shouldCapitalize}
            selectedValue={selectedValue}
            dark={dark}
            onClick={() => onChange?.({ target: { value } })}
          />
        );
      })}
    </DropdownSelect>
  );
}
