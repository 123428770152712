import {
  PropOptional,
  validateArrayOf,
  validateNotNullishObject,
  validateOfType,
  validatePrimitive,
  validatePropertyEnumValue,
} from "@faro-lotv/foundation";
import {
  GUID,
  UserId,
  WorkspaceIntegrationProviderId,
} from "@faro-lotv/ielement-types";
import { Token } from "../authentication";
import { UserSubscriptionRole } from "./core-api-types";

/** Type of a payload before validation */
export type UnknownPayload = Record<string, unknown>;

/** A successful request with a payload */
export type SuccessMessage<Payload = UnknownPayload> = Payload & {
  /** Each successful request will have this status */
  status: "success" | "warning";
};

/** A failed request bring no payload but an error message  */
export type ErrorMessage = {
  /** Each successful request will have this status */
  status: "error";

  /** A message explaining what failed */
  message: string;

  /** The error code. */
  code?: string;

  /** The name of the error that occurred. */
  error?: string;

  /** The name of the error that occurred for the V2 API. */
  // The naming comes from the API, we cannot change it
  // eslint-disable-next-line @typescript-eslint/naming-convention
  error_v2?: string;

  /** Id of the request generating the error */
  requestId: string;
};

type CoreApiResponse = ErrorMessage | SuccessMessage;

/**
 * Type Guard to check if a generic payload is a valid CoreApi response
 *
 * @param payload The json received by the CoreApi
 * @returns true if it's a Error or Success message
 */
export function isCoreApiResponse(
  payload: UnknownPayload,
): payload is CoreApiResponse {
  return (
    typeof payload.status === "string" &&
    ["success", "error", "warning"].includes(payload.status)
  );
}

/**
 * Type Guard to check if a CoreApi response is an error
 *
 * @param payload The json received from the CoreApi
 * @returns True if it's an error
 */
export function isErrorMessage(
  payload: CoreApiResponse,
): payload is ErrorMessage {
  return payload.status === "error";
}

/** CoreApi external token providers, we recognize only faro-sphere, autodesk and procore */
type TokenProviders = "faro-sphere" | WorkspaceIntegrationProviderId | string;

/** Data about a single user CoreApi token to talk to other services */
export type UserTokenData = {
  /** Provider of the token (Eg: faro-sphere) */
  provider: TokenProviders;

  /** Timestamp when this token will expires, to check against Date.now() */
  expiration?: number;

  /** The scopes the user has access to. */
  scopes: string[];
};

/**
 * Type-Guard to check if a generic object is a UserToken
 *
 * @param response The generic object
 * @returns true if the object is a UserToken
 */
export function isUserToken(
  response: UnknownPayload,
): response is UserTokenData {
  return (
    typeof response.provider === "string" &&
    (response.expiration === undefined ||
      typeof response.expiration === "number") &&
    Array.isArray(response.scopes) &&
    response.scopes.every((scope) => typeof scope === "string")
  );
}

/**
 * Payload when requesting the list of user tokens
 */
export type UserTokensPayload = {
  /** All the user tokens available to the CoreApi */
  data: UserTokenData[];
};

/**
 * Type-Guard to check if a CoreApi response object is a UserTokenResponse
 *
 * @param response The CoreApi response json
 * @returns true if the object is a UserTokenResponse
 */
export function isUserTokenResponse(
  response: UnknownPayload,
): response is UserTokensPayload {
  return Array.isArray(response.data) && response.data.every(isUserToken);
}

export type IntercomUserInfo = {
  /** The current user ID used to create the hash. Needs to be provided to Intercom. */
  userId: string;

  /** The hash generated by the Core API to authenticate the userId in Intercom. */
  hash: string;
};

export type IntercomUserInfoPayload = {
  /** The intercom user info of the current user */
  data: IntercomUserInfo;
};

/**
 * Type-Guard to check if a CoreApi response object is a IntercomUserInfoPayload
 *
 * @param response The CoreApi response json
 * @returns true if the object is a IntercomUserInfoPayload
 */
export function isIntercomUserInfoPayload(
  response: UnknownPayload,
): response is IntercomUserInfoPayload {
  return (
    validateNotNullishObject(response, "IntercomUserInfoPayload") &&
    validateNotNullishObject(response.data, "IntercomUserInfoPayload.data") &&
    validatePrimitive(response.data, "userId", "string") &&
    validatePrimitive(response.data, "hash", "string")
  );
}

/**
 * Payload when requesting a Token to link a project to Sphere
 */
export type SphereLinkTokenPayload = {
  data: {
    token: Token;
  };
};

/**
 * Type-Guard to check if a CoreApi response object is a SphereLinkTokenPayload
 *
 * @param response The CoreApi response json
 * @returns true if the object is a SphereLinkTokenPayload
 */
export function isSphereLinkTokenPayload(
  response: UnknownPayload,
): response is SphereLinkTokenPayload {
  const toCheck: Partial<SphereLinkTokenPayload> = response;
  return typeof toCheck.data?.token === "string";
}

/**
 * Payload after authorization workflow
 */
export type AuthorizationPayload = {
  /** True if the user authorized the connection */
  connectStatus: boolean;
  /** The authorization provider */
  providerId: TokenProviders;
};

/**
 * Type-Guard to check if an Authorization response object is a AuthorizationPayload
 *
 * @param response The CoreApi response JSON object
 * @returns true if the object is an AuthorizationPayload
 */
export function isAuthorizationPayload(
  response: UnknownPayload,
): response is AuthorizationPayload {
  return (
    typeof response.connectStatus === "boolean" &&
    typeof response.providerId === "string"
  );
}

/**
 * Possible values in the project permissions array the app need to check against.
 * The permissions are in ascending order, for a higher the Api will also return all lower ones
 * See https://faro01.atlassian.net/wiki/spaces/HOLO/pages/3034447904/Project+Permissions
 */
export enum ProjectPermissions {
  /** See project in  dashboard / project list, but can't open it */
  describe = "describe",

  /** Project Viewer: User can read the project content */
  read = "read",

  /** Project Editor: User can edit and add content to the project */
  write = "write",

  /** Project Admin: The user can manage the project and its users, but not delete it */
  admin = "admin",

  /** Project Owner: The current user owns this project and can delete it. */
  owner = "*",
}

/** The possible access levels of the project */
export enum ProjectAccessLevel {
  public = "public",
  unlisted = "unlisted",
  private = "private",
}

/**
 * The part we need from the Payload returned by the CoreApi when you query the /v2/project/${PROJECT_ID} endpoint
 */
export type ProjectInfoPayload = {
  data: {
    /** Name of the project */
    name: string;

    /** Access level of the project */
    accessLevel: ProjectAccessLevel;

    currentUserRights: {
      /** Id of the current user, if they are logged in */
      userId?: GUID;

      /**
       * List of permission for the current user which can be one of the items from ProjectPermissions enum
       *
       * @see ProjectPermissions
       */
      permissions: ProjectPermissions[];
    };
  };
};

/**
 * Type-Guard to check if an CoreApi response object is a ProjectInfoPayload response
 *
 * @param response The CoreApi response JSON object
 * @returns true if the object is a ProjectInfoPayload
 */
export function isProjectInfoPayload(
  response: UnknownPayload,
): response is ProjectInfoPayload {
  const toCheck: Partial<ProjectInfoPayload> = response;
  return (
    !!toCheck.data &&
    typeof toCheck.data.name === "string" &&
    Object.values<string>(ProjectAccessLevel).includes(
      toCheck.data.accessLevel,
    ) &&
    // Allow this check as part of the type guard
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !!toCheck.data.currentUserRights &&
    (toCheck.data.currentUserRights.userId === undefined ||
      typeof toCheck.data.currentUserRights.userId === "string") &&
    Array.isArray(toCheck.data.currentUserRights.permissions) &&
    toCheck.data.currentUserRights.permissions.every(
      (x) => typeof x === "string",
    )
  );
}

/**
 * The technical values for company-level roles.
 * Usually only the highest role is returned, which the order in this enum reflects.
 */
export enum CompanyRole {
  /** Admin of the company (full permissions in the company) */
  companyAdmin = "COMPANY_EXECUTIVE",

  /** Company viewer (read access to all projects) */
  companyViewer = "COMPANY_VIEWER",

  /** Group manager in at least one group */
  groupManager = "COMPANY_MANAGER",

  /** Project manager in at least one group */
  projectManager = "PROJECT_MANAGER",

  /** No managing role in the company */
  member = "MEMBER",
}

/**
 * The part we need from the Payload returned by the CoreApi when you query the project-context endpoint
 */
export type ProjectContextPayload = {
  data: {
    /** The feature-roles available in the corresponding project to the current user */
    roles: UserSubscriptionRole[];

    /** Data about the project company */
    company: Pick<Company, "id" | "name"> & {
      /** The highest role of the user in the company. Undefined if the current user is not a member of the company (or not logged in). */
      // Note: The "role" field in the Company type cannot be picked here, as it contains different values.
      role?: CompanyRole;
    };
  };
};

/**
 * Type-Guard to check if an CoreApi response object is a ProjectContextPayload
 *
 * @param response The CoreApi response JSON object
 * @returns true if the object is an ProjectContextPayload
 */
export function isProjectContextPayload(
  response: UnknownPayload,
): response is ProjectContextPayload {
  return (
    validateNotNullishObject(response, "ProjectContextPayload") &&
    validateNotNullishObject(response.data, "ProjectContextPayload.data") &&
    validateArrayOf({
      object: response.data,
      prop: "roles",
      elementGuard(role) {
        // Allowing any string here, because feature roles get added frequently, and are not always necessary for the Viewer.
        return typeof role === "string";
      },
    }) &&
    validateNotNullishObject(
      response.data.company,
      "ProjectContextPayload.data.company",
    ) &&
    validatePrimitive(response.data.company, "id", "string") &&
    validatePrimitive(response.data.company, "name", "string") &&
    validatePropertyEnumValue(
      response.data.company,
      "role",
      CompanyRole,
      PropOptional,
    )
  );
}

/**
 * All information needed to notify the CoreApi a chunk upload finished
 */
export type ChunkUploadFinalizeDescription = {
  /** Method to use for the upload finalization (fixed to PUT for now) */
  method: "PUT";

  /** Url to notify for upload finalization */
  url: string;

  /** if headers exist, they must be added to the request */
  headers?: Record<string, string>;

  /** text to add as request payload */
  body: string;
};

/**
 * TypeGuard to validate an object is a valid ChunkUploadFinalizeDescription
 *
 * @param data the object to validate
 * @returns true if it matches the ChunkUploadFinalizeDescription type
 */
export function isChunkUploadFinalizeDescription(
  data: UnknownPayload,
): data is ChunkUploadFinalizeDescription {
  const toCheck: Partial<ChunkUploadFinalizeDescription> = data;
  return (
    toCheck.method === "PUT" &&
    typeof toCheck.url === "string" &&
    typeof toCheck.body === "string"
  );
}

/**
 * All information needed to upload a single chunk of a big file
 */
export type ChunkUploadRequestDescription = {
  /** Method to use for the chunk upload (fixed to PUT for now) */
  method: "PUT";

  /** Url to use for the upload */
  url: string;

  /** if headers exist, they must be added to the request */
  headers?: Record<string, string>;

  /** read 'length' bytes, starting from 'start' and add them as payload to the request */
  bytes: { start: number; length: number };
};

/**
 * TypeGuard to validate an object is a valid ChunkUploadRequestDescription
 *
 * @param data the object to validate
 * @returns true if it matches the ChunkUploadRequestDescription type
 */
export function isChunkUploadRequestDescription(
  data: UnknownPayload,
): data is ChunkUploadRequestDescription {
  const toCheck: Partial<ChunkUploadRequestDescription> = data;
  return (
    toCheck.method === "PUT" &&
    typeof toCheck.url === "string" &&
    typeof toCheck.bytes?.start === "number" &&
    typeof toCheck.bytes.length === "number"
  );
}

/**
 * CoreApi response object when asking for a ChunkUpload
 */
export type ChunkUploadDescription = {
  /** List of the description of each chunk request */
  chunks: ChunkUploadRequestDescription[];

  /** Description on how to notify the upload is completed */
  finalize: ChunkUploadFinalizeDescription;

  /** Url to use to access the uploaded file after the upload is finalized */
  downloadUrl: string;

  /** Expiration date for this upload task, after this date all the urls will became invalid (Format ISO-8601) */
  expiration: string;
};

export type ChunkUploadPayload = {
  data: ChunkUploadDescription;
};

/**
 * TypeGuard to validate an object is a valid ChunkUploadPayload
 *
 * @param data the object to validate
 * @returns true if it matches the ChunkUploadPayload type
 */
export function isChunkUploadPayload(
  data: UnknownPayload,
): data is ChunkUploadPayload {
  const toCheck: Partial<ChunkUploadPayload> = data;
  return (
    toCheck.data !== undefined &&
    // Allow this check as part of the type guard
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !!toCheck.data.chunks &&
    // Allow this check as part of the type guard
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    !!toCheck.data.finalize &&
    toCheck.data.chunks.every(isChunkUploadRequestDescription) &&
    isChunkUploadFinalizeDescription(toCheck.data.finalize) &&
    typeof toCheck.data.downloadUrl === "string" &&
    typeof toCheck.data.expiration === "string"
  );
}

/**
 * All information needed to upload a small single file
 */
export type UploadRequestDescription = {
  /** Method to use for the upload */
  method: "POST" | "PUT";

  /** Mime type of the file to be uploaded */
  type: string;

  /** if headers exist, they must be added to the request */
  headers?: Record<string, string>;

  /** Url to use for the upload */
  uploadUrl: string;

  /** Url to use to access the uploaded file after the upload is done */
  downloadUrl: string;
};

/**
 * TypeGuard to validate an object is a valid UploadRequestDescription
 *
 * @param data the object to validate
 * @returns true if it matches the UploadRequestDescription type
 */
export function isUploadRequestDescription(
  data: UnknownPayload,
): data is UploadRequestDescription {
  const toCheck: Partial<UploadRequestDescription> = data;
  return (
    (toCheck.method === "POST" || toCheck.method === "PUT") &&
    typeof toCheck.type === "string" &&
    (toCheck.headers === undefined ||
      (typeof toCheck.headers === "object" &&
        !(toCheck.headers instanceof Array))) &&
    typeof toCheck.uploadUrl === "string" &&
    typeof toCheck.downloadUrl === "string"
  );
}

export type UploadRequestPayload = {
  data: UploadRequestDescription;
};

/**
 * TypeGuard to validate an object is a valid UploadRequestPayload
 *
 * @param data the object to validate
 * @returns true if it matches the UploadPayload type
 */
export function isUploadRequestPayload(
  data: UnknownPayload,
): data is UploadRequestPayload {
  const toCheck: Partial<UploadRequestPayload> = data;
  return !!toCheck.data && isUploadRequestDescription(toCheck.data);
}

/**
 * Response Payload from CoreApi when a token is requested
 */
export type TokenRequestPayload = {
  data: {
    /** The Bearer token to use for follow up authenticated requests */
    token: string;
  };
};

/**
 * TypeGuard to validate a CoreApi response is a valid TokenRequestPayload
 *
 * @param payload returned by the CoreApi
 * @returns true if it matches the TokenRequestPayload type
 */
export function isTokenRequestPayload(
  payload: UnknownPayload,
): payload is TokenRequestPayload {
  const toTest: Partial<TokenRequestPayload> = payload;
  return typeof toTest.data?.token === "string";
}

/** The currently logged in user details */
export type UserDetails = {
  /** User email address */
  mailAddress: string;

  /** User profile image url */
  profileImageUrl?: string;

  /** User first name */
  name: string;

  /** The unique id of the user */
  userId: UserId;

  /**
   * User last name.
   *
   * This can be `undefined` for older user accounts.
   * In this case, the last name will be included in the `name` property.
   */
  lastName?: string;
};

/**
 * The part we need from the Payload returned by the CoreApi when you query the v1/users/getLoggedInUser endpoint
 */
export type UserDetailsPayload = {
  data: UserDetails;
};

/**
 * TypeGuard to validate a CoreApi response is a valid UserDetailsPayload
 *
 * @param payload returned by the CoreApi
 * @returns true if it matches the UserDetailsPayload type
 */
export function isUserDetailsPayload(
  payload: UnknownPayload,
): payload is UserDetailsPayload {
  const toTest: Partial<UserDetailsPayload> = payload;
  const { data } = toTest;

  return (
    !!data &&
    typeof data.mailAddress === "string" &&
    (data.profileImageUrl === undefined ||
      typeof data.profileImageUrl === "string") &&
    typeof data.name === "string" &&
    typeof data.userId === "string" &&
    (data.lastName === undefined || typeof data.lastName === "string")
  );
}

/** User info provided by the CoreApi */
export type UserInfo = {
  /** The unique id of the user */
  userId: GUID | undefined;
  /** The e-mail associated to that user */
  mail: string;
  /** The full name of the user */
  name: string;
  /** The permissions of the user on the project */
  permissions: ProjectPermissions[];
};

/**
 * The part we need from the Payload returned by the CoreApi when you query the v2/projects/${projectId}/permission endpoint
 */
export type ProjectUsersPayload = {
  /** The data inside the payload */
  data: {
    /** The list of information of the users that have access to this project */
    users: UserInfo[];
  };
};

/**
 *
 * @returns true if the payload is a ProjectUsersPayload
 * @param payload to check
 */
export function isProjectUsersPayload(
  payload: UnknownPayload,
): payload is ProjectUsersPayload {
  const toTest: Partial<ProjectUsersPayload> = payload;
  const { data } = toTest;

  return (
    !!data &&
    "users" in data &&
    Array.isArray(data.users) &&
    data.users.every(
      (info) =>
        (!info.userId || typeof info.userId === "string") &&
        typeof info.mail === "string" &&
        typeof info.name === "string",
    )
  );
}

/** Member info provided by the CoreApi */
export type ProjectMemberInfo = {
  /** The unique id of the project member */
  id: GUID;
  /** The identity of the project member */
  identity: GUID;
  /** The e-mail associated to that member */
  email: string;
  /** The full name of the member */
  name: string;
  /** The profile or thumbnail image of the member */
  thumbnailUrl?: string;
};

/**
 * The part we need from the Payload returned by the CoreApi when you query
 * the /v3/dashboard/company/${companyId}/project/${projectId}/member endpoint
 */
export type ProjectMembersPayload = {
  /** The data inside the payload */
  data: {
    /** The list of information of the members that have access to this project */
    projectMembers: ProjectMemberInfo[];
  };
};

/**
 *
 * @returns true if the payload is a ProjectMembersPayload
 * @param payload to check
 */
export function isProjectMembersPayload(
  payload: UnknownPayload,
): payload is ProjectMembersPayload {
  const toTest: Partial<ProjectMembersPayload> = payload;
  const { data } = toTest;

  return (
    !!data &&
    "projectMembers" in data &&
    Array.isArray(data.projectMembers) &&
    data.projectMembers.every(
      (member) =>
        typeof member.id === "string" &&
        typeof member.identity === "string" &&
        typeof member.email === "string" &&
        typeof member.name === "string" &&
        (member.thumbnailUrl === undefined ||
          typeof member.thumbnailUrl === "string"),
    )
  );
}

/** Metadata about a Company */
export type Company = {
  /** The unique id of the company */
  id: GUID;
  /** The name of the company */
  name: string;
  /** The uri that the logo can be fetched from */
  logoUrl?: string;
  /** The 2nd level domain of the company */
  domain?: string;
  /** Date that this company was created */
  createdAt: number;
  /** Tags of the company */
  tags: string[];
};

/** Type returned by the core-api /v3/company query */
export type CompaniesPayload = {
  data: Company[];
};

/**
 * @returns true if the payload is a Company
 * @param payload to check
 */
export function isCompany(payload: Partial<Company>): payload is Company {
  return (
    validatePrimitive(payload, "id", "string") &&
    validatePrimitive(payload, "name", "string") &&
    validatePrimitive(payload, "logoUrl", "string", PropOptional) &&
    validatePrimitive(payload, "domain", "string", PropOptional) &&
    validatePrimitive(payload, "createdAt", "number") &&
    validateArrayOf({
      object: payload,
      prop: "tags",
      elementGuard: (tag) => typeof tag === "string",
    })
  );
}

/**
 *
 * @param payload returned by the core api
 * @returns true if the payload is a valid CompaniesPayload
 */
export function isCompaniesPayload(
  payload: UnknownPayload,
): payload is CompaniesPayload {
  const toTest: Partial<CompaniesPayload> = payload;
  const { data } = toTest;

  return !!data && Array.isArray(data) && data.every(isCompany);
}

/** Metadata about a Sphere 2.0 workspace */
export type WorkspaceDescriptor = {
  /** Url of the core api instance to use to query this workspace */
  apiUrl: string;

  /** Name of the workspace */
  name: string;

  /** User role in the workspace */
  role: string;

  /** Type of workspace (Eg: company, admin) */
  type: string;

  /** Url to open the dashboard for this workspace */
  url: string;
};

/**
 * @returns true if the payload is a WorkspaceDescriptor
 * @param payload to check
 */
export function isWorkspaceDescriptor(
  payload: Partial<WorkspaceDescriptor>,
): payload is WorkspaceDescriptor {
  return (
    typeof payload.apiUrl === "string" &&
    typeof payload.name === "string" &&
    typeof payload.role === "string" &&
    typeof payload.type === "string" &&
    typeof payload.url === "string"
  );
}

/** Type returned by the core-api /users/me/workspaces query */
export type WorkspacesPayload = {
  data: {
    workspaces: WorkspaceDescriptor[];
  };
};

/**
 * @returns true if the payload is a valid WorkspacePayload
 * @param payload returned by the core api
 */
export function isWorkspacesPayload(
  payload: UnknownPayload,
): payload is WorkspacesPayload {
  const toTest: Partial<WorkspacesPayload> = payload;
  const { data } = toTest;

  return (
    !!data &&
    typeof data === "object" &&
    "workspaces" in data &&
    Array.isArray(data.workspaces) &&
    data.workspaces.every(isWorkspaceDescriptor)
  );
}

/** Defines if a project was moved to archive or not */
export enum ArchivalStatus {
  Active = "UNARCHIVED",
  Archived = "ARCHIVED",
}

export type ProjectDetailsDescriptor = {
  /** The projects GUID */
  id: GUID;

  /** The projects name */
  name: string;

  /** The projects client meta data */
  client?: string;

  /** The projects address meta data */
  address: string;

  /** The projects description meta data */
  description?: string;

  /** The projects creation date as a UNIX timestamp*/
  createdAt: number;

  /** The projects last modification date as a UNIX timestamp */
  modifiedAt: number;

  /** If the project is private, public or unlisted */
  accessLevel: ProjectAccessLevel;

  state: ArchivalStatus;

  /** Url to the projects thumbnail image */
  thumbnailUrl?: string;
};

/**
 * @returns true if the payload is a ProjectDetailsDescriptor
 * @param toCheck to check
 */
export function isProjectDetailsDescriptor(
  toCheck: Partial<ProjectDetailsDescriptor>,
): toCheck is ProjectDetailsDescriptor {
  return (
    typeof toCheck.id === "string" &&
    typeof toCheck.name === "string" &&
    (toCheck.client === undefined || typeof toCheck.client === "string") &&
    typeof toCheck.address === "string" &&
    (toCheck.description === undefined ||
      typeof toCheck.description === "string") &&
    typeof toCheck.createdAt === "number" &&
    typeof toCheck.modifiedAt === "number" &&
    typeof toCheck.accessLevel === "string" &&
    typeof toCheck.state === "string" &&
    (toCheck.thumbnailUrl === undefined ||
      typeof toCheck.thumbnailUrl === "string")
  );
}

export type ProjectContextDescriptor = {
  /** The project context company */
  company: Company;
};

/**
 * Checks if the payload is a valid project context descriptor
 *
 * @returns true if the payload is a ProjectContextDescriptor
 * @param toCheck payload to check
 */
export function isProjectContextDescriptor(
  toCheck: Partial<ProjectContextDescriptor>,
): toCheck is ProjectContextDescriptor {
  return !!toCheck.company && isCompany(toCheck.company);
}

/**
 * Subset of meta data for a Sphere 2.0 Project as returned by /v3/projects.
 * Add more fields as needed.
 */
export type ProjectDescriptor = {
  /** Project Details */
  project: ProjectDetailsDescriptor;

  /** Permissions of the current User for this project */
  permissions: Array<string | ProjectPermissions>;

  /** The project context */
  context: ProjectContextDescriptor;
};

/**
 * @returns true if the payload is a ProjectDescriptor
 * @param payload to check
 */
export function isProjectDescriptor(
  payload: Partial<ProjectDescriptor>,
): payload is ProjectDescriptor {
  return (
    !!payload.project &&
    typeof payload.project === "object" &&
    isProjectDetailsDescriptor(payload.project) &&
    Array.isArray(payload.permissions) &&
    payload.permissions.every((permission) => typeof permission === "string") &&
    typeof payload.context === "object" &&
    isProjectContextDescriptor(payload.context)
  );
}

/**
 * Payload returned by /v3/projects.
 * Contains a page of projects and an id to fetch the next page, if it exists.
 */
export type ProjectsPayload = {
  /** The current page of projects */
  data: ProjectDescriptor[];

  /**
   * Id for fetching the next page, doesn't exist in the response of the last page.
   * Used as the value for the start prop in GetUserProjectsPageParams.
   */
  next?: string;
};

/**
 * @returns true if the payload is a valid ProjectsPayload
 * @param payload returned by the core api
 */
export function isProjectsPayload(
  payload: UnknownPayload,
): payload is ProjectsPayload {
  const toTest: Partial<ProjectsPayload> = payload;
  const { data } = toTest;

  return (
    (toTest.next === undefined || typeof toTest.next === "string") &&
    !!data &&
    Array.isArray(data) &&
    data.every(isProjectDescriptor)
  );
}

export type TokenBalance = {
  /** The number of tokens available to the user */
  balance: number;
};

/**
 * @returns Whether given object is a `TokenBalance` object
 * @param data The object to check
 */
export function isTokenBalance(data: unknown): data is TokenBalance {
  return (
    validateNotNullishObject(data, "TokenBalance") &&
    validatePrimitive(data, "balance", "number")
  );
}

/** The payload returned by the tokens route */
export type TokenBalancePayload = {
  /** The token balance for the requested project. */
  data: TokenBalance;
};

/**
 * @returns Whether given object is a `TokenBalancePayload` object
 * @param payload The payload returned by the core api
 */
export function isTokenBalancePayload(
  payload: unknown,
): payload is TokenBalancePayload {
  return (
    validateNotNullishObject(payload, "TokenBalancePayload") &&
    validateOfType(payload, "data", isTokenBalance)
  );
}
