import { TypedEvent } from "@faro-lotv/foundation";
import {
	Camera,
	CubeTexture,
	Intersection,
	Matrix4,
	Mesh,
	Object3D,
	PlaneGeometry,
	Raycaster,
	Scene,
	WebGLRenderer,
} from "three";
import { CubeMapMaterial } from "../Materials/CubeMapMaterial";
import { EquirectangularDepthImage } from "./EquirectangularDepthImage";
import type { Pano } from "./Pano";

/** Max width of a pano in pixel */
const MAX_PANO_WIDTH = 2048;

/**
 * An object to render an cube map with a possible additional depth texture
 * It will fill the viewport with the correct area of the color texture
 * If the depth is available is then possible to query the position/normal of each texture point
 * or cast rays from the center of the object and check for intersections with the depth data
 */
export class CubeMapPano extends Mesh implements Pano {
	/** This object geometry is a full screen quad */
	override geometry = new PlaneGeometry(2, 2);
	/** Our custom material to render equirectangular images */
	override material = new CubeMapMaterial();
	/** Data texture to keep the depth information */
	depths?: EquirectangularDepthImage;
	/** Signal to notify when the depth texture have changed */
	depthsChanged = new TypedEvent<void>();
	/** Signal to notify when the color texture have changed */
	textureChanged = new TypedEvent<void>();
	/** Signal to notify that the object is about to be rendered */
	beforeRender = new TypedEvent<void>();
	/** Signal to notify that this object have been disposed */
	disposed = new TypedEvent<void>();
	/** If true will delay changes that may slow down rendering like uploading big textures */
	#animating = false;

	/**
	 * Construct a new Equirectangular Object
	 *
	 * @param texture The equirectangular texture
	 * @param position The position in the world where this image was taken
	 */
	constructor(
		public texture: CubeTexture,
		position: Matrix4,
	) {
		super();
		this.applyMatrix4(position);
		this.material.updateTexture(texture);
	}

	/**
	 * Dispose all resource for this object, freeing gpu memory
	 */
	dispose(): void {
		this.geometry.dispose();
		this.texture.dispose();
		this.depths?.dispose();
		this.disposed.emit();
	}

	/** @returns true if this pano is frozen */
	get animating(): boolean {
		return this.#animating;
	}

	/** Change the frozen status of this pano */
	set animating(f: boolean) {
		this.#animating = f;
		if (!this.#animating && this.texture !== this.material.texture) {
			// Update the texture and dispose of the previous one if there was one
			this.material.updateTexture(this.texture)?.dispose();
		}
	}

	/**
	 * Change the color texture
	 *
	 * @param texture The new color texture
	 */
	setTexture(texture: CubeTexture): void {
		this.texture = texture;
		if (!this.animating || this.width <= MAX_PANO_WIDTH) {
			// Update the texture and dispose of the previous one if there was one
			this.material.updateTexture(texture)?.dispose();
		}
		this.textureChanged.emit();
	}

	/**
	 * Change the depth texture
	 *
	 * @param depths The new depth texture
	 */
	setDepths(depths: EquirectangularDepthImage): void {
		this.depths = depths;
		this.depths.setPanoWorldMatrix(this.matrixWorld);
		this.depthsChanged.emit();
	}

	/** @returns the current image width */
	get width(): number {
		const nw = this.texture.images[0]?.naturalWidth;
		const w = this.texture.images[0]?.width;
		return nw || w || 0;
	}

	/** @returns the current image height */
	get height(): number {
		return this.width / 2;
	}

	/** @returns the current depth image width */
	get depthWidth(): number {
		return this.depths?.image.width ?? 0;
	}

	/** @returns true if we have depth information */
	get hasDepths(): boolean {
		return this.depths !== undefined;
	}

	/** @returns the current pano opacity */
	get opacity(): number {
		return this.material.opacity;
	}

	/** Change the pano opacity */
	set opacity(opacity: number) {
		this.material.opacity = opacity;
		this.material.transparent = opacity !== 1;
	}

	/**
	 * Update the materials and fetch data from the backend before rendering this object.
	 *
	 * @inheritdoc
	 */
	override onBeforeRender = (renderer: WebGLRenderer, scene: Scene, camera: Camera): void => {
		this.material.update(this.matrixWorld, camera, renderer);
		this.beforeRender.emit();
	};

	/** @inheritdoc */
	raycast(raycaster: Raycaster, intersects: Array<Intersection<Object3D>>): void {
		if (!this.depths) return;
		return this.depths.raycast(this, raycaster, intersects);
	}
}
