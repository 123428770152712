import { TypedEvent } from "@faro-lotv/foundation";
import { Box2, Group, Mesh, PlaneGeometry, Texture, Vector2 } from "three";
import { FloorPlanTileMaterial } from "../Materials/FloorPlanTileMaterial";

/** The class used to rendered one specific tile */
export class FloorPlanTile extends Mesh<PlaneGeometry, FloorPlanTileMaterial> {
	/** Signal to notify that the object is about to be rendered */
	beforeRender = new TypedEvent<void>();

	/**
	 *  Create a new tile
	 *
	 * @param texture The texture of this tile
	 * @param geometry The plane geometry of the tile
	 * @param material The material used to render this tile
	 * @param depthLevel The depth of this tile in the tree
	 */
	constructor(
		public texture: Texture | undefined,
		public geometry: PlaneGeometry,
		public material: FloorPlanTileMaterial,
		public depthLevel: number,
	) {
		super(geometry, material);
	}

	/**
	 * Set the values of this tile
	 *
	 * @param texture The texture used for this tile
	 * @returns the previous tile if present
	 */
	setTile(texture: Texture): Texture | undefined {
		const prev = this.texture;
		this.texture = texture;
		this.material.name = `FloorPlanTileMaterial : ${texture.name}`;
		this.geometry.name = `FloorPlanTileGeometry : ${texture.name}`;
		return prev;
	}

	/**
	 * Update the materials and fetch data from the backend before rendering this object.
	 *
	 * @inheritdoc
	 */
	override onBeforeRender = (): void => {
		if (this.texture) {
			this.material.map = this.texture;
		}
		this.material.depthLevel = this.depthLevel;
		this.material.uniformsNeedUpdate = true;
		this.beforeRender.emit();
	};
}

/** A collection of the visible tiles for the a floor plan */
export class TiledFloorPlan extends Group {
	override name = "TiledFloorPlan";
	material = new FloorPlanTileMaterial();
	geometry = new PlaneGeometry();
	/** Signal to notify that the object is about to be rendered */
	beforeRender = new TypedEvent<void>();
	/** Signal to notify when the color texture have changed */
	textureChanged = new TypedEvent<void>();
	/** Signal to notify that this object have been disposed */
	disposed = new TypedEvent<void>();
	/** If true will delay changes that may slow down rendering like uploading big textures */
	#animating = false;
	/** A new overview tile that we're waiting to add during animations */
	#pendingTile?: Texture;
	/** The overview tile */
	#overviewTile: FloorPlanTile;
	/** The list of all the textures added to this object */
	#textures: Texture[] = [];

	/** This group will only contains FloorPlanTiles */
	override children: FloorPlanTile[] = [];

	/** The max depth of the tree */
	#maxDepth = 0;

	/**
	 * @returns the number of tiles active in this floor plan
	 */
	get numTiles(): number {
		return this.children.length;
	}

	/**
	 * Create a tiled floor plan, the first texture is the full, low res, image
	 *
	 * @param maxDepth The maximum depth of the LOD structure
	 * @param texture The full low res floor plan image to start with (will cover the entire plane surface)
	 */
	constructor(
		maxDepth: number,
		public texture?: Texture,
	) {
		super();
		this.#maxDepth = maxDepth;
		this.#overviewTile = new FloorPlanTile(texture, this.geometry, this.material, 0);
		if (texture) {
			this.#textures.push(texture);
			this.#overviewTile.setTile(texture)?.dispose();
			this.#overviewTile.name = "Overview tile";
			this.name = `TiledFloorPlan ${texture.name}`;
		}
		this.#overviewTile.beforeRender.pipe(this.beforeRender);
		this.#overviewTile.renderOrder = maxDepth;
		this.#overviewTile.onAfterRender = (renderer) => {
			renderer.clearStencil();
		};
		this.clear();
	}

	/**
	 * Clears all the child tiles and reverts back to the base image
	 *
	 * @returns this
	 */
	override clear(): this {
		super.clear();
		this.add(this.#overviewTile);
		return this;
	}

	/** @returns The list of textures used by the children*/
	get textures(): Texture[] {
		return this.#textures;
	}

	/**
	 * Dispose all resources used by this object, the plane geometry, the depths if attached and all the textures
	 */
	dispose(): void {
		this.geometry.dispose();
		this.material.dispose();
		for (const texture of this.#textures) {
			texture.dispose();
		}
		this.disposed.emit();
	}

	/** @returns true if animating */
	get animating(): boolean {
		return this.#animating;
	}

	/** Change animating status */
	set animating(a: boolean) {
		this.#animating = a;
		if (!a && this.#pendingTile) {
			this.#overviewTile.setTile(this.#pendingTile)?.dispose();
			this.textureChanged.emit();
			this.#pendingTile = undefined;
		}
	}

	/**
	 * Add a new tile to this floor plan
	 *
	 * @param texture The texture for the new tile
	 * @param rect The rect covered in uv coordinates (col, row, width, height)
	 * @param depth The depth of this tile in the tree
	 */
	addTile(texture: Texture, rect: Box2, depth: number): void {
		const t = new FloorPlanTile(texture, this.geometry, this.material, depth);
		t.name = `Depth ${depth} with box from (${rect.min.x}, ${rect.min.y}) to (${rect.max.x}, ${rect.max.y})`;

		const size = rect.getSize(new Vector2());
		t.scale.setX(size.x);
		t.scale.setY(size.y);
		t.translateX(0.5 * size.x + (rect.min.x - 0.5));
		t.translateY(-0.5 * size.y - (rect.min.y - 0.5));

		// Higher the depth, higher the resolution -> first to be rendered
		t.renderOrder = this.#maxDepth - depth - 1;
		this.add(t);
		this.textureChanged.emit();

		// Disable raycast on smaller tiles
		t.raycast = () => {};

		this.#textures.push(texture);
	}

	/**
	 * Replace all the current tiles with a single one covering the entire surface
	 *
	 * @param texture The new full sphere tile
	 */
	replaceOverview(texture: Texture): void {
		this.name = `TiledFloorPlan ${texture.name}`;
		this.#textures.push(texture);
		if (this.#animating && texture.image.width > 1024) {
			this.#pendingTile = texture;
		} else {
			this.#overviewTile.setTile(texture)?.dispose();
			this.textureChanged.emit();
		}
	}

	/**
	 * Remove one tile from this floor plan
	 *
	 * @param texture The tile we want to remove
	 */
	removeTile(texture: Texture): void {
		if (texture === this.#overviewTile.texture) return;
		for (const ts of this.children) {
			if (ts.texture === texture) {
				this.remove(ts);
				this.textureChanged.emit();
				return;
			}
		}
	}

	/** @returns The width of the overview texture*/
	get width(): number {
		return this.#overviewTile.texture?.image.width ?? 0;
	}

	/** @returns The height of the overview texture */
	get height(): number {
		return this.#overviewTile.texture?.image.height ?? 0;
	}

	/** @returns The current opacity of the images */
	get opacity(): number {
		return this.material.opacity;
	}

	/** Change the opacity of the images*/
	set opacity(opacity: number) {
		this.material.opacity = opacity;
		this.material.depthWrite = opacity > 0;
		this.material.needsUpdate = true;
	}
}
