import { Types } from "@amplitude/analytics-browser";
import { AmplitudeClient } from "./amplitude/amplitude-client";
import {
  EventProps,
  IInitParams,
  ISetPropsParams,
  PrivateData,
} from "./analytics-types";

const CENSOR_PRIVATE_DATA_PLUGIN_NAME = "censor-private-data";

/**
 * Module to provide analytics functionality
 *
 * Currently only provides a wrapper around AmplitudeClient
 * but might be extended to use different analytics services in the future
 */
class AnalyticsService {
  #client: AmplitudeClient = new AmplitudeClient();

  #defaultProps: EventProps = {};
  #userProperties: EventProps = {};
  #privateData?: PrivateData = undefined;

  async init(apiKey: string, props: IInitParams = {}): Promise<void> {
    try {
      await this.#client.init(apiKey, props).promise;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Amplitude client could not be initialized", { error });
    }
  }

  /**
   * Send an event to the analytics service
   *
   * @param eventName - Name of the the event being tracked
   * @param eventProps - Props of the event being tracked
   * @returns - A promise with the result of tracking event
   */
  track<Props extends EventProps = EventProps>(
    eventName: string,
    eventProps?: Props,
  ): Types.AmplitudeReturn<Types.Result> | undefined {
    // Updating the screen dimensions before each track call to attach the current sizes to the event
    this.#client.userProperties = {
      ...this.#userProperties,
      screenWidth: window.screen.width,
      windowWidth: window.innerWidth,
      screenHeight: window.screen.height,
      windowHeight: window.innerHeight,
      // screen.orientation() is not supported in iOS Safari 15.6-15.8, hence the optional chaining
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, compat/compat
      windowOrientation: window.screen.orientation?.type,
    };

    return this.#client.trackEvent(eventName, {
      ...eventProps,
      ...this.#defaultProps,
    });
  }

  /**
   * Enable/disable the analytics tracking of the user
   *
   * @param shouldOptOut True if the user wants to opt out of analytical tracking
   * @see https://developers.amplitude.com/docs/typescript-browser#tracking-switch
   */
  set optOutOfAnalytics(shouldOptOut: boolean) {
    this.#client.optOutOfAnalytics = shouldOptOut;
  }

  /**
   * Set the props that will be attached to every tracking event
   */
  set props({
    defaultProps: newDefaultProps,
    userId,
    userProps,
  }: ISetPropsParams) {
    if (newDefaultProps) {
      this.#defaultProps = { ...newDefaultProps };
    }
    if (userProps) {
      this.#userProperties = userProps;
      this.#client.userProperties = this.#userProperties;
    }
    if (userId) {
      this.#client.userId = userId;
    }
  }

  /**
   * Set the private data that should be censored from the event properties.
   *
   * This is accomplished with an Amplitude enrichment plugin that intercepts all events.
   *
   * The private data should be set _before_ initializing the analytics client, otherwise some events might not be censored.
   */
  set privateData(privateData: PrivateData | undefined) {
    if (this.#privateData) {
      this.#client.remove(CENSOR_PRIVATE_DATA_PLUGIN_NAME);
    }
    this.#privateData = privateData;
    this.#client.add(censorPrivateDataPlugin(this.#privateData));
  }

  /** @returns The currently assigned private data which will be censored. */
  get privateData(): PrivateData | undefined {
    return this.#privateData;
  }
}

/**
 * Remove private data from the page title of the built-in page view events.
 *
 * @param privateData The private data that should be removed from the page title.
 *  This must be a map from the name of the data, to the current data value.
 * @returns An enrichment plugin that removes the private data from the page view events.
 */
function censorPrivateDataPlugin(
  privateData?: PrivateData,
): Types.EnrichmentPlugin {
  return {
    name: CENSOR_PRIVATE_DATA_PLUGIN_NAME,
    type: "enrichment",
    execute: (event) => {
      if (
        // For now, only censor data in the built-in page view events
        event.event_type !== "[Amplitude] Page Viewed" ||
        !privateData ||
        Object.keys(privateData).length === 0 ||
        !event.event_properties
      ) {
        // If there is nothing to censor, just forward the event
        return Promise.resolve(event);
      }

      const censoredEventProperties = event.event_properties ?? {};
      let censoredPageTitle = censoredEventProperties["[Amplitude] Page Title"];

      if (typeof censoredPageTitle === "string") {
        // Replace all private data with placeholders
        for (const [name, data] of Object.entries(privateData)) {
          if (data) {
            censoredPageTitle = censoredPageTitle.replaceAll(
              data,
              `{{${name}}}`,
            );
          }
        }

        censoredEventProperties["[Amplitude] Page Title"] = censoredPageTitle;
      }

      const censoredEvent = {
        ...event,
        event_properties: censoredEventProperties,
      };

      return Promise.resolve(censoredEvent);
    },
  };
}

export const Analytics = new AnalyticsService();
