import { assert } from "@faro-lotv/foundation";
import { Box3, Camera, OrthographicCamera, PerspectiveCamera, Vector3 } from "three";
import { DEG_TO_RAD, degToRad } from "./Math";
import { SupportedCamera } from "./SupportedCamera";

export enum CameraMode {
	Perspective = 0,
	Orthographic = 1,
}

/** By default our perspective cameras should have 75 degrees as field of view, except when rendering pano images. */
export const DEFAULT_PERSPECTIVE_FOV = 75;

/**
 * Function to convert a size in pixels to the size in meters at a specified distance from the camera
 *
 * @param size The pixel size
 * @param cam The camera used to render this scene
 * @param viewportHeight The height of the viewport in pixels
 * @param distance The distance at which we want to compute the pixel2meter factor
 * @returns The size in meters
 */
export function pixels2m(size: number, cam: Camera, viewportHeight: number, distance: number): number {
	if (cam instanceof OrthographicCamera) {
		return size * ((cam.top - cam.bottom) / (viewportHeight * cam.zoom));
	}
	if (cam instanceof PerspectiveCamera) {
		const frustumHeight = 2 * distance * Math.tan(degToRad(cam.fov / 2));
		return size * (frustumHeight / (viewportHeight * cam.zoom));
	}
	return 0;
}

/**
 * Reproportions the aspect ratio of a given camera, taking care of both perspective and
 * orthographic projections.
 *
 * @param cam The camera
 * @param aspectRatio The aspect ratio to set to the camera
 */
export function reproportionCamera(cam: Camera, aspectRatio: number): void {
	assert(cam instanceof PerspectiveCamera || cam instanceof OrthographicCamera, "Unsupported camera");
	if (cam instanceof PerspectiveCamera) {
		cam.aspect = aspectRatio;
	} else {
		cam.right = cam.top * aspectRatio;
		cam.left = cam.bottom * aspectRatio;
	}
	cam.updateProjectionMatrix();
}

/**
 * Resets the view centering the given bounding box.
 *
 * @param camera The camera to be recentered.
 * @param bbox The bounding box to center the view on.
 * @param aspectRatio The current screen resolution aspect ratio, computed as width / height.
 * @param relativeDistance How far the camera should be placed away from the bounding box centre,
 * expressed as a factor that multiplies the bounding box's diagonal.
 */
export function zoomOn(camera: Camera, bbox: Box3, aspectRatio: number, relativeDistance = 1): void {
	assert(camera instanceof PerspectiveCamera || camera instanceof OrthographicCamera, "Unsupported camera");
	const bc = bbox.getCenter(new Vector3());
	const diagonal = bbox.max.distanceTo(bbox.min);
	camera.position.set(bc.x - diagonal * relativeDistance, bc.y, bc.z);
	camera.lookAt(bc.x, bc.y, bc.z);
	camera.updateMatrixWorld(true);
	if (camera instanceof PerspectiveCamera) {
		camera.far = diagonal * relativeDistance * 2;
		camera.near = camera.far * 0.001;
	} else {
		camera.far = diagonal * relativeDistance * 2;
		camera.near = camera.far * 0.001;
		camera.top = diagonal * 0.5;
		camera.bottom = -camera.top;
	}
	reproportionCamera(camera, aspectRatio);
}

/**
 * Returns a radians-per-pixel coefficient useful to convert pointer movement
 * pixels into rotation radians when implementing a 3D interactor
 *
 * @param camera The camera that is rendering the scene
 * @param height The viewport screen height
 * @returns The radians-per-pixel coefficient
 */
export function pixels2radians(camera: SupportedCamera, height: number): number {
	if (camera instanceof PerspectiveCamera) {
		return (camera.getEffectiveFOV() * DEG_TO_RAD) / height;
	}
	return (camera.top - camera.bottom) / height;
}
