import { assert } from "@faro-lotv/foundation";
import { Camera, DataTexture, Intersection, Matrix4, Object3D, Raycaster, Vector3 } from "three";
import { invertedRigid } from "../Utils/GeometricUtils";

export type ImagePoint = {
	row: number;
	col: number;
};

/**
 * @param row pixel row
 * @param col pixel col
 * @returns an imagePoint for this pixel
 */
export function imagePoint(row = 0, col = 0): ImagePoint {
	return {
		row,
		col,
	};
}

/**
 * Compute the final point coordinate in a equirectangular image applying word wrap
 *
 * @param point The point (can have out of bound row/cols)
 * @param width The equirectangular image width
 * @returns An image point with coordinates from 0 to width/height
 */
export function pacManWrap(point: ImagePoint, width: number): ImagePoint {
	const height = width / 2;
	let { row, col } = point;
	if (row < 0) {
		row = -row;
		col += width / 2;
	}
	if (row >= width) {
		row = height - 1 - (row - height + 1);
		col += width / 2;
	}

	if (row < 0) {
		row = height + row;
	}
	if (row >= height) {
		row = row - height;
	}
	if (col < 0) {
		col += width;
	}
	if (col >= width) {
		col -= width;
	}
	return imagePoint(row, col);
}

/**
 * Compute the pixel row/col coordinates from the index in an equirectangular image
 *
 * @param idx The index in the depth buffer
 * @param width The width of the equirectangular image
 * @returns The ImagePoint with the row/col of the pixel
 */
export function indexToPoint(idx: number, width: number): ImagePoint {
	return imagePoint(Math.floor(idx / width), Math.floor(idx % width));
}

/**
 * Compute the index of the pixel in the buffer from the row/col coordinates
 *
 * @param point Row/Col coordinates of the pixel
 * @param width The width of the equirectangular image
 * @returns The index of the pixel in the buffer
 */
export function pointToIndex(point: ImagePoint, width: number): number {
	return point.row * width + point.col;
}

/**
 * Extract the depth of a pixel from the depth buffer at the specified coordinates
 *
 * @param point The row/col of the point
 * @param depths The depth buffer
 * @param width The width of the equirectangular depth image
 * @returns The depth for that pixel
 */
export function depthFromPoint(point: ImagePoint, depths: Float32Array, width: number): number {
	return depths[pointToIndex(point, width)];
}

/**
 * Extract the coordinate of a point from the coords buffer
 *
 * @param point The point row/col
 * @param coords The coords buffer
 * @param width The width of the equirectangular depth image
 * @returns An array with x,y,z
 */
export function coordFromPoint(point: ImagePoint, coords: Float32Array, width: number): Vector3 {
	const idx = pointToIndex(point, width);
	return new Vector3(coords[idx * 3 + 0], coords[idx * 3 + 1], coords[idx * 3 + 2]);
}

/**
 * Obtain the coordinates of a point from another point and an offset
 *
 * @param point The starting point
 * @param offset The offset to apply (row, col)
 * @param width The width of the equirectangolar depth image
 * @returns The row/col of the moved point wrapped inside the image
 */
export function applyOffset(point: ImagePoint, offset: ImagePoint, width: number): ImagePoint {
	return pacManWrap(imagePoint(point.row + offset.row, point.col + offset.col), width);
}

/**
 * Iterate on all the different triangles around a point along a square.
 *
 * @generator
 * @param point The center point
 * @param width The width of the equirectangular depth image
 * @param offset The offset used to pick points around the center
 * @yields two ImagePoint for the other two vertices of the triangle
 */
function* trianglesAround(
	point: ImagePoint,
	width: number,
	offset: number = 1,
): Generator<{ b: ImagePoint; c: ImagePoint }> {
	assert(offset > 0, "Offset must be positive");

	const offsets = pointsAround(offset);

	let b = offsets.next();
	let c = offsets.next();
	while (!b.done && !c.done) {
		yield {
			b: applyOffset(point, b.value, width),
			c: applyOffset(point, c.value, width),
		};
		b = c;
		c = offsets.next();
	}
}
/**
 * Generate ordered points around a center point along a square.
 * Each edge of the square is offset from center at specified pixel distance.
 * Every two consecutive points can be used to form a triangle with center.
 *
 * @param offset The pixel distance from the center
 * @yields The points around the center
 */
function* pointsAround(offset: number): Generator<ImagePoint> {
	for (let i = -offset; i < offset; i++) {
		yield imagePoint(offset, i);
	}
	for (let i = offset; i > -offset; i--) {
		yield imagePoint(i, offset);
	}
	for (let i = offset; i > -offset; i--) {
		yield imagePoint(-offset, i);
	}
	for (let i = -offset; i < offset; i++) {
		yield imagePoint(i, -offset);
	}
	// first point repeated to close the square
	yield imagePoint(offset, -offset);
}

/**
 * Validate if a triangle can be used to estimate the normal of a point
 *
 * @param a Vec3s of the first vertex
 * @param b Vec3s of the second vertex
 * @param c Vec3s of the third vertex
 * @param depths Depth buffer
 * @param width Width of the depth equirectangular image
 * @returns True if the triangle is valid for normal estimation
 */
function validTriangle(a: ImagePoint, b: ImagePoint, c: ImagePoint, depths: Float32Array, width: number): boolean {
	const maxDiscontinuity = 1.5;
	const depthA = depthFromPoint(a, depths, width);
	const depthB = depthFromPoint(b, depths, width);
	const depthC = depthFromPoint(c, depths, width);
	return (
		depthA !== 0 &&
		depthB !== 0 &&
		depthC !== 0 &&
		Math.abs(depthA - depthB) < maxDiscontinuity &&
		Math.abs(depthA - depthC) < maxDiscontinuity
	);
}

/**
 * A depth texture for an equirectangular image
 */
export class EquirectangularDepthImage extends DataTexture {
	coordsCache = new Map<number, Vector3>();
	normalsCache = new Map<number, Vector3>();

	/** The offset used to pick triange points around for normal computation */
	normalComputeTrianglePointsOffset = 1;

	/** The global pose matrix of the pano image */
	#worldMatrix = new Matrix4();
	/** The global position of the pano image */
	#position = new Vector3();
	/** The global inverse rotation of the pano image */
	#worldRotationInv = new Matrix4();
	/** The raycast direction in local coordinates, allocated here once for all. */
	#localDirection = new Vector3();
	/** The buffer of depth values for this pano image */
	private depths: Float32Array;

	/**
	 * Construct an equirectangular depth image
	 *
	 * @param width The image width
	 * @param data The data buffer with the depths
	 */
	constructor(width: number, data: Float32Array) {
		super(data, width, width / 2);
		this.depths = data;
	}
	/**
	 * sets the world matrix for the pano
	 *
	 * @param worldMatrix the world matrix of this pano
	 */
	setPanoWorldMatrix(worldMatrix: Matrix4): void {
		this.#worldMatrix = worldMatrix;
		this.#position.setFromMatrixPosition(worldMatrix);
		this.#worldRotationInv.extractRotation(this.#worldMatrix);
		this.#worldRotationInv.invert();
		this.coordsCache.clear();
		this.normalsCache.clear();
	}

	/**
	 * Returns the index of a point in the depth array from the point coordinates
	 *
	 * @param row The pixel row
	 * @param col The pixel col
	 * @returns The index in the depth array
	 */
	indexOf(row: number, col: number): number {
		return row * this.image.width + col;
	}

	/**
	 * Return the depth for a depth image pixel
	 *
	 * @param rowOrIndex row in pixels if two arguments, or index if only one
	 * @param col col in pixels
	 * @returns The depth at that pixel
	 */
	depth(rowOrIndex: number, col?: number): number {
		if (col) {
			return this.image.data[this.indexOf(rowOrIndex, col)];
		}
		return this.image.data[rowOrIndex];
	}

	/**
	 * Return the coordinates for one depth image point (around the point 0, 0, 0)
	 *
	 * @param row row in pixels
	 * @param col col in pixels
	 * @returns the coordinate
	 */
	coord(row: number, col: number): Vector3 | undefined {
		const index = this.indexOf(row, col);
		let coord = this.coordsCache.get(index);
		if (coord) return coord;
		const { width } = this.image;
		const depth = this.depth(index);
		if (!depth) return;

		const theta = (1 - (2 * col) / width) * Math.PI;
		const phi = (1 - (2 * row) / width) * Math.PI + Math.PI * 0.5;
		const cosTheta = Math.cos(theta);
		const sinTheta = Math.sin(theta);
		const cosPhi = Math.cos(phi);
		const sinPhi = Math.sin(phi);
		const xy = depth * cosPhi;
		const x = -xy * cosTheta;
		const y = -xy * sinTheta;
		const z = depth * sinPhi;
		coord = new Vector3(-x, -y, -z).applyMatrix4(this.#worldMatrix);
		this.coordsCache.set(index, coord);
		return coord;
	}

	/**
	 * Compute the normal for a depth image point
	 *
	 * @param row Row in the depth image
	 * @param col Col in the depth image
	 * @returns The normal if depths are available
	 */
	normal(row: number, col: number): Vector3 | undefined {
		const index = this.indexOf(row, col);
		let normal = this.normalsCache.get(index);
		if (normal) return normal;
		const depthWidth = this.image.width;
		const A = this.coord(row, col);
		if (!A) return;

		const point = imagePoint(row, col);
		normal = new Vector3();
		for (const { b, c } of trianglesAround(point, depthWidth, this.normalComputeTrianglePointsOffset)) {
			if (validTriangle(point, b, c, this.depths, depthWidth)) {
				const B = this.coord(b.row, b.col)?.clone();
				const C = this.coord(c.row, c.col)?.clone();
				if (!B || !C) return;
				normal.add(B.sub(A).cross(C.sub(A)));
			}
		}
		normal.normalize();
		this.normalsCache.set(index, normal);
		return normal;
	}

	/**
	 * Pick the first valid depth in a 3x3 square around the selected pixel
	 *
	 * @param row Pixel row
	 * @param col Pixel column
	 * @returns the row, col and depth of the valid pixel found
	 */
	validDepth3x3(row: number, col: number): { row: number; col: number; depth: number } | undefined {
		const offsets = [
			imagePoint(0, 0),
			imagePoint(-1, -1),
			imagePoint(-1, 0),
			imagePoint(-1, 1),
			imagePoint(0, -1),
			imagePoint(0, 1),
			imagePoint(1, -1),
			imagePoint(1, 0),
			imagePoint(1, 1),
		];

		for (const offset of offsets) {
			const adjusted = applyOffset({ row, col }, offset, this.image.width);
			const index = this.indexOf(adjusted.row, adjusted.col);
			const depth = this.depths[index];
			if (depth !== 0) {
				return { row: adjusted.row, col: adjusted.col, depth };
			}
		}
		return undefined;
	}

	/**
	 * Compute the canvas coordinates from a 3d world point
	 *
	 * @param point The 3d world point
	 * @returns The canvas row and col
	 */
	worldToPixel(point: Vector3): { row: number; col: number } {
		const { width } = this.image;
		const height = width / 2;

		// The minus sign is required because the y-axis (i.e. rows) is upside down
		// with respect to the pano image up direction.
		const theta = -Math.atan2(point.z, Math.sqrt(point.x * point.x + point.y * point.y));
		const phi = Math.atan2(point.y, point.x);

		const tx = (phi / (2 * Math.PI) + 1) % 1;
		const ty = theta / Math.PI + 0.5;

		const col = Math.ceil((1 - tx) * (width - 1));
		const row = Math.ceil(ty * (height - 1));
		return { row, col };
	}

	/**
	 * Compute the pixel coordinates on the color texture from the ndc canvas coordinates
	 *
	 * @param x canvas col [-1, 1] with 1 the rightmost column
	 * @param y canvas ro [-1, 1] with 1 the upper column
	 * @param camera The current camera rendering the scene
	 * @returns The pixel row and column on the color texture
	 */
	ndcToPixel(x: number, y: number, camera: Camera): { row: number; col: number } {
		const vMatrix = camera.matrixWorldInverse.clone();
		vMatrix.setPosition(0, 0, 0);

		const mMatrix = this.#worldMatrix.clone();
		mMatrix.setPosition(0, 0, 0);

		const mvMatrix = new Matrix4();
		mvMatrix.multiplyMatrices(vMatrix, mMatrix);
		mvMatrix.setPosition(0, 0, 0);

		const pvmMatrix = new Matrix4();
		pvmMatrix.multiplyMatrices(camera.projectionMatrix, mvMatrix);

		const T = pvmMatrix.clone().invert();
		const point = new Vector3(x, y, 1).applyMatrix4(T);

		return this.worldToPixel(point);
	}

	/**
	 * Check if a world point is visible from this pano.
	 * The computation requires depth information. If we don't have depth information we assume no point is visible
	 * as a default.
	 *
	 * @param point The point we want to check
	 * @returns true if it was visible by the sensor (dist < depth) false if it was not visible (dist > depth) or we don't have depths
	 */
	isVisible(point: Vector3): boolean {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		if (!this.depths) return false;

		const dist = point.distanceTo(new Vector3().applyMatrix4(this.#worldMatrix));
		point.applyMatrix4(invertedRigid(this.#worldMatrix, new Matrix4())).normalize();

		const depthPixel = this.worldToPixel(point);

		const { depth } = this.validDepth3x3(depthPixel.row, depthPixel.col) ?? { depth: 0 };
		return depth > dist;
	}

	/** @inheritdoc */
	raycast<O extends Object3D>(object: O, raycaster: Raycaster, intersects: Array<Intersection<Object3D>>): void {
		const { origin, direction } = raycaster.ray;

		if (origin.distanceTo(this.#position) > 0.01) {
			console.warn("Raycasting on pano works only if the camera is in the pano position");
			return;
		}

		// The direction is the point on our sphere but we need to account for this pano rotations
		this.#localDirection.copy(direction);
		const point = this.#localDirection.applyMatrix4(this.#worldRotationInv);
		const { row, col } = this.worldToPixel(point);

		if (Number.isNaN(row) || Number.isNaN(col)) {
			console.log(`Cannot retrieve point at coordinates row: ${row}, col: ${col}`);
			return;
		}

		// If we have depth we will always have a point and a normal
		const coord = this.coord(row, col);
		const normal = this.normal(row, col);
		if (!coord || !normal) return;
		const intersection: Intersection<O> = {
			distance: coord.distanceTo(this.#position),
			object,
			point: coord,
			face: {
				a: 0,
				b: 0,
				c: 0,
				materialIndex: 0,
				normal,
			},
		};
		intersects.push(intersection);
	}
}
