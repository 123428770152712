import { ThreeEvent } from "@react-three/fiber";
import { useCallback, useEffect, useState } from "react";
import { Intersection } from "three";
import { useOverrideCursor } from "../hooks";

export type UseWaypointsEventsProps = {
  /** Overrides the hover state of the internal hover */
  hoveredPlaceholderId?: number;

  /** Id of the placeholder shown as selected */
  selectedPlaceholderId?: number;

  /** Callback executed when the id of the hovered element changes */
  onWaypointHovered?(placeholderId?: number): void;

  /** Callback to signal a placeholder have been clicked*/
  onWaypointClicked?(placeholderId: number, event: MouseEvent): void;

  /** Compute the waypoint index from the raycast intersection */
  indexFromHit(hit?: Intersection): number | undefined;
};

export type UseWaypointsEventsReturn = {
  /** Event to attach to the waypoints render object */
  onPointerMove(ev: ThreeEvent<PointerEvent>): void;

  /** Event to attach to the waypoints render object */
  onPointerLeave(ev: ThreeEvent<PointerEvent>): void;

  /** Event to attach to the waypoints render object */
  onClick(ev: ThreeEvent<MouseEvent>): void;

  /** Current hovered index */
  hoveredIndex: number | undefined;

  /** Current selected index */
  selectedIndex: number | undefined;
};

/**
 * Handle the low level waypoints events and expose two simplified hovered and click waypoints events
 *
 * @returns the events and state to link to the waypoint renderer
 */
export function useWaypointsEvents({
  hoveredPlaceholderId,
  selectedPlaceholderId,
  onWaypointClicked,
  onWaypointHovered,
  indexFromHit,
}: UseWaypointsEventsProps): UseWaypointsEventsReturn {
  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>();
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();

  /** Shows a pointer mouse cursor on hover */
  useOverrideCursor("pointer", hoveredIndex !== undefined);

  /** Syncs the external hover state into the internal hover index */
  useEffect(() => {
    setHoveredIndex(
      hoveredPlaceholderId !== undefined && hoveredPlaceholderId !== -1
        ? hoveredPlaceholderId
        : undefined,
    );
  }, [hoveredPlaceholderId]);

  /** Syncs the external selected state into the internal selected index */
  useEffect(() => {
    setSelectedIndex(
      selectedPlaceholderId !== undefined && selectedPlaceholderId !== -1
        ? selectedPlaceholderId
        : undefined,
    );
  }, [selectedPlaceholderId]);

  // Update the hovered state tracking the pointer move
  const onPointerMove = useCallback(
    (ev: ThreeEvent<PointerEvent>) => {
      const hits = ev.intersections;

      // The mouse is still on the current hovered placeholder do nothing
      if (
        hoveredIndex !== undefined &&
        hits.some((hit) => indexFromHit(hit) === hoveredIndex)
      ) {
        return;
      }

      // Update the hoveredIndex to the front most placeholder or undefined if no placeholder was hit
      const hit = indexFromHit(
        hits.find((hit) => indexFromHit(hit) !== undefined),
      );
      setHoveredIndex(hit);

      if (onWaypointHovered) {
        onWaypointHovered(hit);
        ev.stopPropagation();
      }
    },
    [hoveredIndex, indexFromHit, onWaypointHovered],
  );

  const onClick = useCallback(
    (ev: ThreeEvent<PointerEvent>) => {
      if (!onWaypointClicked || ev.delta > 1) return;

      if (hoveredIndex === undefined) {
        // On touch devices there is no hovered index, so we need to find the hit
        const hit = indexFromHit(
          ev.intersections.find((hit) => indexFromHit(hit) !== undefined),
        );

        if (hit !== undefined) {
          onWaypointClicked(hit, ev.nativeEvent);
          ev.stopPropagation();
        }
      } else {
        onWaypointClicked(hoveredIndex, ev.nativeEvent);
        ev.stopPropagation();
      }
    },
    [hoveredIndex, indexFromHit, onWaypointClicked],
  );

  const onPointerLeave = useCallback(() => {
    setHoveredIndex(undefined);
    onWaypointHovered?.(undefined);
  }, [onWaypointHovered]);

  return {
    onClick,
    onPointerLeave,
    onPointerMove,
    hoveredIndex,
    selectedIndex,
  };
}
