import { Stack, SxProps, Theme } from "@mui/system";
import { ReactNode, useMemo, useState } from "react";
import { cyan, neutral } from "../colors";
import { FaroIconButton } from "../icon-button/faro-icon-button";
import {
  Checkmark3Icon,
  CloseCancelErrorIcon,
  DeleteIcon,
  EditIcon,
} from "../icons";
import { TruncatedFaroText } from "../text/truncated-text";
import {
  FormHelperErrorTextDark,
  FormHelperErrorTextLight,
} from "./base-inputs";
import { DEFAULT_FIELD_WIDTH, TextField } from "./text-field";

/** The height of the text field */
const TEXT_FIELD_HEIGHT = "36px";

export type ActionTextFieldProps = {
  /** Text displayed in the component */
  inputText: string;
  /** If true the dark colors are used */
  dark?: boolean;
  /** If true the component is disabled */
  disabled?: boolean;
  /** If true the component is displayed in full width */
  fullWidth?: boolean;
  /** Optional style for the component */
  sx?: SxProps<Theme>;
  /** Function used to validate the input text. It should return the error message if the text is invalid */
  validate?(text: string): ReactNode;
  /** Function called when the delete button is clicked */
  onDeleteButtonClick?(): void;
  /** Function called when the confirm button is clicked in the editing state */
  onConfirmButtonClick?(text: string): void;
  /** Function called when the cancel button is clicked in the editing state */
  onCancelButtonClick?(): void;
};

/**
 * @returns a text component with action buttons to edit or delete the text
 */
export function ActionTextField({
  inputText,
  dark,
  disabled,
  fullWidth,
  sx,
  validate,
  onDeleteButtonClick,
  onConfirmButtonClick,
  onCancelButtonClick,
}: ActionTextFieldProps): JSX.Element {
  const [isEditing, setIsEditing] = useState(false);

  if (isEditing && !disabled) {
    return (
      <ActionTextFieldEdit
        inputText={inputText}
        dark={dark}
        fullWidth={fullWidth}
        sx={sx}
        validate={validate}
        setIsEditing={setIsEditing}
        onConfirmButtonClick={onConfirmButtonClick}
        onCancelButtonClick={onCancelButtonClick}
      />
    );
  }
  return (
    <ActionTextFieldIdle
      inputText={inputText}
      dark={dark}
      disabled={disabled}
      fullWidth={fullWidth}
      sx={sx}
      validate={validate}
      setIsEditing={setIsEditing}
      onDeleteButtonClick={onDeleteButtonClick}
    />
  );
}

type ActionTextFieldIdleProps = Pick<
  ActionTextFieldProps,
  | "inputText"
  | "dark"
  | "disabled"
  | "fullWidth"
  | "sx"
  | "validate"
  | "onDeleteButtonClick"
> & {
  /** Function to set the editing state */
  setIsEditing(value: boolean): void;
};

function ActionTextFieldIdle({
  inputText,
  dark,
  disabled,
  fullWidth,
  sx,
  validate,
  setIsEditing,
  onDeleteButtonClick,
}: ActionTextFieldIdleProps): JSX.Element {
  /** Depending on the prop dark, this should be the color to use for the text and icons */
  const inputColor = useMemo(
    () => (dark ? neutral[100] : neutral[800]),
    [dark],
  );

  const errorMessage = useMemo(
    () => validate?.(inputText),
    [inputText, validate],
  );

  const FormHelperTextVariant = dark
    ? FormHelperErrorTextDark
    : FormHelperErrorTextLight;

  return (
    <Stack
      direction="column"
      sx={{
        width: fullWidth ? "100%" : "fit-content",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        sx={{
          width: fullWidth ? "100%" : DEFAULT_FIELD_WIDTH,
          height: TEXT_FIELD_HEIGHT,
          px: 1.5,
          py: 0.5,
          // Show the error line at the bottom even in the idle state, if there is an error
          ...(errorMessage !== undefined && { boxShadow: "0 -1px  red inset" }),
          ...sx,
        }}
      >
        <TruncatedFaroText
          variant="bodyM"
          dark={dark}
          // This is set so that the ellipse has the same color of the text
          containerSx={{ color: inputColor }}
          sx={{
            // Change color opacity if the component is disabled
            opacity: disabled ? 0.5 : 1,
          }}
        >
          {inputText}
        </TruncatedFaroText>
        <Stack direction="row" gap={1}>
          <FaroIconButton
            onClick={(ev) => {
              ev.stopPropagation();
              setIsEditing(true);
            }}
            size="s"
            color={inputColor}
            hoverColor={getIconButtonHoverColor(dark)}
            margin={0.5}
            disabled={disabled}
          >
            <EditIcon />
          </FaroIconButton>
          <FaroIconButton
            onClick={(ev) => {
              ev.stopPropagation();
              onDeleteButtonClick?.();
            }}
            size="s"
            color={inputColor}
            hoverColor={getIconButtonHoverColor(dark)}
            margin={0.5}
            disabled={disabled}
          >
            <DeleteIcon />
          </FaroIconButton>
        </Stack>
      </Stack>
      {/* Error message */}
      {errorMessage !== undefined && (
        <FormHelperTextVariant
          sx={{
            // The margin is used to achieve the same style as the error shown in the TextField component
            mt: "4px",
            fontWeight: 600,
          }}
        >
          {errorMessage}
        </FormHelperTextVariant>
      )}
    </Stack>
  );
}

type ActionTextFieldEditProps = Pick<
  ActionTextFieldProps,
  | "inputText"
  | "dark"
  | "fullWidth"
  | "sx"
  | "validate"
  | "onConfirmButtonClick"
  | "onCancelButtonClick"
> &
  Pick<ActionTextFieldIdleProps, "setIsEditing">;

function ActionTextFieldEdit({
  inputText,
  dark,
  fullWidth,
  sx,
  validate,
  setIsEditing,
  onConfirmButtonClick,
  onCancelButtonClick,
}: ActionTextFieldEditProps): JSX.Element {
  const [text, setText] = useState(inputText);

  const iconColor = useMemo(() => (dark ? neutral[100] : neutral[800]), [dark]);

  // Validate the text when it changes
  const errorMessage = useMemo(() => validate?.(text), [validate, text]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        width: fullWidth ? "100%" : "fit-content",
        ...sx,
      }}
      onClick={(ev) => {
        // Prevent the click event from propagating to the parent when the user clicks on the text field in the editing state
        ev.stopPropagation();
      }}
    >
      <TextField
        fullWidth={fullWidth}
        variant="underlined"
        text={text}
        autoFocus
        dark={dark}
        onTextChanged={setText}
        error={errorMessage}
        sx={{ ".MuiInputBase-input": { p: 0 }, px: 1.5, py: 0.5 }}
        endAdornment={
          <Stack direction="row" gap={1} ml={2}>
            <FaroIconButton
              size="s"
              color={iconColor}
              hoverColor={getIconButtonHoverColor(dark)}
              margin={0.5}
              disabled={errorMessage !== undefined}
              onClick={(ev) => {
                ev.stopPropagation();

                onConfirmButtonClick?.(text);
                setIsEditing(false);
              }}
            >
              <Checkmark3Icon />
            </FaroIconButton>
            <FaroIconButton
              onClick={(ev) => {
                ev.stopPropagation();

                onCancelButtonClick?.();
                setIsEditing(false);
              }}
              size="s"
              color={iconColor}
              hoverColor={getIconButtonHoverColor(dark)}
              margin={0.5}
            >
              <CloseCancelErrorIcon />
            </FaroIconButton>
          </Stack>
        }
      />
    </Stack>
  );
}

/**
 * @param dark - if true the dark colors are used
 * @returns the hover color for the icon button
 */
function getIconButtonHoverColor(dark?: boolean): string {
  return dark ? cyan[400] : "primary.main";
}
