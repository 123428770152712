import { log } from "./logging/log";

/** All supported primitive types */
export type PrimitiveTypes =
  | "string"
  | "number"
  | "bigint"
  | "boolean"
  | "undefined"
  | "symbol"
  | "object";

/** Symbol used to notify a property can not be null or undefined in the IElement type definition */
export const PropRequired = Symbol("PropRequired");

/** Symbol used to notify that a property can be null or undefined in the IElement type definition */
export const PropOptional = Symbol("PropOptional");

/** A flag to mark a property to nullable or not in the schema */
export type Optionality = typeof PropRequired | typeof PropOptional;

/** A function validating the type of a value. */
export type TypeGuard<Target extends Source, Source = unknown> = (
  value: Source,
) => value is Target;

/**
 * @returns true if the prop is missing but optional, false if it's not missing or not optional
 * @param prop to check
 * @param optionality to validate if the prop is optional
 */
export function isOptionalPropMissing(
  prop: unknown,
  optionality: Optionality,
): boolean {
  if (prop !== null && prop !== undefined) {
    return false;
  }
  return optionality === PropOptional;
}

/**
 * Verify a property on an object is of a required primitive type
 *
 * @param object to check
 * @param prop to check
 * @param type required
 * @param optionality to define if this property can be null
 * @returns true if the prop is of the required type
 */
export function validatePrimitive(
  object: unknown,
  prop: string | number | symbol,
  type: PrimitiveTypes,
  optionality: Optionality = PropRequired,
): boolean {
  // @ts-expect-error the validity of the property is checked in the body of the function
  const toCheck = object[prop];
  return (
    isOptionalPropMissing(toCheck, optionality) ||
    check(
      typeof toCheck === type,
      "Property",
      prop,
      "with value",
      toCheck,
      "is not of type",
      type,
    )
  );
}

/**
 * Verify that a property is a non empty string
 *
 * @param object to check
 * @param prop to check
 * @param optionality to define if this property can be null
 * @returns true if the prop is a non empty string
 */
export function validateNonEmptyString<O extends object, K extends keyof O>(
  object: O,
  prop: K,
  optionality: Optionality = PropRequired,
): boolean {
  return (
    isOptionalPropMissing(object[prop], optionality) ||
    (validatePrimitive(object, prop, "string") &&
      check(
        object[prop] !== "",
        `Property ${String(prop)} should not be empty`,
      ))
  );
}

/**
 * Check a value is an object and is not null or undefined
 *
 * An expected type `T` can be specified in the generic argument to pre-type the object as `Partial<T>` after validation.
 * Note that the types of the properties *still need to be validated*, this functionality only exists
 * to remove some typing boilerplate in the validation logic.
 *
 * @param object to check
 * @param name of the object we expect to receive for logging purposes as it can't be checked in Javascript
 * @returns true if it's not null or undefined and is an object and not another primitive type
 */
export function validateNotNullishObject<T = Record<string, unknown>>(
  object: unknown,
  name: string,
): object is NonNullable<Partial<T>> {
  if (!object) {
    log(`expected object is ${object} instead of ${name}`);
    return false;
  }
  if (typeof object !== "object") {
    log(`expected object is of type ${typeof object} instead of ${name}`);
    return false;
  }

  return true;
}

/**
 * Verify a property has a specific value
 *
 * @param object to check
 * @param prop to check
 * @param expectedValue to check
 * @returns true if the prop has the expected value
 */
export function validateOfExpectedValue<
  O extends object,
  K extends keyof O,
  T extends O[K],
>(object: O, prop: K, expectedValue: T): boolean {
  if (object[prop] !== expectedValue) {
    log(
      `Property ${String(
        prop,
      )} is not matching the expected value ${expectedValue}`,
    );
    return false;
  }
  return true;
}

export type IsArrayOfArguments = {
  /** object to check */
  object: unknown;

  /** name of the property to check */
  prop: string | number | symbol;

  /** type guard to check all the elements of the array */
  elementGuard(x: unknown): boolean;

  /** if defined this is the required size of the array */
  size?: number;

  /** if defined this decide if the prop can be null or not @default NotNull */
  optionality?: Optionality;
};

/**
 * @returns true if the prop is an array containing object of a specific type
 */
export function validateArrayOf({
  object,
  prop,
  elementGuard,
  size,
  optionality = PropRequired,
}: IsArrayOfArguments): boolean {
  // @ts-expect-error the validity of the property is checked in the body of the function
  const toCheck = object[prop];
  return (
    isOptionalPropMissing(toCheck, optionality) ||
    check(
      Array.isArray(toCheck) &&
        toCheck.every(elementGuard) &&
        (!size || toCheck.length === size),
      "Property",
      prop,
      "with value",
      toCheck,
      "is not an array matching",
      elementGuard,
      "and size",
      size,
    )
  );
}

/**
 * Verify that a property is a valid value in an array
 *
 * @param object to check
 * @param prop to check
 * @param e enum to check for
 * @param optionality to define if this property can be null
 * @returns true if the prop is a valid enum
 */
export function validatePropertyEnumValue<
  O extends object,
  K extends keyof O,
  Enum extends Record<string, unknown>,
>(
  object: O,
  prop: K,
  e: Enum,
  optionality: Optionality = PropRequired,
): boolean {
  const value = object[prop];
  return (
    isOptionalPropMissing(value, optionality) ||
    check(
      validateEnumValue(value, e),
      "Property",
      prop,
      "with value",
      value,
      "is not valid for enum",
      Object.values(e),
    )
  );
}

/**
 * Check if the value is an instance of the enum.
 *
 * @param value The value to check.
 * @param e The enum that the value might be part of.
 * @returns True, if the value is an instance of the enum.
 */
export function validateEnumValue<Enum extends Record<string, unknown>>(
  value: unknown,
  e: Enum,
): value is Enum[keyof Enum] {
  return Object.values<unknown>(e).includes(value);
}

/**
 * Verify that a property on an object is of a required type
 *
 * @param object to check
 * @param prop to check
 * @param typeGuard to check if the object respect the expected type
 * @param optionality to define if this property can be null
 * @returns true if the prop is of the required type
 */
export function validateOfType<O extends object, K extends keyof O>(
  object: O,
  prop: K,
  typeGuard: (x: unknown) => boolean,
  optionality: Optionality = PropRequired,
): boolean {
  const value = object[prop];
  return (
    isOptionalPropMissing(value, optionality) ||
    check(
      typeGuard(value),
      "Property",
      prop,
      "with value",
      value,
      "does not respect the type guard",
      typeGuard,
    )
  );
}

/**
 * Log a message if the passed condition is not met and return the condition for further logic
 *
 * @param condition condition checked
 * @param message message to log to notify what failed
 * @param args additional arguments to log
 * @returns the same condition for further logic
 */
function check(
  condition: boolean,
  message: string,
  ...args: unknown[]
): boolean {
  if (!condition) {
    log(message, ...args);
  }
  return condition;
}
