import { Box, Stack, SxProps } from "@mui/material";
import {
  PropsWithChildren,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { BasePopover } from "../../faro-popover/base-popover";
import { FaroChipTag, FaroChipTagProps } from "../chip-tag/chip-tag";

export interface FaroChipListProps
  extends Pick<FaroChipTagProps, "size" | "dark"> {
  /** The chips to show. Can be arbitrary react nodes, but styles are optimized for FaroChip. */
  chips: ReactNode[];

  /** Whether to use dark styles for the popup */
  dark?: boolean;

  /** List custom styling */
  sx?: SxProps;

  /** Always show the chip if there's only one */
  showSingleChip?: boolean;

  /** Draw the list from right to left */
  reverse?: boolean;
}

/**
 * @returns a horizontal list of chips that handles overflows by showing an additional "+n" chip with a dropdown menu
 *
 * If only one chip is defined it will be always shown
 */
export function FaroChipList({
  chips,
  dark,
  size,
  sx,
  showSingleChip = true,
  reverse = false,
}: FaroChipListProps): JSX.Element {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const [numVisible, setNumVisible] = useState(0);

  // These wrapped chips are used to detect after which chip the "+n" chip should be inserted.
  const wrappedChips = container
    ? chips.map((c, index) => (
        <HideWhenOverflown
          key={index}
          container={container}
          numElementsLeft={chips.length - (index + 1)}
          onVisibilityChanged={(isVisible) =>
            setNumVisible((prevNumVisible) => {
              // Knowing whether a single chip is visible doesn't give us the numVisible state directly,
              // but it gives us an upper/lower bound that we can use to update the state.
              if (isVisible) {
                const minNumVisible = index + 1;
                return Math.max(minNumVisible, prevNumVisible);
              }
              return Math.min(index, prevNumVisible);
            })
          }
        >
          {c}
        </HideWhenOverflown>
      ))
    : [];

  const numOverflown = chips.length - numVisible;

  const firstChip = chips.at(0);

  if (showSingleChip && firstChip && chips.length === 1) {
    // Fragment is needed to return a single ReactNode as a JSX.Element
    //  eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{firstChip}</>;
  }

  return (
    <Stack sx={sx}>
      {/** Render all the chips upfront to check for intersections, but don't make them visible */}
      <Stack
        ref={setContainer}
        direction="row"
        gap={1}
        sx={{ maxHeight: 0, visibility: "hidden", overflow: "hidden" }}
      >
        {wrappedChips}
      </Stack>
      <Stack
        direction={reverse ? "row-reverse" : "row"}
        gap={1}
        overflow="hidden"
      >
        {chips.slice(0, numVisible)}

        {numOverflown > 0 && (
          <Box ref={setAnchorEl} component="div">
            <FaroChipTag
              color={undefined}
              label={`+${numOverflown}`}
              dark={dark}
              size={size}
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            />
            {anchorEl && (
              <BasePopover
                anchorEl={anchorEl}
                isAnimated
                open={isPopoverOpen}
                onClose={() => setIsPopoverOpen(false)}
                showCloseButton={false}
                dark={dark}
                popperSx={{
                  minWidth: 0,
                  maxHeight: 300,
                  overflowY: "auto",
                  px: 1.5,
                  py: 1,
                }}
                popperOptions={{
                  placement: "bottom-start",
                }}
              >
                <Stack gap={0.5}>{chips.slice(numVisible)}</Stack>
              </BasePopover>
            )}
          </Box>
        )}
      </Stack>
    </Stack>
  );
}

interface HideWhenOverflownProps {
  /** The container element to detect overflows with */
  container: HTMLDivElement;

  /** The number of elements after */
  numElementsLeft: number;

  /** callback executed when the visibility changes */
  onVisibilityChanged?(newVisibility: boolean): void;
}

/** @returns wrapped component that hides itself, when it overflows its parent. */
function HideWhenOverflown({
  numElementsLeft,
  container,
  onVisibilityChanged,
  children,
}: PropsWithChildren<HideWhenOverflownProps>): JSX.Element {
  const measureRef = useRef<HTMLDivElement>(null);

  // Creates an ResizeObserver to detect how much the element intersects its container,
  // by comparing the width of parent with the current width + offset of the child
  useLayoutEffect(() => {
    function onIntersection(): void {
      if (!measureRef.current) return;

      const containerRect = container.getBoundingClientRect();
      const chipRect = measureRef.current.getBoundingClientRect();

      const LARGE_CHIP_MARGIN = 48;
      const SMALL_CHIP_MARGIN = 40;

      // Change the margin used for the intersection based on the width of the chip
      // containing the number of remaining tags
      let margin = 0;
      if (numElementsLeft > 9) {
        margin = LARGE_CHIP_MARGIN;
      } else if (numElementsLeft > 0) {
        margin = SMALL_CHIP_MARGIN;
      }

      const isInBounds =
        Math.floor(chipRect.left + chipRect.width + margin) <=
        Math.ceil(containerRect.left + containerRect.width);
      onVisibilityChanged?.(isInBounds);
    }

    const observer = new ResizeObserver(onIntersection);
    observer.observe(container);
    return () => observer.disconnect();
  }, [container, onVisibilityChanged, numElementsLeft]);

  return <div ref={measureRef}>{children}</div>;
}
