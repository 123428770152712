import { DesaturatePass as LotvDesaturate } from "@faro-lotv/lotv";
import { useState } from "react";
import { useForwardProp } from "../../hooks/use-forward-prop";
import { attachPass } from "../attach-utils";

export type DesaturatePassProps = {
  /**
   * Factor multiplied by the saturation component of the HSL color of the input FBO. 0 => total desaturation. *
   *
   * @default 0.0
   */
  saturationMultiplier?: number;

  /**
   * Factor multiplied by the lightness component of the HSL color of the input FBO.
   *
   * @default 1.0
   */
  lightnessMultiplier?: number;

  /**
   * Whether the pass is enabled
   *
   * @default true
   */
  enabled?: boolean;
};

/**
 * @returns An EffectPipeline pass that modifies the saturation and lightness of the input FBO.
 */
export function DesaturatePass({
  saturationMultiplier = 0.0,
  lightnessMultiplier = 1.0,
  enabled = true,
}: DesaturatePassProps): JSX.Element {
  const [pass] = useState(() => new LotvDesaturate());

  useForwardProp(pass, "lightnessMultiplier", lightnessMultiplier);
  useForwardProp(pass, "saturationMultiplier", saturationMultiplier);
  useForwardProp(pass, "enabled", enabled);

  return <primitive object={pass} attach={attachPass} />;
}
