import { useEffect } from "react";

/**
 * Ensures that, whenever 'prop' changes, it is forwarded to the corresponding
 * property of the given object. Useful to overcome lack of typechecking in
 * R3F's <primitive> component.
 *
 * @param obj The object to which assign the property
 * @param key Key of the property to be assigned
 * @param prop Value to assign to the property
 */
export function useForwardProp<T extends {}, K extends keyof T>(
  obj: T | null,
  key: K,
  prop: T[K],
): void {
  useEffect(() => {
    if (obj !== null) {
      obj[key] = prop;
    }
  }, [obj, prop, key]);
}

/**
 * Same as the hook above, but the assignment is performed only of 'prop' is defined.
 *
 * @param obj The object to which assign the property
 * @param key Key of the property to be assigned
 * @param prop Value to assign to the property
 */
export function useForwardPropIfDefined<T extends {}, K extends keyof T>(
  obj: T | null,
  key: K,
  prop: T[K] | undefined,
): void {
  useEffect(() => {
    if (obj !== null && prop !== undefined) {
      obj[key] = prop;
    }
  }, [obj, prop, key]);
}
