import { ILabel } from "@faro-lotv/ielement-types";
import {
  BaseMutation,
  MutationTypes,
  createBaseMutation,
} from "./mutation-base";

/**
 * Sets the name of a label in the project.
 */
export interface MutationSetLabelName extends BaseMutation {
  type: MutationTypes.MutationSetLabelName;

  /** The id of the label to edit the name of. */
  labelId: ILabel["id"];

  /** The new name of the label. */
  name: ILabel["name"];
}

/**
 * @returns a mutation to set the name of a label in the project
 * @param labelId The id of the label to edit
 * @param name The new name of the label
 */
export function createMutationSetLabelName(
  labelId: MutationSetLabelName["labelId"],
  name: MutationSetLabelName["name"],
): MutationSetLabelName {
  return {
    ...createBaseMutation(MutationTypes.MutationSetLabelName, undefined),
    labelId,
    name,
  };
}
