// Import all used variants of open-sans
import "@fontsource/open-sans";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import {
  alpha,
  createTheme,
  PaletteColor,
  ThemeOptions,
} from "@mui/material/styles";

// Allowing to apply style overrides for MUI lab components
// See import type {} from '@mui/lab/themeAugmentation';
import type {} from "@mui/lab/themeAugmentation";
import { grey } from "@mui/material/colors";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { neutral } from "./colors";
import { INPUT_LIGHT_COLORS } from "./input/input-colors";
import { createOutlinedInputStyles } from "./input/input-styles";

/** All supported font weights by the theme font (open-sans) */
export enum FontWeights {
  Regular = 400,
  SemiBold = 600,
  Bold = 700,
}

/**
 * Also see if the design team has any specific naming for the colors once
 * the design is finalized
 *
 * The numbers in front of the name suggests the opacity percentage
 */
const faroBlue = "#01426A";
const cyan = "#00CCFF";
const driftBlue = "#0077B3";
const brightBlue = "#58BFFF";
const customBlue = "#3AABFB";
const customBlue10 = "#3AABFB1A";
const blue100 = "#DEE8FD";
const blue350 = "#6699FF";
const blue400 = "#4E86F3";
const blue500 = "#1F65F0";
const blue600 = "#0E4ECC";
const blue700 = "#0B3C9D";
const blue800 = "#082A6E";
const laserBlue = "#3454DB";
const darkBlueGrey80 = "#29303DCC";
const customGrey = "#F3F3F3";
const customGreyShade2 = "#A5A5A5";
const customGreyShade3 = "#6B6B6B";
const customGreyShade4 = "#C3C3C3";
const shadow = "#00000029";
const neutralGray = "#B3B3B3";
const whiteGray = "#F0F0F0";
const blackGray = "#252527";
const darkGrey = "#3A3C3E";
const darkGrey80 = "#3A3C3ECC";
const gray50 = "#F8F9FA";
const gray100 = "#F0F2F5";
const gray200 = "#E0E4EB";
const gray300 = "#C2C9D6";
const gray400 = "#A3AEC2";
const gray500 = "#8592AD";
const gray600 = "#667799";
const gray700 = "#525F7A";
const gray800 = "#363C4D";
const gray850 = "#363C4D";
const gray900 = "#29303D";
const gray950 = "#20252E";
const gray999 = "#15191F";
const bgColor = "#f9fbfc";
const lightBlue = "#E6F7FF";
const white = "#FFFFFF";
const white10 = "#FFFFFF1A";
const black = "#000000";
const black10 = "#0000001A";
const black40 = "#00000066";
const gridBackground = "#E6F5F0";
const gridLines = "#B4BFBB";
const gridBorder = "#B4BFBB";
const yellow100 = "#FFF8E5";
const yellow500 = "#FFBE19";
const yellow600 = "#E8A600";
const orange500 = "#FF731E";
const red100 = "#FFEBEB";
const red500 = "#FA2424";
const red600 = "#E50505";
const red700 = "#B30404";
const green100 = "#E1FAED";
const green500 = "#17DE6F";
const green700 = "#0D8040";
const magenta = "#e93dc8";
const userModuleBackground = "#212121";

/** CSS backdrop filter used for transparent elements in the 3D overlay */
const backdropFilter = "blur(4px) brightness(40%)";

/** The amount of transparency on disabled texts */
const DISABLED_TEXT_ALPHA = 0.4;

// Custom color names available in the faro theme
export interface IFaroThemePalette {
  customBlue: string;
  customBlue10: string;
  driftBlue: string;
  cyan: string;
  customGrey: string;
  customGreyShade2: string;
  customGreyShade3: string;
  shadow: string;
  darkGrey: string;
  darkGrey80: string;
  darkBlueGrey80: string;
  gray50: string;
  gray100: string;
  gray200: string;
  gray300: string;
  gray400: string;
  gray500: string;
  gray600: string;
  gray700: string;
  gray800: string;
  gray850: string;
  gray900: string;
  gray950: string;
  gray999: string;
  neutralGray: string;
  whiteGray: string;
  blackGray: string;
  bgColor: string;
  lightBlue: string;
  white: string;
  white10: string;
  black: string;
  black10: string;
  black40: string;
  gridBackground: string;
  gridLines: string;
  gridBorder: string;
  laserBlue: string;
  warning: PaletteColor;
  info: PaletteColor;
  success: PaletteColor;
  error: PaletteColor;
  warningYellow: string;
  yellow100: string;
  yellow500: string;
  yellow600: string;
  orange500: string;
  red100: string;
  red500: string;
  red600: string;
  red700: string;
  green100: string;
  green500: string;
  green700: string;
  blue100: string;
  blue350: string;
  blue400: string;
  blue500: string;
  blue600: string;
  blue700: string;
  blue800: string;
  magenta: string;
  userModuleBackground: string;
}

// Copy of IFaroThemePalette palette, but with optional properties. Needed for proper Typescript support.
export type IFaroThemePaletteOptions = Partial<IFaroThemePalette>;

/**
 * To declare custom properties in the theme,
 * extending the interface of material styles
 *
 * @see - https://mui.com/material-ui/customization/palette/#typescript-2
 */
declare module "@mui/material/styles" {
  /**
   * Disabling the EsLint as the Palette only needs to extend the
   * ICustomPalette and nothing else need to be defined in this interface
   */
  interface Palette extends IFaroThemePalette {}

  /** extending the type of the Palette by typing the custom properties added */
  interface PaletteOptions extends IFaroThemePaletteOptions {}
}

// Extending the variants allowed for Buttons
declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    info: true;
    gray: true;
    danger: true;
    darkGray: true;
    group: true;
  }
}
// Extending the variants allowed for Typography
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    info: true;
    danger: true;
  }
}

/**
 * Theme definition with the basic properties needed later to define component overrides
 *
 * Contains the palette, the spacing configuration, and the basic typography definition
 */
const BaseThemeOptions = {
  palette: {
    customBlue,
    customBlue10,
    cyan,
    driftBlue,
    customGrey,
    customGreyShade2,
    customGreyShade3,
    shadow,
    darkGrey,
    darkGrey80,
    darkBlueGrey80,
    gray50,
    gray100,
    gray200,
    gray300,
    gray400,
    gray500,
    gray600,
    gray700,
    gray800,
    gray850,
    gray900,
    gray950,
    gray999,
    neutralGray,
    whiteGray,
    blackGray,
    bgColor,
    lightBlue,
    white,
    white10,
    black,
    black10,
    black40,
    gridBackground,
    gridLines,
    gridBorder,
    laserBlue,
    yellow100,
    yellow500,
    yellow600,
    orange500,
    red100,
    red500,
    red600,
    red700,
    green100,
    green500,
    green700,
    blue100,
    blue350,
    blue400,
    blue500,
    blue600,
    blue700,
    blue800,
    magenta,
    userModuleBackground,
    primary: {
      main: blue500,
    },
    secondary: {
      main: faroBlue,
    },
    info: {
      main: "#1F65F0",
      light: "#EFF4FE",
      dark: blue600,
    },
    success: {
      main: "#0D8040",
      light: "#E8FCF3",
    },
    warning: {
      main: "#CC9200",
      light: "#FFF8E5",
    },
    error: {
      main: "#E51F04",
      light: "#FFEBEB",
      dark: red700,
    },
  },
  typography: {
    fontFamily: "Open Sans",
  },
  spacing: 8,
  transitions: {
    duration: {
      shorter: 200,
    },
  },
};

const BaseTheme = createTheme(BaseThemeOptions);

/**
 * Overrides of styles of MUI styles, adjusting them to our design
 */
const componentOverrides: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
        },

        code: {
          fontFamily:
            "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
        },

        "::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        // Define style only for chrome compatible browsers
        "@supports selector(::-webkit-scrollbar)": {
          "::-webkit-scrollbar-thumb": {
            backgroundColor: neutral[100],
            "&:hover": {
              backgroundColor: neutral[200],
            },
            "&:active": {
              backgroundColor: neutral[300],
            },
          },
          "::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
        },
        // Define style for non chrome browsers (if defined in chrome compatible browsers will break the expected style)
        "@supports not selector(::-webkit-scrollbar)": {
          "*": {
            scrollbarColor: `${neutral[100]} transparent`,
            scrollbarWidth: "thin",
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        root: {
          backdropFilter: "blur(5px)",
          fontFamily: "Open Sans",
        },
        paper: {
          padding: BaseTheme.spacing(2),
          borderRadius: "5px",
        },
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: FontWeights.SemiBold,
          fontSize: "22px",
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: `${BaseTheme.spacing(2)} ${BaseTheme.spacing(3)}`,
          gap: BaseTheme.spacing(3 / 2),
          "& > :not(:first-of-type)": {
            marginLeft: 0,
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: "unset",
          color: INPUT_LIGHT_COLORS.text,
          fontSize: "0.75rem",
          fontWeight: FontWeights.Bold,

          "&.Mui-focused": {
            color: INPUT_LIGHT_COLORS.text,
          },

          "&.Mui-disabled": {
            color: INPUT_LIGHT_COLORS.text,
          },

          "&.Mui-error": {
            color: INPUT_LIGHT_COLORS.error,
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: createOutlinedInputStyles(BaseTheme, INPUT_LIGHT_COLORS),
      },
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          padding: BaseTheme.spacing(3 / 2),
          fontSize: "0.875rem",

          "&.Mui-disabled": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: gray500,
            },
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: FontWeights.SemiBold,
        },
        containedPrimary: {
          "&.Mui-disabled": {
            color: alpha(white, DISABLED_TEXT_ALPHA),
            background: BaseTheme.palette.primary.main,
          },
        },

        textError: {
          color: red600,

          "&:hover ": {
            color: white,
            background: red600,
          },
        },
        text: {
          paddingLeft: BaseTheme.spacing(2),
          paddingRight: BaseTheme.spacing(2),
        },
      },

      variants: [
        {
          props: { variant: "info" },
          style: {
            backgroundColor: customBlue,
            color: white,
            textTransform: "none",
            py: 1,
            px: 2,
            "&:hover": {
              backgroundColor: driftBlue,
            },
            "&:disabled": {
              color: `${white}80`,
            },
          },
        },
        {
          props: { variant: "gray" },
          style: {
            backgroundColor: whiteGray,
            color: black,
            textTransform: "none",
            py: 1,
            px: 2,
            "&:hover": {
              backgroundColor: grey[400],
            },
          },
        },
        {
          props: { variant: "danger" },
          style: {
            backgroundColor: red600,
            color: white,
            textTransform: "none",
            py: 1,
            px: 2,
            "&:hover": {
              backgroundColor: red700,
            },
          },
        },
        {
          props: { variant: "darkGray" },
          style: {
            textTransform: "none",
            fontSize: "16px",

            backgroundColor: "rgba(0, 0, 0, 0)",
            backdropFilter,

            color: white,

            "&.Mui-selected": {
              backgroundColor: BaseTheme.palette.primary.main,
              color: white,
            },

            "&:hover, &.Mui-selected:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              color: white,
            },

            "&:disabled": {
              color: "rgba(255, 255, 255, 0.5)",
            },
          },
        },
        {
          props: { variant: "group" },
          style: {
            textTransform: "none",
            fontSize: "16px",

            backgroundColor: "transparent",
            backdropFilter,

            color: white,

            borderRadius: "6px",
            borderColor: white10,
            width: "46px",
            height: "46px",
            py: 1,
            px: 2,

            "&.Mui-selected": {
              backgroundColor: BaseTheme.palette.primary.main,
              color: white,
            },

            "&:hover, &.Mui-selected:hover": {
              backgroundColor: black40,
              color: white,
            },

            "&:disabled": {
              color: white,
              opacity: 0.5,
            },
          },
        },
      ],
    },

    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "16px",
          backgroundColor: neutral[999],
          "&:hover": {
            backgroundColor: `color-mix(in srgb, ${neutral[999]}, ${neutral[500]} 25%)`,
          },

          backdropFilter,

          color: white,

          borderRadius: "6px",
          borderColor: "rgba(255, 255, 255, 0.1)",

          py: 1,
          px: 2,

          "&.Mui-selected": {
            backgroundColor: BaseTheme.palette.primary.main,
            color: white,
          },

          "&.Mui-selected:hover": {
            backgroundColor: `color-mix(in srgb, ${BaseTheme.palette.primary.main}, ${neutral[500]} 25%)`,
            color: white,
          },

          "&:disabled": {
            color: "rgba(255, 255, 255, 0.5)",
          },
        },
      },
    },

    MuiLinearProgress: {
      styleOverrides: {
        root: {
          background: customGreyShade4,
        },

        bar: {
          background: `repeating-linear-gradient(-70deg, ${brightBlue}, ${brightBlue}, 5px, ${BaseTheme.palette.primary.main} 5px, ${BaseTheme.palette.primary.main} 15px)`,
        },
      },
    },

    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: BaseTheme.palette.primary.main,
        },
      },
    },

    MuiBadge: {
      styleOverrides: {
        badge: {
          borderRadius: "50%",
          height: "12px",
          width: "12px",
          minWidth: "unset",
          padding: "0",
          border: "1.5px solid white",
          boxSizing: "content-box",
        },
      },
      variants: [
        {
          props: { color: "default" },
          style: {
            "& .MuiBadge-badge": {
              color: black,
              backgroundColor: white,
            },
          },
        },
        {
          props: { color: "warning" },
          style: {
            "& .MuiBadge-badge": {
              color: black,
              backgroundColor: yellow600,
            },
          },
        },
      ],
    },

    // TODO: This styling needs to be removed as FaroTooltip from flat ui contains the proper styling - https://faro01.atlassian.net/browse/SWEB-3367
    MuiTooltip: {
      defaultProps: {
        disableInteractive: true,
      },
      styleOverrides: {
        tooltip: {
          padding: "0.5em 1em 0.5em 1em",
          borderRadius: "0.25em",
          maxWidth: "20.75em",
          fontSize: "0.875em",
          backgroundColor: gray950,
          outline: `1px solid ${white}33`,
          filter: `drop-shadow(0px 1px 1px ${black}29)`,

          // Tooltips are dark-themed, so some colors need to be adjusted here
          ".MuiLink-root": {
            color: blue350,
          },
        },
      },
    },

    MuiSnackbar: {
      styleOverrides: {
        root: {
          border: `1px solid ${white10}`,
          borderRadius: "6px",
          backgroundColor: blackGray,
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: BaseTheme.palette.primary.main,
            fontWeight: 600,
          },
          textTransform: "none",
          textAlign: "left",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "& .MuiTabs-indicator": {
            backgroundColor: BaseTheme.palette.primary.main,
          },
        },
      },
    },
  },
};

/**
 * The theme object contains the default component styling used throughout the application.
 * Should be extended by individual apps and then passed to `createTheme`.
 */
export const FaroThemeOptions: ThemeOptions = {
  ...BaseThemeOptions,
  ...componentOverrides,
};

/**
 * @deprecated Return of `createTheme` is not compatible as argument for `createTheme`.
 *   Instead, extend `FaroThemeOptions` and then pass to `createTheme`.
 */
export const FaroTheme = createTheme(FaroThemeOptions);
