import { GUID } from "@faro-lotv/foundation";
import {
  IElementCamView,
  IElementType,
  IElementTypeHint,
  IPose,
  IRefCoordSystemTransform,
} from "@faro-lotv/ielement-types";
import { CreateIElement } from "../project-api-types";
import {
  BaseMutation,
  createBaseMutation,
  MutationTypes,
} from "./mutation-base";

export interface MutationAddCamView extends BaseMutation {
  /** New cam view element */
  newElement: CreateIElement<IElementCamView>;
}

export interface CreateMutationAddCamView {
  /** ID of the element to be created */
  id: GUID;

  /** Name of the element to be created */
  name: string;

  /**
   * The parent iElement to attach the CamView to. This parent has to match one of the following types
   * - MarkupPolygon
   * - MeasurePolygon
   * - Section with one of the following type hints: Room, TimeTravel
   */
  parentId: GUID;

  /** ID of the root element */
  rootId: GUID;

  /** Whether the required camera view is orthographic (true) or perspective (false) */
  orthographic: boolean;

  /** FOV in degrees for a perspective camera, ortho vertical size for ortho camera */
  zoomLevel: number;

  /** Pose of this camera view */
  pose?: IPose;

  /** The Reference coord system matrix of this camera view */
  refCoordSystemMatrix?: IRefCoordSystemTransform | null;

  /**
   * Date of creation of this node, in ISO string format
   *
   * @default now
   */
  createdAt?: string;
}

/**
 * Adds a camera view to an annotation or a measurement. This mutation results in this structure:
 * MarkupPolygon
 * └ CamView
 *
 * @returns A mutation to add a new CamView iElement to a markup
 */
export function createMutationAddCamView({
  id,
  name,
  parentId,
  rootId,
  orthographic,
  zoomLevel,
  pose,
  refCoordSystemMatrix,
  createdAt = new Date().toISOString(),
}: CreateMutationAddCamView): MutationAddCamView {
  const newElement: CreateIElement<IElementCamView> = {
    type: IElementType.camView,
    id,
    name,
    zoomLevel,
    parentId,
    rootId,
    createdAt,
    pose,
    refCoordSystemMatrix,
    typeHint: orthographic ? IElementTypeHint.orthographic : null,
  };

  return {
    ...createBaseMutation(MutationTypes.MutationAddCamView, parentId),
    newElement,
  };
}
