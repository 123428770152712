import { TypedEvent } from "@faro-lotv/foundation";
import { Camera, EventDispatcher, PerspectiveCamera, Vector2, Vector3, Vector4 } from "three";
import { SupportedCamera, memberWithPrivateData, pixels2radians } from "../Utils";
import { DEG_TO_RAD, RotAngles, cartesian2spherical, clamp, spherical2cartesian } from "../Utils/Math";
import { SyncableControls } from "./SyncableControls";
import { DoubleTouch, PointerCoords, TouchEvents } from "./TouchEvents";

enum SphereControlState {
	ViewStill = 0,
	UserDragging = 1,
	ViewMovingByInertia = 2,
}

/** Minimum field of view value to use with the SphereControls */
export const DEFAULT_MIN_PERSP_FOV = 1;

/** Maximum field of view value to use with the SphereControls */
export const DEFAULT_MAX_PERSP_FOV = 110;

/** Default field of view value to use with the SphereControls */
export const DEFAULT_PERSP_FOV = 90;

const Zup = 0;
const Yup = 1;
const maxAngle = 170;

const DEFAULT_INERTIA = 0.95;
const DEFAULT_EASING = 1.5;
const DEFAULT_ZOOM_FACTOR = 1.5;

const MIN_ROTATION_SPEED = 1e-4;

// Minimum duration of the zoom animation, in seconds
const MIN_ZOOM_DURATION = 0.25;

const MAX_PHI_DEG = 85;

// If zooming out then reduce the zoom by 5% of an ortho camera
const DEC_ZOOM_MULTIPLIER = 0.95;
// If zooming in then increase the zoom by 5% of an ortho camera
const INC_ZOOM_MULTIPLIER = 1.05;

/**
 * Parameters used for the computation of the camera rotation inertia.
 */
class InertiaParams {
	// yaw angle of last direction the camera was pointing to
	lastTheta = 0;
	// pitch angle of last direction the camera was pointing to
	lastPhi = 0;
	// rotation speed around the vertical in degrees/seconds
	thetaSpeed = 0;
	// rotation speed around the x axis in degrees/seconds.
	phiSpeed = 0;
	// The inertia coefficient that is multiplied to the current speed at each interactor update.
	inertia = DEFAULT_INERTIA;

	/** @returns whether the camera is rotating with a significant speed. */
	rotatingWithSpeed(): boolean {
		return Math.abs(this.thetaSpeed) > MIN_ROTATION_SPEED || Math.abs(this.phiSpeed) > MIN_ROTATION_SPEED;
	}

	storeRotationSpeed(deltaTime: number, theta: number, phi: number): void {
		if (deltaTime < MIN_ROTATION_SPEED) {
			this.thetaSpeed = this.phiSpeed = 0;
		} else {
			const invD = 1 / deltaTime;
			this.thetaSpeed = (theta - this.lastTheta) * invD;
			this.phiSpeed = (phi - this.lastPhi) * invD;
		}
	}

	decelerate(): void {
		this.thetaSpeed = Math.abs(this.thetaSpeed) > MIN_ROTATION_SPEED ? this.thetaSpeed * this.inertia : 0;
		this.phiSpeed = Math.abs(this.phiSpeed) > MIN_ROTATION_SPEED ? this.phiSpeed * this.inertia : 0;
	}
}

/** What the mouse wheel should do */
export enum ZoomBehavior {
	/** Should it zoom on the detail under the mouse cursor, */
	ZoomOnMouseCursor = 0,
	/** or should it center the view on the detail initially under the mouse cursor? */
	CenterViewOnMouseCursor = 1,
}

/**
 * Parameters for the zoom animation
 */
class ZoomParams {
	// Time since last zoom event, in seconds
	timeSinceZoomed = 1000;
	// zoom sign. 1 stands for zoom out, -1 for zoom in.
	zoomSign = 1;
	// Factor used to convert the current fov to an FOV zoom speed in degrees / seconds
	zoomSpeedFactor = DEFAULT_ZOOM_FACTOR;
	// zoom behavior
	behavior = ZoomBehavior.CenterViewOnMouseCursor;
	// easing factor, implements the behavior above
	easing = DEFAULT_EASING;
}

/**
 * A class to control a camera dragging a sphere from the inside.
 *
 * This interactor is useful e.g. to visualize datasets of panorama images, or to provide mouse/touch navigation in
 * other kinds of 'bubble' views where camera translation is not allowed.
 *
 * Behaviors:
 *     Mouse button drag: Rotates the view, always maintaining the camera up direction
 *     Mouse wheel spin:  Zooms the view in/out.
 *     One-touch drag:    Rotates the view.
 *     Two-touch drag:    Zooms the view interpreting the gesture as pinch-and-zoom.
 *     Keys:              Not supported.
 *
 * Also, this interactor accounts for rotation inertia. Inertia is present only in mouse interaction.
 * Touchscreen interaction goes without inertia. Inertia can be turned off by calling control.inertia = 0;
 */
export class SphereControls extends EventDispatcher implements SyncableControls {
	// camera yaw angle (rotation around vertical axis) in radians
	#theta = 0;
	// camera pitch angle (rotation around X axis) in radians
	#phi = 0;
	// How the user is currently interacting
	#interactionState = SphereControlState.ViewStill;
	// The camera being controlled
	#camera: SupportedCamera;
	// constant used to convert pointer movements in pixel intervals to rotations in radians intervals.
	#pixels2radians = 0.1;
	// Touch events tracking, needed for pinch-and-zoom
	#touchEvents = new TouchEvents();
	// Minimum angle that the perspective camera FOV can assume while zooming, in degrees.
	#minPerspFov = DEFAULT_MIN_PERSP_FOV;
	// Maximum angle that the perspective camera FOV can assume while zooming, in degrees.
	#maxPerspFov = DEFAULT_MAX_PERSP_FOV;
	// Up direction (either Z or Y up are supported)
	#upDirection = Yup;
	// Rotation inertia parameters
	#inertiaParams = new InertiaParams();
	// Zoom animation parameters
	#zoom = new ZoomParams();
	/** Event fired when the user interacts with the control */
	userInteracted = new TypedEvent<void>();
	/** Event fired when the user reaches the maximum FOV angle */
	maxPerspFovReached = new TypedEvent<void>();
	/** Current viewport of the view this controls is managing */
	viewport?: Vector4;

	/**
	 * Construct the control class
	 *
	 * @param camera The camera to control
	 * @param target The element to listen for events
	 */
	constructor(camera: SupportedCamera, target?: HTMLElement) {
		super();
		this.#camera = camera;
		if (this.#camera.up.z === 1) {
			this.#upDirection = Zup;
		} else if (this.#camera.up.y === 1) {
			this.#upDirection = Yup;
		} else {
			console.warn("SphereControls: camera up direction not supported.");
		}

		this.updateFromCamera();

		this.onTouchStart = this.onTouchStart.bind(this);
		this.onTouchEnd = this.onTouchEnd.bind(this);
		this.onSingleTouchMove = this.onSingleTouchMove.bind(this);
		this.onPinchAndZoom = this.onPinchAndZoom.bind(this);
		this.onMouseDown = this.onMouseDown.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
		this.onMouseDrag = this.onMouseDrag.bind(this);
		this.onWheel = this.onWheel.bind(this);

		this.#touchEvents.touchStarted.on(this.onTouchStart);
		this.#touchEvents.touchEnded.on(this.onTouchEnd);
		this.#touchEvents.singleTouchMoved.on(this.onSingleTouchMove);
		this.#touchEvents.pinchAndZoomed.on(this.onPinchAndZoom);
		this.#touchEvents.mousePressed.on(this.onMouseDown);
		this.#touchEvents.mouseReleased.on(this.onMouseUp);
		this.#touchEvents.mouseDragged.on(this.onMouseDrag);
		this.#touchEvents.mouseWheel.on(this.onWheel);

		if (target) {
			this.attach(target);
		}
	}

	/**
	 * Dispose all resources and event connections
	 */
	dispose(): void {
		this.detach();
		this.#touchEvents.touchStarted.off(this.onTouchStart);
		this.#touchEvents.touchEnded.off(this.onTouchEnd);
		this.#touchEvents.singleTouchMoved.off(this.onSingleTouchMove);
		this.#touchEvents.pinchAndZoomed.off(this.onPinchAndZoom);
		this.#touchEvents.mousePressed.off(this.onMouseDown);
		this.#touchEvents.mouseReleased.off(this.onMouseUp);
		this.#touchEvents.mouseDragged.off(this.onMouseDrag);
		this.#touchEvents.mouseWheel.off(this.onWheel);
		this.#touchEvents.dispose();
	}

	/** Enable/Disable this control class */
	set enabled(e: boolean) {
		this.#touchEvents.enabled = e;
	}

	/** @returns true if the controls are enabled */
	get enabled(): boolean {
		return this.#touchEvents.enabled;
	}

	/**
	 * Map a point in canvas coordinates to the viewport
	 *
	 * @param canvasPosition The canvas point
	 * @param result optional result vector, to avoid allocations
	 * @returns The viewport point
	 */
	mapToViewport(canvasPosition: Vector2, result = new Vector2()): Vector2 {
		if (!this.viewport) {
			return result.copy(canvasPosition);
		}
		const x = canvasPosition.x - this.viewport.x;
		const y = canvasPosition.y - this.viewport.y;
		result.set(x, y);
		return result;
	}

	/**
	 * Attach this controls to an element to receive events from
	 *
	 * @param target The element to listen for events
	 */
	attach(target: HTMLElement): void {
		this.#touchEvents.attach(target);
	}

	/**
	 * If attached to an element, detach all event linsteners
	 */
	detach(): void {
		this.#touchEvents.detach();
	}

	/**
	 * When the user stops rotating the view, in this function we determine whether
	 * switching to the view still state or whether the camera was rotating so fast
	 * that it is desirable to let it slowly decelerate by inertia.
	 */
	private maybeStartInertialMovement(): void {
		// maybe switch to an inertia state here?
		this.#interactionState = this.#inertiaParams.rotatingWithSpeed()
			? SphereControlState.ViewMovingByInertia
			: SphereControlState.ViewStill;
	}

	/**
	 *
	 */
	private onTouchEnd(): void {
		if (this.#touchEvents.pointersCount === 0) {
			this.maybeStartInertialMovement();
		}
	}

	/**
	 *
	 */
	private onTouchStart(): void {
		this.updateFromCamera();
		this.userInteracted.emit();
		this.#interactionState = SphereControlState.UserDragging;
		this.storeLastRotation();
	}

	/**
	 * Clamps the camera latitude angle in one single place to get an uniquely defined angle interval.
	 */
	private clampLatitude(): void {
		this.#phi = clamp(this.#phi, -MAX_PHI_DEG * DEG_TO_RAD, MAX_PHI_DEG * DEG_TO_RAD);
	}

	/**
	 *
	 * @param camera The perspective camera used by this control
	 * @param fov Perspective FOV
	 */
	private assignClampedPerspFov(camera: PerspectiveCamera, fov: number): void {
		if (fov > this.#maxPerspFov) {
			this.maxPerspFovReached.emit();
		}
		const cfov = clamp(fov, this.#minPerspFov, this.#maxPerspFov);
		camera.fov = cfov;
	}

	/**
	 * This is needed to make sure that the rotation speed that the user experiences is independent from
	 * camera parameters and from screen resolution.
	 */
	private updatePixels2radians(): void {
		const height = this.viewport?.getComponent(3) ?? this.#touchEvents.elementHeight;
		this.#pixels2radians = pixels2radians(this.#camera, height);
	}

	/**
	 *
	 */
	private onMouseDown(): void {
		this.updateFromCamera();
		this.#interactionState = SphereControlState.UserDragging;
		this.userInteracted.emit();
		this.storeLastRotation();
	}

	/**
	 *
	 * @param pp New touch coords and speed
	 */
	private onSingleTouchMove(pp: PointerCoords): void {
		this.userInteracted.emit();
		this.updatePixels2radians();
		const m = pp.getMovement(pp.deltaTime);
		this.#theta += m.x * this.#pixels2radians;
		this.#phi += m.y * this.#pixels2radians;
		this.clampLatitude();
	}

	/**
	 * This function moves the camera in a way that the zoom is centered on the
	 * current mouse cursor (or on the center of the two touches of a pinch-and-zoom gesture.)
	 *
	 * @param anglesBefore Direction hovered by the mouse cursor before the zoom
	 * @param anglesAfter Direction hovered by the mouse cursor after the zoom
	 * @param easing How strong to apply the movement on the mouse cursor.
	 */
	private zoomOnPOI(anglesBefore: RotAngles, anglesAfter: RotAngles, easing: number): void {
		let thetaDiff = anglesAfter.theta - anglesBefore.theta;
		// Purpose of the if below is to compensate for bounds errors of the theta angle.
		// It can happen for example that thetaBefore = -PI + smallAngle and thetaAfter = PI - smallAngle
		if (Math.abs(thetaDiff) > Math.PI) {
			thetaDiff = anglesAfter.theta > anglesBefore.theta ? thetaDiff - 2 * Math.PI : thetaDiff + 2 * Math.PI;
		}
		this.#theta -= thetaDiff * easing;
		this.#phi -= (anglesAfter.phi - anglesBefore.phi) * easing;
		this.clampLatitude();
		this.updateCamera();
	}

	/**
	 *
	 * @param dp Double touch data with position and speed for each touch
	 */
	private onPinchAndZoom(dp: DoubleTouch): void {
		this.userInteracted.emit();
		const pix = new Vector2().addVectors(dp.mainTouch.position, dp.secondTouch.position).multiplyScalar(0.5);
		const anglesBefore = cartesian2spherical(this.screenCoordsToWorldRay(pix.x, pix.y), this.#upDirection === Yup);
		const { ratio } = dp;
		if (this.#camera instanceof PerspectiveCamera) {
			const fov = this.#camera.fov * ratio;
			this.assignClampedPerspFov(this.#camera, fov);
			this.#camera.updateProjectionMatrix();
		} else {
			this.#camera.zoom *= ratio;
			this.#camera.updateProjectionMatrix();
		}
		const anglesAfter = cartesian2spherical(this.screenCoordsToWorldRay(pix.x, pix.y), this.#upDirection === Yup);
		this.zoomOnPOI(anglesBefore, anglesAfter, 1);
	}

	/**
	 *
	 */
	private onMouseDrag(): void {
		const pp = this.#touchEvents.mouseCoords;
		this.onSingleTouchMove(pp);
	}

	/**
	 *
	 */
	private onMouseUp(): void {
		this.maybeStartInertialMovement();
	}

	/**
	 *
	 * @param x X coordinate in screen pixels
	 * @param y Y coordinate in screen pixels
	 * @returns the world direction corresponding to the input screen coordinates, given the current camera pose and projection.
	 */
	private screenCoordsToWorldRay = memberWithPrivateData(() => {
		const clipCoords = new Vector3();
		const cameraWorldPos = new Vector3();

		return (x: number, y: number): Vector3 => {
			const width = this.viewport?.getComponent(2) ?? this.#touchEvents.elementWidth;
			const height = this.viewport?.getComponent(3) ?? this.#touchEvents.elementHeight;
			cameraWorldPos.setFromMatrixPosition(this.#camera.matrixWorld);
			clipCoords.set((x / width) * 2 - 1, 1 - (y / height) * 2, 0.5);
			return clipCoords.unproject(this.#camera).sub(cameraWorldPos).normalize();
		};
	});

	/**
	 * At each frame, computes the zoom animation.
	 *
	 * @param deltaTime Time elapsed since last rendering
	 */
	private computeZoom = memberWithPrivateData(() => {
		const m = new Vector2();

		return (deltaTime: number): void => {
			this.#zoom.timeSinceZoomed += deltaTime;
			if (this.#zoom.timeSinceZoomed > MIN_ZOOM_DURATION) return;
			this.mapToViewport(this.touchEvents.mouseCoords.position, m);
			const anglesBefore = cartesian2spherical(this.screenCoordsToWorldRay(m.x, m.y), this.#upDirection === Yup);
			if (this.#camera instanceof PerspectiveCamera) {
				const speed = this.#camera.fov * this.#zoom.zoomSpeedFactor;
				const fov = this.#camera.fov + this.#zoom.zoomSign * speed * deltaTime;
				this.assignClampedPerspFov(this.#camera, fov);
			} else {
				this.#camera.zoom *= this.#zoom.zoomSign > 0 ? DEC_ZOOM_MULTIPLIER : INC_ZOOM_MULTIPLIER;
			}
			this.#camera.updateProjectionMatrix();
			const anglesAfter = cartesian2spherical(this.screenCoordsToWorldRay(m.x, m.y), this.#upDirection === Yup);
			this.zoomOnPOI(anglesBefore, anglesAfter, this.#zoom.easing);
		};
	});

	/**
	 * @param ev the wheel event
	 */
	private onWheel(ev: WheelEvent): void {
		ev.preventDefault();
		this.userInteracted.emit();
		this.#zoom.timeSinceZoomed = 0;
		this.#zoom.zoomSign = Math.sign(ev.deltaY);
	}

	/**
	 * Update the camera rotation based on the longitude and latitude.
	 * The longitude and latitude values are updated every time the mouse moves.
	 */
	private updateCamera = memberWithPrivateData(() => {
		const target = new Vector3();

		return (): void => {
			target.copy(this.#camera.position.clone());
			const dir = spherical2cartesian({ theta: this.#theta, phi: this.#phi }, this.#upDirection === Yup);
			target.add(dir);
			this.#camera.lookAt(target);
		};
	});

	/**
	 * Updates this control orient with the target camera.
	 *
	 * @param camera (Optional) the camera to use, defaults to the bound camera
	 */
	updateFromCamera(camera?: Camera): void {
		const { theta, phi } = this.thetaPhiFromCamera(camera);

		// This check is required in the case of nadir direction of the camera that causes a cosTheta==0
		if (!Number.isNaN(theta)) this.#theta = theta;
		if (!Number.isNaN(phi)) this.#phi = phi;
	}

	/**
	 *
	 * @param camera (Optional) the camera to use, defaults to the bound camera	 *
	 * @returns The current camera direction converted in yaw and pitch angles (radians)
	 */
	private thetaPhiFromCamera = memberWithPrivateData(() => {
		const dir = new Vector3();

		return (camera?: Camera): RotAngles => {
			if (!camera) camera = this.#camera;
			return cartesian2spherical(camera.getWorldDirection(dir), this.#upDirection === Yup);
		};
	});

	/** Store longitudinal angle of last direction the camera was pointing to */
	private storeLastRotation(): void {
		this.#inertiaParams.lastTheta = this.#theta;
		this.#inertiaParams.lastPhi = this.#phi;
	}

	/**
	 * Updates the interactor accounting for rotation inertia.
	 *
	 * @param deltaTime Elapsed time since last update.
	 */
	public update(deltaTime: number): void {
		this.computeZoom(deltaTime);
		switch (this.#interactionState) {
			case SphereControlState.ViewStill:
				break;
			case SphereControlState.UserDragging: {
				this.#inertiaParams.storeRotationSpeed(deltaTime, this.#theta, this.#phi);
				this.storeLastRotation();
				this.updateCamera();
				break;
			}
			case SphereControlState.ViewMovingByInertia: {
				// finding out new speed
				this.#inertiaParams.decelerate();
				if (this.#inertiaParams.thetaSpeed === 0 && this.#inertiaParams.phiSpeed === 0) {
					this.#interactionState = SphereControlState.ViewStill;
				} else {
					this.#theta += this.#inertiaParams.thetaSpeed * deltaTime;
					this.#phi += this.#inertiaParams.phiSpeed * deltaTime;
					this.clampLatitude();
					this.updateCamera();
				}
				break;
			}
		}
	}

	/** @returns the deceleration that the rotation has by inertia, expressed as a multiplier for the speed. */
	get inertia(): number {
		return this.#inertiaParams.inertia;
	}

	/**
	 * Sets he deceleration that the rotation has by inertia, expressed as a multiplier for the speed applied at each interactor update.
	 * If it is set to zero, the rotation has no inertia.
	 */
	set inertia(i: number) {
		if (i >= 0 && i < 1) {
			this.#inertiaParams.inertia = i;
		} else {
			console.log("Inertia deceleration value must be greater or equal to zero and strictly smaller than 1.");
		}
	}

	/**
	 * @returns the handler of the touch events. Useful if another class wants to be
	 * notified e.g. when a tap gesture happens.
	 */
	get touchEvents(): TouchEvents {
		return this.#touchEvents;
	}

	/** @returns the minimum angle that the perspective camera FOV can assume while zooming, in degrees. */
	get minPerspFov(): number {
		return this.#minPerspFov;
	}

	/** Sets the minimum angle that the perspective camera FOV can assume while zooming, in degrees. */
	set minPerspFov(n: number) {
		if (n < 1 || n >= this.#maxPerspFov) {
			console.warn(`Nin FOV angle should lie in the interval 1 - ${this.#maxPerspFov}`);
		} else {
			this.#minPerspFov = n;
		}
	}

	/** @returns the maximum angle that the perspective camera FOV can assume while zooming, in degrees. */
	get maxPerspFov(): number {
		return this.#maxPerspFov;
	}

	/** Sets the maximum angle that the perspective camera FOV can assume while zooming, in degrees. */
	set maxPerspFov(n: number) {
		if (n <= this.#minPerspFov || n >= maxAngle) {
			console.warn(`Max FOV angle should lie in the interval ${this.#minPerspFov} - ${maxAngle}`);
		} else {
			this.#maxPerspFov = n;
		}
	}

	/** @returns the factor used to compute the speed of the perspective zoom animation from the current fov */
	get zoomSpeedFactor(): number {
		return this.#zoom.zoomSpeedFactor;
	}

	/** Sets the speed of the perspective zoom animation, in degrees / second */
	set zoomSpeedFactor(s: number) {
		if (s < 1 || s > 1000) {
			console.warn("Zoom speed factor should lie in the interval [1, 1000].");
		} else {
			this.#zoom.zoomSpeedFactor = s;
		}
	}

	/** @returns The behavior of the zoom action */
	get zoomBehavior(): ZoomBehavior {
		return this.#zoom.behavior;
	}

	/** Sets the behavior of the zoom action */
	set zoomBehavior(b: ZoomBehavior) {
		this.#zoom.behavior = b;
		this.#zoom.easing = b === ZoomBehavior.CenterViewOnMouseCursor ? DEFAULT_EASING : 1;
	}

	/** @returns The camera managed by these controls */
	get camera(): SupportedCamera {
		return this.#camera;
	}

	/** Sets the camera managed by these controls */
	set camera(c: SupportedCamera) {
		this.#camera = c;
		this.updateFromCamera();
	}
}
