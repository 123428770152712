/**
 * Represents the ids of supported external providers.
 */
export enum WorkspaceIntegrationProviderId {
  autodesk = "autodesk",
  procore = "procore",
}

/**
 * Gets the Workspace Integration Provider Id based on the provided string.
 *
 * @param providerId - The id of the provider as a string.
 * @returns The corresponding WorkspaceIntegrationProviderId or undefined if not found.
 */
export function getWorkspaceIntegrationProviderId(
  providerId: string,
): WorkspaceIntegrationProviderId | undefined {
  switch (providerId) {
    case WorkspaceIntegrationProviderId.autodesk:
      return WorkspaceIntegrationProviderId.autodesk;
    case WorkspaceIntegrationProviderId.procore:
      return WorkspaceIntegrationProviderId.procore;
    default:
      return undefined;
  }
}

/** Supported units of measure in the HoloBuilder project settings. */
export type SupportedUnitsOfMeasure = "us" | "metric";

export type ProjectSettings = {
  /**
   *  This type depends on the units of measure defined by the backend.
   *  This is not used for storing the data but only for rendering in the UI.
   */
  unitSystem?: SupportedUnitsOfMeasure;
};

/** Markup settings parsed out of the HoloBuilder "slide container" (=project contents) */
export type SlideContainerMarkupSettings = {
  /** Whether markups should be visible for Project Viewers. Otherwise they should only be shown for Editors (or higher) */
  displayForViewers?: boolean;
};

type BcfProjectIdData = {
  /** The project id formatted as expected by the BCF Service backend*/
  bcfProjectId: string;
};

/**
 * The integrations enabled in the project
 * NOTE: The fields "autodesk" and "procore" inside the ProjectIntegrations is now obsolete and should no longer be used.
 */
export type ProjectIntegrations = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "autodesk-bim360.issues"?: BcfProjectIdData | null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "autodesk-bim360.rfis"?: BcfProjectIdData | null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "autodesk-acc.issues"?: BcfProjectIdData | null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "autodesk-acc.rfis"?: BcfProjectIdData | null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "procore.observations"?: BcfProjectIdData | null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "procore.rfis"?: BcfProjectIdData | null;
};
