precision highp float;

#if COLOR_TEXTURES
layout (location = 3) in int drawID;
#else
layout (location = 2) in int drawID;
#endif

uniform sampler2D poseTexture;
uniform bool clippingInLocalTransform;

out vec3 fragPosition;
out vec3 fragNormal;

// On Intel GPUs, GLSL shaders are translated to directX's HLSL 
// by a non-optimal driver, to say the least. Passing 'flat int' 
// variables between shaders is allowed, it compiles, but the 
// performances are completely unacceptable. Hence we pass a simple float.
out float fragDrawID;

#if COLOR_TEXTURES
out vec2 fTexCoord;
#endif

#include <clipping_planes_pars_vertex>
#include <common>

// To avoid Z-fighting, an unique depth offset is added to each CAD part. This depth offset
// is chosen as a multiple of 3 * 10-5 in logarithmic depth space, and it is picked in a 
// pseudorandom sequence of 20 different multiples.
const float k_MinDepthIncrement = 0.00003;
const int[20] k_Offsets = int[20](18, 5, 1, 8, 6, 7, 2, 17, 11, 14, 12, 3, 10, 4, 19, 13, 20, 16, 9, 15);
// A different offset is used in ortho projection, in meters
const float k_OrthoOffset = 0.001;

void main() 
{
    ivec2 tsize = textureSize(poseTexture, 0);
    int i = drawID * 4;
    int row = i / tsize.x;
    int col = i - row * tsize.x;
    vec4 X = texelFetch(poseTexture, ivec2(col, row), 0);
    vec4 Y = texelFetch(poseTexture, ivec2(col + 1, row), 0);
    vec4 Z = texelFetch(poseTexture, ivec2(col + 2, row), 0);
    vec4 T = texelFetch(poseTexture, ivec2(col + 3, row), 0);

    if(T.w == 0.0) {
        // If we enter here, the CAD part is not visible.
        // Discard statement is not available in webgl vertex shaders,
        // hence the vertex is moved behind the camera where will be
        // culled out by the GPU.
        gl_Position = vec4(0.0, 0.0, 10.0, 1.0);
        return;
    }
    
    mat4 localMatrix = mat4(X, Y, Z, T);
    mat4 MV = modelViewMatrix * localMatrix;

    bool perspective = isPerspectiveMatrix(projectionMatrix);

    vec4 mvPosition = MV * vec4(position, 1.0);
    if(!perspective) {
        // In ortho projection, CAD parts are offsetted along the camera direction in multiples of one millimeter
        // to avoid Z-fighting
        mvPosition.z += k_OrthoOffset * float(k_Offsets[drawID % 20]);
    }
    fragPosition = mvPosition.xyz;
    gl_Position = projectionMatrix * vec4(fragPosition, 1.0);
    fragNormal = vec3(MV * vec4(normal, 0.0));
    fragDrawID = float(drawID);
#if COLOR_TEXTURES
    fTexCoord = uv;
#endif

    if(perspective) {
        // To avoid Z-fighting, in perspective projection CAD parts are offsetted in depth
        // by a small amount defined in logarithmic space.
        gl_Position.z += k_MinDepthIncrement * float(k_Offsets[drawID % 20]);
    }
    
#if NUM_CLIPPING_PLANES > 0
    if (clippingInLocalTransform) {
        vClipPosition = - (viewMatrix * localMatrix * vec4(position, 1.0)).xyz;
    } else {
        vClipPosition = - mvPosition.xyz;
    }
#endif
}

