import {
  PropsWithChildren,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useExternalScript } from "../hooks/use-external-script";
import { HolobuilderCookieManager } from "./cookie-manager-types";

// Extending the window global to contain the HolobuilderCookieManager object
declare global {
  interface Window {
    HolobuilderCookieManager?: HolobuilderCookieManager;
  }
}

type CookieManagerContext = {
  /** True if the script is finished loading */
  isManagerLoaded: boolean;

  /** True if the cookie-manager has been initialized (i.e., all functionality is available) */
  isInitialized: boolean;

  /** True if the cookie-manager is used (i.e., a script URL has been provided) */
  isManagerUsed: boolean;
};

export const HBCookieManagerContext = createContext<
  CookieManagerContext | undefined
>(undefined);

export type CookieManagerOptions = {
  /** If true, the cookie state is pushed to the GTM dataLayer. Default false */
  triggerGoogleTagManager?: boolean;

  /** Disable when the babel-polyfill is already loaded before the cookie-manager. Default true */
  requireBabelPolyfill?: boolean;

  /** Disable when Bootstrap is already loaded before the cookie-manager. Default true */
  requireBootstrap?: boolean;

  /** Disable when Jquery is already loaded before the cookie-manager. Default true */
  requireJquery?: boolean;

  /** Delays the intialization of the cookie-manager. Default 0 */
  delay?: number;

  /** Whether the cookie manager should open automatically if cookies have not been accepted yet. Default true */
  shouldOpenAutomatically?: boolean;

  /** Whether to show a preferences button. Default false */
  preferencesButton?: boolean;

  /** Show preference button only if not all cookies are accepted. Default false */
  preferencesButtonOnNotComplete?: boolean;

  /* Position of the preferences button. Default "bottom right" */
  preferencesButtonPosition?: string;

  /** Use to overwrite the automatic language detection */
  forcedBrowserData?: { country: string; language: string };
};

export type HBCookieManagerProviderProps = {
  /** URL of the main cookie-manager script */
  cookieManagerUrl?: string;

  /** Optional options to initialize the cookie manager */
  options?: CookieManagerOptions;
};

/**
 * @returns The context that provides access to the HB cookie-manager.
 *  Will load the provided script URL and then call the init function of the cookie-manager.
 *  Note: The init call will show the UI of the cookie-manager if the user didn't interact with it yet.
 */
export function HBCookieManagerProvider({
  children,
  cookieManagerUrl,
  options = {},
}: PropsWithChildren<HBCookieManagerProviderProps>): JSX.Element | null {
  // Load the main cookie manager script
  const { isDoneLoading } = useExternalScript(cookieManagerUrl);
  const [isInitialized, setIsInitialized] = useState(false);

  // Initialize the cookie-manager once the script has been loaded
  useEffect(() => {
    if (isDoneLoading && window.HolobuilderCookieManager && !isInitialized) {
      window.HolobuilderCookieManager.init(options);
      setIsInitialized(true);
    }
  }, [isDoneLoading, options, isInitialized]);

  const value = useMemo<CookieManagerContext>(
    () => ({
      isManagerLoaded: isDoneLoading,
      isInitialized,
      isManagerUsed: !!cookieManagerUrl,
    }),
    [isDoneLoading, isInitialized, cookieManagerUrl],
  );

  return (
    <HBCookieManagerContext.Provider value={value}>
      {children}
    </HBCookieManagerContext.Provider>
  );
}
