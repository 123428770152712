import {
  Optional,
  RetryOptions,
  isGuardsLoggingEnabled,
  log,
  validateArrayOf,
  validateNotNullishObject,
} from "@faro-lotv/foundation";
import {
  GUID,
  IElement,
  IElementProjectRoot,
  IElementType,
  IElementTypeHint,
  isIElementProjectRoot,
  validateIElement,
} from "@faro-lotv/ielement-types";
import { chunk } from "es-toolkit";
import {
  ApiResponseError,
  SendAuthenticatedJsonRequestParams,
  Token,
  TokenProvider,
  sendAuthenticatedJsonRequest,
} from "../authentication";
import {
  CaptureApiClient,
  CaptureTreeEdgeRevision,
  CaptureTreeEntity,
  CaptureTreeEntityRevision,
  CaptureTreeEntityType,
  CreateClusterEntitiesParams,
  CreateOrUpdateRegistrationEdgesParams,
  CreateRootEntityParams,
  CreateScanEntitiesParams,
  RegistrationEdge,
  RegistrationRevision,
  UpdateRegistrationRevisionParams,
  isCaptureTreeEdgeRevision,
  isCaptureTreeEntity,
  isCaptureTreeEntityRevision,
  isRegistrationEdge,
  isRegistrationEdgeRevision,
  isRegistrationRevision,
} from "./capture-tree-types";
import { Mutation } from "./mutations";
import { ProjectApiError } from "./project-api-errors";
import {
  CloudAlignmentResponse,
  CloudToBimAlignment,
  DataSetAreaInfo,
  FloorPlanGenerationTokenMathRequest,
  GetIElementsByVolumeParams,
  IElementInVolume,
  MutationResult,
  PaginatedAreaVolumeResponse,
  ProjectLabels,
  ProjectStatus,
  ScanProcessingTokenMathRequest,
  SignUrlsParams,
  SignUrlsResponse,
  SlicePanoImageResponse,
  StorageTokenMathRequest,
  TaskResult,
  isCloudAlignmentResponse,
  isIElementInVolume,
  isPaginatedAreaVolumeResponse,
  isProjectLabels,
  isProjectStatus,
  isSignUrlsResponse,
  isSlicePanoImageResponse,
  isTaskResult,
  isTokenPriceResponse,
} from "./project-api-types";
import {
  SphereSuccess,
  isSphereAPIValidationError,
  isSphereAPIerror,
} from "./sphere-api/sphere-api-responses";

/**
 * Docs of the Project API:
 * * https://v2.project.api.staging.holobuilder.com/swagger/index.html
 * * https://projectapi.api.eu.dev.farosphere.com/swagger/index.html
 */

const AREA_VOLUME_PAGE_SIZE = 100;
const CAPTURE_TREE_PAGE_SIZE = 100;

/** The maximum number of items which can be created/updated at once in the capture tree. */
const CAPTURE_TREE_CHUNK_SIZE = 100;

/** The maximum number of parallel requests when fetching many IElements at once. */
const PARALLEL_IELEMENT_REQUESTS = 20;

/**
 * The type hints that should be migrated to match the libraries' type definitions
 * see https://v2.project.api.dev.holobuilder.com/swagger/index.html#section/Upcoming-Breaking-Changes/TypeHint-Migration
 */
const MIGRATE_TYPE_HINTS_VALUE = `${IElementTypeHint.area},${IElementTypeHint.dataSession},${IElementTypeHint.dataSetPCloudUpload}`;

/** A hint for the expected size (i.e., number of items that match the query) of the response to optimize queries. */
export enum ResponseSize {
  /**
   * Only a few items are expected to match the query.
   *
   * Most responses are expected to fit in a single response page.
   */
  small = "small",
  /**
   * A large number of items are expected to match the query.
   *
   * To get all items, many pages might need to be fetched.
   */
  large = "large",
}

export interface GetIElementsParams {
  /** A signal to abort this request */
  signal?: AbortSignal;

  /**
   * Number of items to request per page from the API
   * If not provided, the API will decide the size of the page
   */
  itemsPerPage?: number;

  /**
   * If defined only elements of this type will be returned
   *
   * @deprecated Use `types` instead.
   */
  type?: IElementType;

  /** When provided, only returns elements where the type matches one of the given types */
  types?: IElementType[];

  /**
   * If defined only elements with the provided typeHints will be returned
   *
   * @deprecated Use `typeHints` instead.
   */
  typeHint?: IElementTypeHint[];

  /** If defined only elements with the provided typeHints will be returned */
  typeHints?: IElementTypeHint[];

  /** If defined only elements changed after this date will be returned */
  changedAfter?: Date;

  /** List of nodes to fetch */
  ids?: GUID[];

  /** List of nodes we want to fetch all the children of */
  ancestorIds?: GUID[];

  /** List of nodes we want to fetch the ancestors of  */
  descendantIds?: GUID[];

  /**
   * A hint for the expected size of the response to optimize the query.
   *
   * @default ExpectedResponseSize.large
   */
  expectedResponseSize?: ResponseSize;

  /** Callback which is called after the `getIndex` route was called */
  onIndexFetched?(): void;

  /**
   * Callback which is called after a page was fetched with the progress of fetched pages in percent
   * and the current page result
   *
   * @param progress The progress of the page fetching in percent (in the range [0, 100])
   *    If `expectedResponseSize` is `small`, this will be an approximation
   * @param onePageResult The result of the current page
   */
  onNextPageFetched?(
    progress: number,
    onePageResult: IElement[],
  ): Promise<void>;

  parentIds?: GUID[];
}

interface PagedRequest {
  /** A signal to abort this request */
  signal?: AbortSignal;

  /** Token to request a follow up page on a paged request */
  nextPageToken: string;
}

export interface PagedResponse<T> {
  /** Content of the current page */
  page: T[];

  /**
   * Token that can be used to request the next page
   * If not token is provided, there is no next page
   */
  token: string | null;
}

/**
 * @param itemTypeGuard The type guard to validate the items of the page with
 * @param itemTypeGuardName Name of `itemTypeGuard` for logging if invalid
 * @returns A validator to check if the response is a paged response. The validator logs invalid elements to console.error.
 */
function isPagedResponse<T>(
  itemTypeGuard: (data: unknown) => data is T,
  itemTypeGuardName: string,
) {
  return (data: unknown): data is PagedResponse<T> => {
    // Call validateNotNullishObject() only once to avoid duplicate logging.
    const dataPR = validateNotNullishObject(data, "PagedResponse")
      ? data
      : undefined;

    const valid =
      !!dataPR &&
      validateArrayOf({
        object: dataPR,
        prop: "page",
        elementGuard: itemTypeGuard,
      }) &&
      (typeof dataPR.token === "string" || dataPR.token === null);

    if (!valid && isGuardsLoggingEnabled()) {
      log(`isPagedResponse validation failed (${itemTypeGuardName}):`);
      if (Array.isArray(dataPR?.page)) {
        dataPR.page.forEach((item, i) => {
          if (!itemTypeGuard(item)) {
            log(`Invalid item at responseBody.page[${i}]:`, item);
          }
        });
      }
    }

    return valid;
  };
}

export interface PaginatedIndexResponse {
  /** Number of pages */
  pageCount: number;
  /** Total amount of items in all pages */
  itemCount: number;
  /** Array of all tokens */
  pageTokens: string[];
}

export interface MakeAuthorizedRequestParams {
  /** The request path, _without_ the base URL */
  requestUrl: string;

  /** Additional headers to the http request */
  additionalHeaders?: Record<string, string>;

  /** A signal to abort this fetch */
  signal?: AbortSignal;

  /** Payload to  */
  requestBody?: RequestInit["body"];

  /** Query parameters to attach to the request URL */
  queryParams?: Record<string, string | undefined>;

  /** HTTP method to use for the request (e.g. `"GET"` or `"POST"`) */
  httpMethod?: RequestInit["method"];

  /** Token to use to authenticate the request */
  token?: Token;
}

/**
 * Old params to construct a ProjectApi client using directly a Token and not a TokenProvider
 *
 * @deprecated Please use {@link ProjectApiConstructorParams}
 */
export type LegacyProjectApiConstructorParams = {
  /** ID of the project to work with */
  projectId: GUID;

  /** Token (i.e. JWT) to use for authentication */
  authToken: Token;

  /** Base URL of the Project API (e.g. https://v2.project.api.staging.holobuilder.com, https://projectapi.api.eu.dev.farosphere.com) */
  projectApi: URL;
};

export type ProjectApiConstructorParams = {
  /** ID of the project to work with */
  projectId: GUID;

  /** Token (i.e. JWT) to use for authentication */
  tokenProvider: TokenProvider;

  /** Base URL of the Project API (e.g. https://v2.project.api.staging.holobuilder.com, https://projectapi.api.eu.dev.farosphere.com) */
  projectApi: URL;

  /** A string to identify a backend client in the format client/version */
  clientId?: string;

  /** A callback function which will be executed on an authentication error */
  onAuthenticationError?(): void;

  /**
   * Delay to apply between failed requests.
   *
   * @default exponentialBackOff
   */
  retryDelay?: RetryOptions["delay"];
};

export type SlicePanoImageParams = {
  /** A signal to abort this request */
  signal?: AbortSignal;

  /** Uri of the image which is used to be sliced */
  originalImgUri: string;
};

/**
 * Check if a ProjectApiConstructorParams object is legacy
 *
 * @param params The params
 * @returns true if they are the legacy params
 */
function isLegacyParams(
  params: LegacyProjectApiConstructorParams | ProjectApiConstructorParams,
): params is LegacyProjectApiConstructorParams {
  return "authToken" in params;
}

/** A wrapper around the Project API to easily access all important endpoints. */
export class ProjectApi {
  #projectId: GUID;
  #tokenProvider: TokenProvider;
  #projectApiBaseUrl: URL;
  #clientId?: string;
  #onAuthenticationError?: () => void;
  #retryDelay?: RetryOptions["delay"];

  /** @returns the current linked project id */
  get projectId(): GUID {
    return this.#projectId;
  }

  /**
   * Create a new Project API client
   */
  constructor(params: ProjectApiConstructorParams);
  /**
   * Create a new Project API token
   *
   * @deprecated in favor of the alternative constructor taking a TokenProvider
   */
  constructor(params: LegacyProjectApiConstructorParams);
  /**
   * Constructor private implementations
   *
   * @param params new or legacy params
   */
  constructor(
    params: ProjectApiConstructorParams | LegacyProjectApiConstructorParams,
  ) {
    this.#projectId = params.projectId;
    this.#projectApiBaseUrl = params.projectApi;
    this.#tokenProvider = isLegacyParams(params)
      ? () => Promise.resolve(params.authToken)
      : params.tokenProvider;
    this.#clientId = isLegacyParams(params) ? undefined : params.clientId;
    this.#onAuthenticationError = isLegacyParams(params)
      ? undefined
      : params.onAuthenticationError;
    this.#retryDelay = isLegacyParams(params) ? undefined : params.retryDelay;
  }

  /** @returns The root IElement of the project with the given ID. */
  getRootIElement(): Promise<IElementProjectRoot> {
    return this.makeAuthorizedV1Request<IElementProjectRoot>({
      path: this.#projectId,
      typeGuard: (response): response is IElementProjectRoot =>
        validateIElement(response) && isIElementProjectRoot(response),
    });
  }

  /**
   * Provides a list of all elements of a project.
   * Defaults to request 5000 items per page (the current max for the API)
   */
  async getIElements(): Promise<PagedResponse<IElement>>;

  /**
   * Provides a list of all elements of a project matching the given filters.
   * Defaults to request 5000 items per page (the current max for the API)
   */
  async getIElements(
    params: GetIElementsParams,
  ): Promise<PagedResponse<IElement>>;

  /**
   * Provides a specific page of a paged iElements response.
   * Adding more query filters is not possible here.
   */
  async getIElements(params: PagedRequest): Promise<PagedResponse<IElement>>;

  /**
   * Implementation for the overloads above
   *
   * @param params The filters or next page token to use for the request
   * @returns The IElements of the requested project
   */
  async getIElements(
    params: GetIElementsParams | PagedRequest = {},
  ): Promise<PagedResponse<IElement>> {
    let queryParams: Record<string, string | undefined>;

    if ("nextPageToken" in params) {
      // When requesting a subsequent page, all other query parameters are ignored
      const { nextPageToken } = params;

      queryParams = {
        token: nextPageToken,
      };
    } else {
      const {
        itemsPerPage = 5000,
        type,
        types,
        typeHint,
        typeHints,
        changedAfter,
        ids,
        ancestorIds,
        descendantIds,
        parentIds,
      } = params;

      // Support for the legacy `type` and `typeHint` parameter, until we remove it
      const combinedTypes = [...(type ? [type] : []), ...(types ?? [])];
      const combinedTypeHints = [...(typeHint ?? []), ...(typeHints ?? [])];

      queryParams = {
        itemsPerPage: itemsPerPage.toString(),
        Types: combinedTypes.length > 0 ? combinedTypes.join(",") : undefined,
        TypeHints:
          combinedTypeHints.length > 0
            ? combinedTypeHints.join(",")
            : undefined,
        ChangedAfter: changedAfter?.toISOString(),
        Ids: ids?.join(","),
        AncestorIds: ancestorIds?.join(","),
        DescendantIds: descendantIds?.join(","),
        ParentIds: parentIds?.join(","),
      };
    }

    const pagedResponse = await this.makeAuthorizedV1Request<
      PagedResponse<IElement>
    >({
      signal: params.signal,
      path: `${this.#projectId}/ielements?migrateTypeHints=${MIGRATE_TYPE_HINTS_VALUE}`,
      queryParams,
    });

    const malformed = pagedResponse.page.filter((el) => !validateIElement(el));
    if (malformed.length > 0) {
      throw new Error(
        `Project api response contain malformed elements ${malformed
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
          .map((el: Partial<{ id: string }>) => el?.id)
          .join(",")}`,
      );
    }
    return pagedResponse;
  }

  /**
   * @returns All the iElements that matches the query parameters, will handle multiple page requests internally.
   * Callbacks can be used to track the progress of the request.
   */
  async getAllIElements({
    signal,
    onIndexFetched,
    onNextPageFetched,
    expectedResponseSize = ResponseSize.large,
    ...rest
  }: GetIElementsParams = {}): Promise<IElement[]> {
    try {
      let fetchedPages = 0;
      const iElements: IElement[] = [];

      if (expectedResponseSize === ResponseSize.small) {
        // No index request, as the response is expected to fit into a single page
        // Still call the callback for consistency
        onIndexFetched?.();

        let nextPageToken: string | null = null;

        do {
          const { page, token }: PagedResponse<IElement> = nextPageToken
            ? await this.getIElements({
                signal,
                nextPageToken,
              })
            : await this.getIElements({
                signal,
                ...rest,
              });
          nextPageToken = token;

          fetchedPages += 1;
          // Without the index, the total number of pages is unknown
          // Progress is approximated with a logistic function, which approaches 100 and has 50 when fetchedPages = 1
          // (since a low number of pages is expected)
          // See https://en.wikipedia.org/wiki/Logistic_function
          const progress = nextPageToken
            ? 100 / (1 + Math.pow(Math.E, -fetchedPages + 1))
            : 100;
          await onNextPageFetched?.(progress, page);
          iElements.push(...page);
        } while (nextPageToken);

        return iElements;
      }

      // For large responses, fetch the index first to be able to make multiple requests in parallel
      const { pageTokens, pageCount } = await this.getIndex({
        signal,
        ...rest,
      });
      onIndexFetched?.();

      // Fetch multiple pages in parallel for faster loading times
      for (const tokenChunk of chunk(pageTokens, PARALLEL_IELEMENT_REQUESTS)) {
        const pages = await Promise.all(
          tokenChunk.map(async (token) => {
            const res = await this.getIElements({
              signal,
              nextPageToken: token,
            });
            fetchedPages += 1;
            const progress = (fetchedPages / pageCount) * 100;
            await onNextPageFetched?.(progress, res.page);
            return res.page;
          }),
        );

        iElements.push(...pages.flat());
      }

      return iElements;
    } catch (error) {
      if (signal?.aborted) {
        return [];
      }
      throw error;
    }
  }

  /**
   * Check the status of a project
   *
   * @param signal to abort this request
   * @returns the status of the project
   */
  async getProjectStatus(signal?: AbortSignal): Promise<ProjectStatus> {
    const payload = await this.makeAuthorizedV1Request({
      path: `${this.#projectId}/status`,
      signal,
      typeGuard: (response): response is { status: ProjectStatus } =>
        validateNotNullishObject(response, "ProjectStatusResponse") &&
        isProjectStatus(response.status),
    });
    return payload.status;
  }

  /**
   * Slice a panorama image (equirectangular) into multiple levels of detail.
   *
   * @returns Returns a list of level of details for the sliced panorama image.
   */
  async slicePanoImage({
    originalImgUri,
    signal,
  }: SlicePanoImageParams): Promise<SlicePanoImageResponse> {
    const payload =
      await this.makeAuthorizedHelperRequest<SlicePanoImageResponse>({
        path: "panoimageslicer",
        httpMethod: "POST",
        requestBody: { originalImgUri, projectId: this.#projectId },
        signal,
        typeGuard: (response): response is SlicePanoImageResponse =>
          isSlicePanoImageResponse(response),
      });
    return payload;
  }

  /**
   * @param signal to abort the request
   * @returns the project labels
   */
  getProjectLabels(signal?: AbortSignal): Promise<ProjectLabels> {
    return this.makeAuthorizedV1Request<ProjectLabels>({
      path: `${this.projectId}/labels`,
      typeGuard: isProjectLabels,
      signal,
    });
  }

  /**
   * Applies mutations on the given project sequentially. If any mutation fails,
   * all subsequent mutations will not be executed, resulting in a 409 Conflict status code.
   *
   * The provided mutations will be forwarded as-is to the API, and the API response will be returned as-is
   *
   * @param mutations Mutations to apply to the project
   * @param allowPartialSuccess Whether to allow partial mutations to succeed. @default false
   * @returns The results of the mutation
   */
  applyMutations(
    mutations: Mutation[],
    allowPartialSuccess: boolean = false,
  ): Promise<MutationResult[]> {
    return this.makeAuthorizedV1Request<MutationResult[]>({
      path: `mutations/${this.#projectId}`,
      httpMethod: "POST",
      requestBody: mutations,
      queryParams: {
        allowPartialSuccess: allowPartialSuccess.toString(),
      },
    });
  }

  /**
   * Start an async task to apply mutations on the given project sequentially. If any mutation fails,
   * all subsequent mutations will not be executed, resulting in a 409 Conflict status code.
   *
   * The post will immediately return with results of type pending with an id to ask for the status trough the
   * tasks endpoint
   *
   * @param mutations Mutations to apply to the project
   * @param allowPartialSuccess Whether to allow partial mutations to succeed. @default false
   * @returns The results of the mutation
   */
  applyMutationsAsync(
    mutations: Mutation[],
    allowPartialSuccess: boolean = false,
  ): Promise<GUID> {
    return this.makeAuthorizedV1Request<GUID>({
      path: `mutationsasync/${this.#projectId}`,
      httpMethod: "POST",
      requestBody: mutations,
      queryParams: {
        allowPartialSuccess: allowPartialSuccess.toString(),
      },
    });
  }

  /**
   * @returns the current state of an async task
   * @param taskId the id of the task
   */
  getTaskStatus(taskId: GUID): Promise<TaskResult> {
    return this.makeAuthorizedV1Request({
      path: `${this.#projectId}/tasks/${taskId}`,
      typeGuard: isTaskResult,
    });
  }

  /**
   * @deprecated Replaced by `getIElementsByVolume` endpoint
   * @returns the list of datasets inside an area with their local position in that area
   * @param areaId of the area to query the datasets for
   * @param signal to abort the request
   */
  async queryAreaVolume(
    areaId: GUID,
    signal: AbortSignal,
  ): Promise<DataSetAreaInfo[]> {
    let pageToken: string | undefined | null = undefined;
    const dataSets: DataSetAreaInfo[] = [];

    while (pageToken !== null) {
      const { page, token }: PaginatedAreaVolumeResponse =
        await this.makeAuthorizedV1Request({
          path: `${this.projectId}/ielements/${areaId}/volumeQuery`,
          signal,
          typeGuard: isPaginatedAreaVolumeResponse,
          queryParams: {
            token: pageToken,
            itemsPerPage: AREA_VOLUME_PAGE_SIZE.toString(),
          },
        });
      dataSets.push(...page);
      pageToken = token;
    }

    return dataSets;
  }

  /**
   * @returns References to all elements that are either a child of the given area,
   * part of the area's volumes or explicitly aligned to the given area.
   */
  getIElementsByVolume({
    token,
    areaId,
    elementTypes,
    signal,
    itemsPerPage = AREA_VOLUME_PAGE_SIZE,
  }: GetIElementsByVolumeParams = {}): Promise<
    PagedResponse<IElementInVolume>
  > {
    const typeGuard = isPagedResponse(isIElementInVolume, "isIElementInVolume");
    return this.makeAuthorizedV1Request({
      path: `${this.projectId}/ielementsByVolume?migrateTypeHints=${MIGRATE_TYPE_HINTS_VALUE}`,
      signal,
      typeGuard,
      queryParams: {
        token,
        itemsPerPage: itemsPerPage.toString(),
        areaId,
        elementTypes: elementTypes
          ? elementTypes
              .map(({ type, typeHint }) =>
                typeHint ? `${type}.${typeHint}` : type,
              )
              .join(",")
          : undefined,
      },
    });
  }

  /**
   * @param params the parameters for the iElementsByVolume request
   * @returns All pages of the iElementsByVolume endpoint
   */
  async getAllIElementsByVolume(
    params: Omit<GetIElementsByVolumeParams, "token">,
  ): Promise<IElementInVolume[]> {
    let pageToken: string | undefined | null = undefined;
    const elements: IElementInVolume[] = [];

    while (pageToken !== null) {
      const { page, token } = await this.getIElementsByVolume({
        ...params,
        token: pageToken,
      });
      elements.push(...page);
      pageToken = token;
    }

    return elements;
  }

  /**
   * @returns the list of capture tree entities for the current open draft revision (if one exists),
   *          or for the main revision of the project.
   * @param signal to abort the request
   */
  async getCaptureTree(signal?: AbortSignal): Promise<CaptureTreeEntity[]> {
    let pageToken: string | undefined | null = undefined;
    const entities: CaptureTreeEntity[] = [];
    const typeGuard = isPagedResponse(
      isCaptureTreeEntity,
      "isCaptureTreeEntity",
    );
    // TODO: Remove duplication https://faro01.atlassian.net/browse/SWEB-4900
    while (pageToken !== null) {
      const { page, token }: PagedResponse<CaptureTreeEntity> =
        await this.makeAuthorizedV1RestRequest({
          path: `projects/${this.projectId}/capture-tree`,
          signal,
          typeGuard,
          queryParams: {
            token: pageToken,
            itemsPerPage: CAPTURE_TREE_PAGE_SIZE.toString(),
          },
        });
      entities.push(...page);
      pageToken = token;
    }

    return entities;
  }

  /** @deprecated Use `getCaptureTree` instead. */
  getCaptureTreeForMainRevision = this.getCaptureTree.bind(this);

  /**
   * @returns the list of capture tree entities for a specific registration revision of the project
   * @param registrationRevisionId the id of the registration revision
   * @param signal to abort the request
   */
  async getCaptureTreeForRegistrationRevision(
    registrationRevisionId: GUID,
    signal?: AbortSignal,
  ): Promise<CaptureTreeEntityRevision[]> {
    let pageToken: string | undefined | null = undefined;
    const entityRevisions: CaptureTreeEntityRevision[] = [];
    const typeGuard = isPagedResponse(
      isCaptureTreeEntityRevision,
      "isCaptureTreeEntityRevision",
    );
    // TODO: Remove duplication https://faro01.atlassian.net/browse/SWEB-4900
    while (pageToken !== null) {
      const { page, token }: PagedResponse<CaptureTreeEntityRevision> =
        await this.makeAuthorizedV1RestRequest({
          path: `projects/${this.projectId}/registration-revisions/${registrationRevisionId}`,
          signal,
          typeGuard,
          queryParams: {
            token: pageToken,
            itemsPerPage: CAPTURE_TREE_PAGE_SIZE.toString(),
          },
        });
      entityRevisions.push(...page);
      pageToken = token;
    }

    return entityRevisions;
  }

  /**
   * @returns the requested registration revision of the project
   * @param revisionId the id of the revision to query
   * @param signal to abort the request
   */
  getRegistrationRevision(
    revisionId: GUID,
    signal?: AbortSignal,
  ): Promise<RegistrationRevision> {
    return this.makeAuthorizedV1RestRequest({
      path: `projects/${this.projectId}/registration-revisions/${revisionId}/info`,
      signal,
      typeGuard: isRegistrationRevision,
    });
  }

  /**
   * @returns the list registration revisions of the project
   * @param signal to abort the request
   */
  async getAllRegistrationRevisions(
    signal?: AbortSignal,
  ): Promise<RegistrationRevision[]> {
    let pageToken: string | undefined | null = undefined;
    const registrationRevisions: RegistrationRevision[] = [];
    const typeGuard = isPagedResponse(
      isRegistrationRevision,
      "isRegistrationRevision",
    );
    // TODO: Remove duplication https://faro01.atlassian.net/browse/SWEB-4900
    while (pageToken !== null) {
      const { page, token }: PagedResponse<RegistrationRevision> =
        await this.makeAuthorizedV1RestRequest({
          path: `projects/${this.projectId}/registration-revisions`,
          signal,
          typeGuard,
          queryParams: {
            token: pageToken,
            itemsPerPage: CAPTURE_TREE_PAGE_SIZE.toString(),
          },
        });
      registrationRevisions.push(...page);
      pageToken = token;
    }

    return registrationRevisions;
  }

  /**
   * @returns the list of registration edges for the current open draft revision (if one exists),
   *          or for the main revision of the project.
   * @param signal to abort the request
   */
  async getRegistrationEdges(
    signal?: AbortSignal,
  ): Promise<RegistrationEdge[]> {
    let pageToken: string | undefined | null = undefined;
    const edges: RegistrationEdge[] = [];
    const typeGuard = isPagedResponse(isRegistrationEdge, "isRegistrationEdge");
    // TODO: Remove duplication https://faro01.atlassian.net/browse/SWEB-4900
    while (pageToken !== null) {
      const { page, token }: PagedResponse<RegistrationEdge> =
        await this.makeAuthorizedV1RestRequest({
          path: `projects/${this.projectId}/registration-edges`,
          signal,
          typeGuard,
          queryParams: {
            token: pageToken,
            itemsPerPage: CAPTURE_TREE_PAGE_SIZE.toString(),
          },
        });
      edges.push(...page);
      pageToken = token;
    }

    return edges;
  }

  /** @deprecated Use `getRegistrationEdges` instead. */
  getAllRegistrationEdgesForMainRevision = this.getRegistrationEdges.bind(this);

  /**
   * @returns the list of registration edges for a specific revision of the project
   * @param registrationRevisionId the id of the registration revision
   * @param signal to abort the request
   */
  async getRegistrationEdgesForRevision(
    registrationRevisionId: GUID,
    signal?: AbortSignal,
  ): Promise<CaptureTreeEdgeRevision[]> {
    let pageToken: string | undefined | null = undefined;
    const entityRevisions: CaptureTreeEdgeRevision[] = [];
    const typeGuard = isPagedResponse(
      (data) =>
        isRegistrationEdgeRevision(data) && isCaptureTreeEdgeRevision(data),
      "isRegistrationEdgeRevision && isCaptureTreeEdgeRevision",
    );
    // TODO: Remove duplication https://faro01.atlassian.net/browse/SWEB-4900
    while (pageToken !== null) {
      const { page, token }: PagedResponse<CaptureTreeEdgeRevision> =
        await this.makeAuthorizedV1RestRequest({
          path: `projects/${this.projectId}/registration-revisions/${registrationRevisionId}/edges`,
          signal,
          typeGuard,
          queryParams: {
            token: pageToken,
            itemsPerPage: CAPTURE_TREE_PAGE_SIZE.toString(),
          },
        });
      entityRevisions.push(...page);
      pageToken = token;
    }

    return entityRevisions;
  }

  /**
   * @param registrationRevisionId The revision to delete edges from.
   * @param registrationEdgeIds The IDs of the registration edges which should be marked as deleted.
   *  The edge entities will not be removed before the revision is merged, only their status will be set to deleted.
   */
  async markRegistrationEdgeRevisionsAsDeleted(
    registrationRevisionId: GUID,
    registrationEdgeIds: GUID[],
  ): Promise<void> {
    for (const idChunk of chunk(registrationEdgeIds, CAPTURE_TREE_CHUNK_SIZE)) {
      await this.makeAuthorizedV1RestRequest<void>({
        path: `projects/${this.#projectId}/registration-revisions/${registrationRevisionId}/edges`,
        httpMethod: "DELETE",
        requestBody: idChunk,
      });
    }
  }

  /**
   * @throws error if the registration revision was not successfully applied
   * @returns a promise that will resolve or rejects once the operation is completed
   * @param captureTreeEntityIds The ids of the capture tree entities to include in the revision
   * @param registrationEdgeIds The ids of the registration edges to include in the revision
   * @param createdByClient Optional param to define client name that created the revision.
   */
  async createRegistrationRevision(
    captureTreeEntityIds: GUID[],
    registrationEdgeIds: GUID[],
    createdByClient?: CaptureApiClient,
  ): Promise<RegistrationRevision> {
    return await this.makeAuthorizedV1RestRequest<RegistrationRevision>({
      path: `projects/${this.#projectId}/registration-revisions`,
      httpMethod: "POST",
      requestBody: {
        captureTreeEntityIds,
        registrationEdgeIds,
        createdByClient,
      },
    });
  }

  /**
   * Apply (= merge) the changes of a revision.
   *
   * @see publishDraftRevision
   * @throws error if the registration revision was not successfully applied
   * @returns a promise that will resolve or rejects once the operation is completed
   * @param registrationRevisionId the id of the registration revision to be applied to draft/main
   */
  applyRegistrationRevision(registrationRevisionId: GUID): Promise<void> {
    return this.makeAuthorizedV1RestRequest<void>({
      path: `projects/${this.#projectId}/registration-revisions/${registrationRevisionId}/apply`,
      httpMethod: "POST",
    });
  }

  /** @deprecated Use `applyRegistrationRevision` instead. */
  applyRegistrationRevisionToMain = this.applyRegistrationRevision.bind(this);

  /**
   * Publish the Draft Revision to the Capture Tree.
   *
   * @see applyRegistrationRevision
   * @throws error if the registration revision was not successfully applied
   * @returns a promise that will resolve or rejects once the operation is completed
   * @param draftRevisionId the id of the registration revision to be applied to main
   */
  publishDraftRevision(draftRevisionId: GUID): Promise<void> {
    return this.makeAuthorizedV1RestRequest<void>({
      path: `projects/${this.#projectId}/registration-revisions/${draftRevisionId}/publish`,
      httpMethod: "POST",
    });
  }

  /**
   * @returns the updated registration revision
   */
  async updateRegistrationRevision({
    registrationRevisionId,
    state,
    reportUri,
    projectPointCloud,
  }: UpdateRegistrationRevisionParams): Promise<RegistrationRevision> {
    return await this.makeAuthorizedV1RestRequest<RegistrationRevision>({
      path: `projects/${this.#projectId}/registration-revisions/${registrationRevisionId}`,
      httpMethod: "PATCH",
      requestBody: { state, reportUri, projectPointCloud },
    });
  }

  /**
   * @throws error if the root entity was not successfully created
   * @returns a promise that will resolve or rejects once the operation is completed
   */
  createOrUpdateRootEntityForRegistrationRevision({
    registrationRevisionId,
    requestBody,
  }: CreateRootEntityParams): Promise<void> {
    return this.makeAuthorizedV1RestRequest<void>({
      path: `projects/${this.#projectId}/registration-revisions/${registrationRevisionId}/root`,
      httpMethod: "PATCH",
      requestBody,
    });
  }

  /** @deprecated Use `createOrUpdateRootEntityForRegistrationRevision` instead */
  createRootEntityForRegistrationRevision =
    this.createOrUpdateRootEntityForRegistrationRevision;

  /**
   * @throws error if the cluster entities were not successfully created
   * @returns a promise that will resolve or rejects once the operation is completed
   */
  async createOrUpdateClusterEntitiesForRegistrationRevision({
    registrationRevisionId,
    requestBody,
  }: CreateClusterEntitiesParams): Promise<void> {
    for (const clusterChunk of chunk(requestBody, CAPTURE_TREE_CHUNK_SIZE)) {
      await this.makeAuthorizedV1RestRequest<void>({
        path: `projects/${this.#projectId}/registration-revisions/${registrationRevisionId}/clusters`,
        httpMethod: "PATCH",
        requestBody: clusterChunk,
      });
    }
  }

  /** @deprecated Use `createOrUpdateClusterEntitiesForRegistrationRevision` instead */
  createClusterEntitiesForRegistrationRevision =
    this.createOrUpdateClusterEntitiesForRegistrationRevision;

  /**
   * @throws error if the scan entities were not successfully created
   * @returns a promise that will resolve or rejects once the operation is completed
   */
  async createOrUpdateScanEntitiesForRegistrationRevision({
    registrationRevisionId,
    requestBody,
  }: CreateScanEntitiesParams): Promise<void> {
    for (const scanChunk of chunk(requestBody, CAPTURE_TREE_CHUNK_SIZE)) {
      await this.makeAuthorizedV1RestRequest<void>({
        path: `projects/${this.#projectId}/registration-revisions/${registrationRevisionId}/scans`,
        httpMethod: "PATCH",
        requestBody: scanChunk,
      });
    }
  }

  /** @deprecated Use `createOrUpdateScanEntitiesForRegistrationRevision` instead */
  createScanEntitiesForRegistrationRevision =
    this.createOrUpdateScanEntitiesForRegistrationRevision;

  /**
   * Create or update registration edges for a registration revision.
   *
   * Ignores global edges, as they only exist in the registration report.
   *
   * @throws error if the registration edges were not successfully created
   * @returns a promise that will resolve or rejects once the operation is completed
   */
  async createOrUpdateRegistrationEdges({
    registrationRevisionId,
    requestBody,
  }: CreateOrUpdateRegistrationEdgesParams): Promise<void> {
    for (const edgeChunk of chunk(requestBody, CAPTURE_TREE_CHUNK_SIZE)) {
      await this.makeAuthorizedV1RestRequest<void>({
        path: `projects/${this.#projectId}/registration-revisions/${registrationRevisionId}/edges`,
        httpMethod: "PATCH",
        requestBody: edgeChunk,
      });
    }
  }

  /**
   * Merges data from a source Revision into a target Revision.
   *
   * @param mergeEntities Map of CaptureTree entities to merge into the target revision.
   * @param mergeEdges Map of CaptureTree edges to merge into the target revision.
   * @param targetRevisionEntities List of entities which already exist in the target revision.
   *                               This is required to merge entity content and avoid data-loss.
   * @param targetRevisionId The ID of the revision to merge the data to.
   */
  async mergeRegistrationRevisionData(
    mergeEntities: CaptureTreeEntity[],
    mergeEdges: CaptureTreeEdgeRevision[],
    targetRevisionEntities: CaptureTreeEntity[],
    targetRevisionId: GUID,
  ): Promise<void> {
    // For the Entities we need separate lists for each entity type, due to different api endpoints
    let updatedRoot;
    const updatedClusters = [];
    const updatedScans = [];

    for (const mergeEntity of mergeEntities) {
      let entityToCollect;

      // If the entity is already part of the target revision, we want to patch the existing entity to avoid deleting relevant data
      // e.g. generated point clouds
      const targetEntity = targetRevisionEntities.find(
        (e) => e.id === mergeEntity.id,
      );
      if (targetEntity) {
        // patch relevant information from source to target entity, for just the pose
        entityToCollect = { ...targetEntity, pose: mergeEntity.pose };
      } else {
        entityToCollect = mergeEntity;
      }

      switch (entityToCollect.type) {
        case CaptureTreeEntityType.root:
          updatedRoot = entityToCollect;
          break;
        case CaptureTreeEntityType.cluster:
          updatedClusters.push(entityToCollect);
          break;
        default:
          updatedScans.push(entityToCollect);
      }
    }

    if (updatedRoot) {
      await this.createOrUpdateRootEntityForRegistrationRevision({
        registrationRevisionId: targetRevisionId,
        requestBody: updatedRoot,
      });
    }
    if (updatedClusters.length > 0) {
      await this.createOrUpdateClusterEntitiesForRegistrationRevision({
        registrationRevisionId: targetRevisionId,
        requestBody: updatedClusters,
      });
    }
    if (updatedScans.length > 0) {
      await this.createOrUpdateScanEntitiesForRegistrationRevision({
        registrationRevisionId: targetRevisionId,
        requestBody: updatedScans,
      });
    }

    // For Edges there's no point in partially patching them, so we always replace the objects as a whole
    await this.createOrUpdateRegistrationEdges({
      registrationRevisionId: targetRevisionId,
      requestBody: mergeEdges,
    });
  }

  /**
   * @returns the list of areas an element is contained in
   * @param elementId of the area to query the datasets for
   * @param signal to abort the request
   */
  async queryAreaVolumeInverse(
    elementId: GUID,
    signal: AbortSignal,
  ): Promise<DataSetAreaInfo[]> {
    let pageToken: string | undefined | null = undefined;
    const dataSets: DataSetAreaInfo[] = [];

    while (pageToken !== null) {
      const { page, token }: PaginatedAreaVolumeResponse =
        await this.makeAuthorizedV1Request({
          path: `${this.projectId}/ielements/${elementId}/inverseVolumeQuery`,
          signal,
          typeGuard: isPaginatedAreaVolumeResponse,
          queryParams: {
            token: pageToken,
            itemsPerPage: AREA_VOLUME_PAGE_SIZE.toString(),
          },
        });
      dataSets.push(...page);
      pageToken = token;
    }

    return dataSets;
  }

  /**
   * Apply cloud to cad model alignment via cloudtobim endpoint
   *
   * The provided CloudToBimAlignment will be forwarded as-is to the API
   *
   * @param alignment the given CloudToBimAlignment to apply to the project
   * @returns The results of the CloudToBimAlignment
   */
  applyCloudToBimAlignment(
    alignment: CloudToBimAlignment,
  ): Promise<CloudAlignmentResponse> {
    return this.makeAuthorizedV1RestRequest<CloudAlignmentResponse>({
      path: `projects/${this.#projectId}/align/cloudtobim`,
      httpMethod: "POST",
      requestBody: alignment,
      typeGuard: isCloudAlignmentResponse,
    });
  }

  /**
   * Delete all alignments of all point clouds in the capture Tree
   * https://v2.project.api.dev.holobuilder.com/swagger/index.html#tag/Alignment/paths/~1v1.rest~1projects~1%7BprojectId%7D~1resetcloudalignments/delete.
   *
   * @returns nothing
   */
  resetCloudAlignments(): Promise<CloudAlignmentResponse> {
    return this.makeAuthorizedV1RestRequest<CloudAlignmentResponse>({
      path: `projects/${this.#projectId}/resetcloudalignments`,
      httpMethod: "DELETE",
      typeGuard: isCloudAlignmentResponse,
    });
  }

  /**
   *
   * @param projectName The name of the project being created in Sphere.
   * @param workspaceId The workspace in which to insert the new project
   * @param JWTtoken The token to use to authenticate the request
   * @returns a core API response
   */
  public async linkProjectToSphere(
    projectName: string,
    workspaceId: GUID | null,
    JWTtoken: Token,
  ): Promise<SphereSuccess> {
    const path = `${this.#projectId}/proxy`;

    try {
      return await this.makeAuthorizedV1Request<SphereSuccess>({
        path,
        additionalHeaders: {
          "Access-Control-Allow-Credentials": "true",
        },
        httpMethod: "POST",
        requestBody: {
          type: "ConnectWithSphereRequest",
          projectName,
          workspaceId,
        },
        tokenProvider: () => Promise.resolve(JWTtoken),
      });
    } catch (error) {
      if (error instanceof ApiResponseError) {
        const message = error.body;
        let newMsg = message;
        // We determine whether the error we are getting is a Sphere API error,
        // in order to provide a more meaningful and easier error message.
        if (isSphereAPIerror(message)) {
          if (isSphereAPIValidationError(message)) {
            newMsg = `Sphere validation error: ${message.error}`;
          } else {
            newMsg = `Sphere API error: ${message.error}`;
          }
        }
        // Using `ProjectApiError` instead of `ApiResponseError` for backwards compatibility
        throw new ProjectApiError(error.status, error.statusText, newMsg);
      } else {
        throw error;
      }
    }
  }

  /**
   * @param params {@link GetIElementsParams} parameters to filter the index.
   * @returns a list of tokens for all pages of the project.
   */
  public async getIndex(
    params: GetIElementsParams,
  ): Promise<PaginatedIndexResponse> {
    const path = `${this.#projectId}/ielements/index`;
    return await this.makeAuthorizedV1Request<PaginatedIndexResponse>({
      path,
      signal: params.signal,
      queryParams: {
        itemsPerPage: params.itemsPerPage?.toString(),
        Type: params.type,
        Types: params.types?.join(","),
        TypeHints: params.typeHints?.join(","),
        ChangedAfter: params.changedAfter?.toISOString(),
        Ids: params.ids?.join(","),
        AncestorIds: params.ancestorIds?.join(","),
        DescendantIds: params.descendantIds?.join(","),
        ParentIds: params.parentIds?.join(","),
      },
      typeGuard: (response): response is PaginatedIndexResponse =>
        validateNotNullishObject(response, "PaginatedIndexResponse") &&
        typeof response.pageCount === "number" &&
        typeof response.itemCount === "number" &&
        validateArrayOf({
          object: response,
          prop: "pageTokens",
          elementGuard: (x) => typeof x === "string",
        }),
    });
  }

  /**
   * @returns The token price needed to generate a floor plan
   */
  public calculateTokensForFloorPlanGeneration({
    signal,
    datasetId,
  }: FloorPlanGenerationTokenMathRequest): Promise<number> {
    return this.makeAuthorizedV1Request({
      httpMethod: "POST",
      path: "tokenMath/floorplanGeneration",
      signal,
      requestBody: {
        datasetId,
      },
      typeGuard: isTokenPriceResponse,
    });
  }

  /**
   * @returns The token price needed for an amount of storage
   */
  public calculateTokensForStorage({
    signal,
    bytesOfStorage,
  }: StorageTokenMathRequest): Promise<number> {
    return this.makeAuthorizedV1Request({
      httpMethod: "POST",
      path: "tokenMath/storage",
      signal,
      requestBody: {
        bytesOfStorage,
      },
      typeGuard: isTokenPriceResponse,
    });
  }

  /**
   * @returns The token price to process a set of scans
   */
  public calculateTokensForScanProcessing({
    signal,
    scanIds,
  }: ScanProcessingTokenMathRequest): Promise<number> {
    return this.makeAuthorizedV1Request({
      httpMethod: "POST",
      path: "tokenMath/scanProcessing",
      signal,
      requestBody: {
        scanIds,
      },
      typeGuard: isTokenPriceResponse,
    });
  }

  /**
   * Request the core api to sign some urls attached to a specific element
   *
   * @returns the signed urls and their new expire date
   */
  public async signUrls({
    signal,
    elements,
  }: SignUrlsParams): Promise<SignUrlsResponse> {
    return await this.makeAuthorizedV1Request<SignUrlsResponse>({
      httpMethod: "POST",
      path: `signurls/${this.#projectId}`,
      signal,
      requestBody: {
        elements,
      },
      typeGuard: isSignUrlsResponse,
    });
  }

  /**
   * Send a call to the v1 standard endpoint of the project api
   * Used for IElements and mutations
   *
   * @param params for the request
   * @returns a promise with the backend response
   */
  private makeAuthorizedV1Request<T>(
    params: Optional<
      SendAuthenticatedJsonRequestParams<T>,
      "tokenProvider" | "clientId"
    >,
  ): Promise<T> {
    return this.makeAuthorizedRequest<T>({
      ...params,
      path: `/v1/${params.path}`,
    });
  }

  /**
   * Send a call to the v1 rest endpoint of the project api
   * Used for the capture tree and newer apis that uses a REST approach instead of mutations
   *
   * @param params for the request
   * @returns a promise with the backend response
   */
  private makeAuthorizedV1RestRequest<T>(
    params: Optional<
      SendAuthenticatedJsonRequestParams<T>,
      "tokenProvider" | "clientId"
    >,
  ): Promise<T> {
    return this.makeAuthorizedRequest<T>({
      ...params,
      path: `/v1.rest/${params.path}`,
    });
  }

  /**
   * Send a call to the helper endpoint of the project api
   *
   * @param params for the request
   * @returns a promise with the backend response
   */
  private makeAuthorizedHelperRequest<T>(
    params: Optional<
      SendAuthenticatedJsonRequestParams<T>,
      "tokenProvider" | "clientId"
    >,
  ): Promise<T> {
    return this.makeAuthorizedRequest<T>({
      ...params,
      path: `/helper/${params.path}`,
    });
  }

  /**
   * Make an authorized request to the Project API.
   *
   * This `baseUrl`, `clientId`, and `tokenProvider` are set automatically.
   *
   * @param params The parameters to pass to the `sendAuthenticatedJsonRequest` utility.
   * @returns The deserialized response of the API.
   */
  private makeAuthorizedRequest<T>(
    params: Optional<
      SendAuthenticatedJsonRequestParams<T>,
      "tokenProvider" | "clientId" | "baseUrl"
    >,
  ): Promise<T> {
    try {
      return sendAuthenticatedJsonRequest({
        ...params,
        baseUrl: this.#projectApiBaseUrl.toString(),
        path: params.path,
        tokenProvider: this.#tokenProvider,
        clientId: this.#clientId,
        onAuthenticationError: this.#onAuthenticationError,
        retryDelay: this.#retryDelay,
        // TODO: Properly enforce type guards and add missing type validation
        // https://faro01.atlassian.net/browse/SWEB-2572
        typeGuard: params.typeGuard
          ? params.typeGuard
          : (body: unknown): body is T => true,
      });
    } catch (error) {
      if (error instanceof ApiResponseError) {
        // To maintain backwards compatibility
        throw new ProjectApiError(error.status, error.statusText, error.body);
      }
      throw error;
    }
  }
}
