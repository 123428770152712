import { TypedEvent } from "@faro-lotv/foundation";
import { EventDispatcher, MOUSE, Object3D, PerspectiveCamera, Quaternion, Raycaster, Vector2, Vector3 } from "three";
import { DEFAULT_PERSPECTIVE_FOV, SupportedCamera, pixels2m, pixels2radians } from "../Utils";
import { cartesian2spherical, clamp, degToRad, spherical2cartesian } from "../Utils/Math";
import { memberWithPrivateData } from "../Utils/MemoryUtils";
import { KeyboardEvents } from "./KeyboardEvents";
import { DEFAULT_MAX_PERSP_FOV, DEFAULT_MIN_PERSP_FOV } from "./SphereControls";
import { SyncableControls } from "./SyncableControls";
import { DoubleTouch, PointerCoords, TouchEvents, isMouseButton } from "./TouchEvents";
import {
	CameraParams,
	MotionState,
	PIVOT_DISABLED_DISTANCE,
	pinchAndZoomPerspectiveCamera,
	zoomOrthocamera,
	zoomPerspectiveCamera,
} from "./WalkOrbitControlsPrivate";

const Z_UP = 0;
const Y_UP = 1;

/** Min distance an obstacle need to be to make the camera slow down */
const PASSTHROUGH_DISTANCE = 0.5;

/** Distance of the next obstacle if there are no valid obstacles (define max speed in fly mode) */
const NO_OBSTACLE_DISTANCE = 5;

/**
 * The amount of inertia left after one second in a friction model
 *
 * @deprecated This value is only used for backwards compatibility
 */
const DEFAULT_INERTIA = 0.9;

/** Limit the max phi angle so we don't hit the degenerate 90° case but we still can look at the model from the top/bottom */
const MAX_PHI_DEG = 89.999;

/** Number multiplied when calculating the distance (in meters) traveled */
const DISTANCE_MULTIPLIER = 0.015;

/** Min pix2meter factor to use when the camera is too close to the pivot */
const MIN_PIVOT_DISTANCE_FACTOR = 0.3;

/** Max pix2meter factor to allow when the camera is too far to the pivot */
const MAX_PIVOT_DISTANCE_FACTOR = 15000;

/** Divisor factor to adjust wheel values */
const WHEEL_SENSITIVITY_FACTOR = 2000;

/** Min pix2meter factor to use when the camera is too close to the pivot */
const MIN_PIX2M_FACTOR = 0.01;

/** Max pix2meter factor to allow when the camera is too far to the pivot */
const MAX_PIX2M_FACTOR = 1000;

/** Time window in which the actual movement state is accumulated for a smoother experience with infrequent drag-events */
const ACTUAL_MOTION_SMOOTHING_TIME = 0.05;

/** All possible movement types that can be triggered by a mouse drag */
export enum MovementType {
	/** Moving the camera along the project horizontal plane */
	HorizontalTranslation = 0,

	/** Move the camera along the camera plane */
	CameraPan = 1,

	/** Move the camera toward the camera direction */
	Dollying = 2,

	/** Rotate the camera around the pivot point */
	Rotating = 3,

	/** Move the camera up/down along the project vertical direction */
	VerticalTranslation = 4,
}

/**
 * Whether a forward translation should result in a "fly" or "walk" movement
 * 'Fly' will translate along the camera's viewing direction, 'walk' will
 * translate along the horizontal plane.
 */
export enum ForwardMovement {
	Fly = "Fly",
	Walk = "Walk",
}

/**
 * This interactor supports two rotation modes. The pointer/arrow keys
 * can rotate the view direction (like in a first-person shooter),
 * or can rotate the model (like in a 360 images viewer where the object
 * remains under the pointer while rotating)
 */
export enum PointerRotates {
	ViewDirection = "ViewDirection",
	Model = "Model",
}

/**
 * Purpose of this class is to provide a 3D interactor for mouse, keys and touch events,
 * that combines the behaviors of an orbit controller and a walk mode. Both Y-up and Z-up
 * cameras are supported by this interactor.
 *
 * Behaviors:
 *    Mouse Drag:   Each button can be configured separately to:
 * 					Rotate: Rotate the camera around the pivot if valid or around the camera itself
 *                  HorizontalTranslation: Move the camera along the project horizontal plane
 *                  CameraPan: Move the camera along the camera view plane
 *                  VerticalTranslation: Move the camera up/down along the project up direction
 *    Mouse Wheel:  If the projection is perspective, the camera translates back and forth along its view direction.
 *                  The translation speed depends on how close the camera is to an obstruction or the pivot point
 *                  If on the other hand the projection is orthographic, mouse wheel spin causes the ortho projection to zoom in and out.
 *    One-touch drag: orbiting around rotation pivot
 *    Two-touch drag: combination of
 *                     - translation on horizontal plane
 *                     - dollying along focal axis
 *                     - rotation around Z axis.
 *    Arrow keys:   orbits around the pivot point.
 *    WASD keys:    walk (Camera translates on its current horizontal plane).
 *    E key:        translates vertically up
 *    Q key:        translates vertically down.
 *
 * Camera zoom is NOT SUPPORTED by this interactor.
 *
 * Also, this interactor accounts for movement and rotation inertia. Inertia is present only in mouse interaction.
 * Touchscreen interaction goes without inertia.
 */
export class WalkOrbitControls extends EventDispatcher implements SyncableControls {
	/** The camera to be controlled */
	#camera: SupportedCamera;

	/** Whether the current controls are enabled */
	#enabled = true;

	/**  Movement type: horiz translating, dollying, rotating. */
	#movementType = MovementType.HorizontalTranslation;

	/** The last mouse position used to calculate a drag event's delta movement */
	#lastMousePos = new Vector2();

	/** the data in the member #currCamera are always synced with the current camera */
	#currCamera = new CameraParams();

	/** lastCamera is needed to compute the movement speed between lastCamera and currCamera for the inertia effect */
	#lastCamera = new CameraParams();

	/** Handling touch events */
	#touchEvents = new TouchEvents();

	/** Handling keyboard events */
	#keyboardEvents = new KeyboardEvents();

	/** Movement in Keyboard space */
	#keyTranslation = new Vector3();
	#keyRotation = new Vector2();

	/** Motion state of different keyboard inputs */
	#keyboardMotionState = new MotionState();
	#mouseMotionState = new MotionState();
	#touchMotionState = new MotionState();

	/** Motion state applied at the frame */
	#combinedMotionState = new MotionState();

	/** Actual motion state including all non-inertial movements. Calculated at fixed intervals for smoothing */
	#actualMotionState = new MotionState();

	/** Internal raycaster instance used to check for obstacles */
	#raycaster = new Raycaster();

	/** Up direction (either Z or Y up are supported) */
	#upDirection = Y_UP;

	/** Whether the camera is moving or not */
	#isCameraMoving = false;

	/**
	 * This interactor supports two rotation modes. The pointer/arrow keys
	 * can rotate the view direction (like in a first-person shooter),
	 * or can rotate the model (like in a 360 images viewer where the object
	 * remains under the pointer while rotating)
	 */
	#pointerRotates = PointerRotates.ViewDirection;
	#rotationMultiplier = 1;

	#forwardMovement = ForwardMovement.Walk;

	/**
	 * If the walk orbit controls are handling a perspective camera, the controls do not allow
	 * the camera FOV to be other than this value. If the camera handled has another FOV value,
	 * then wheel events are used to restore the default value, instead of translating the camera.
	 */
	#defaultPerspFov = DEFAULT_PERSPECTIVE_FOV;

	/** Min Phi angle the camera should rotate to in radians */
	minPhi = degToRad(-MAX_PHI_DEG);

	/** Max Phi angle the camera should rotate to in radians*/
	maxPhi = degToRad(MAX_PHI_DEG);

	/** Distance in meters of the camera from the pivot point when the pivot point changes */
	focusDistance = 5;

	/** Sensitivity of the mouse wheel, higher means higher movement speed */
	wheelSensitivity = 1;

	/** Custom viewport height, default to canvas height if not defined */
	viewportHeight?: number;

	/** A map for each action a mouse button need to trigger */
	mouseBindings: Record<MOUSE.LEFT | MOUSE.MIDDLE | MOUSE.RIGHT, MovementType> = {
		[MOUSE.LEFT]: MovementType.Rotating,
		[MOUSE.RIGHT]: MovementType.HorizontalTranslation,
		[MOUSE.MIDDLE]: MovementType.CameraPan,
	};

	/** Parameters for accelerated movement of the keyboard controls */
	keyboardSettings = {
		/** Translation speed at in m/s (scales with the pivot distance) */
		movementSpeed: 24,

		/** Translation acceleration in m/s^2 (scales with the pivot distance) */
		movementAcceleration: 150,

		/** Rotation speed in rad/s */
		rotationSpeed: 2,

		/** Rotation acceleration in rad/s^2 */
		rotationAcceleration: 5,

		/** Multiplier for speed when the sprint key is pressed */
		sprintSpeedMultiplier: 5.625,

		/**  Multiplier for the movement acceleration when the sprint key is pressed */
		sprintAccelerationMultiplier: 8,
	};

	/**
	 * Bindings for keyboard movement. Uses the key.code property.
	 * see https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code
	 */
	keyboardBindings = {
		movement: {
			forward: "KeyW",
			backward: "KeyS",
			left: "KeyA",
			right: "KeyD",
			up: "KeyE",
			down: "KeyQ",
			sprint: "ShiftLeft",
		},
		rotation: {
			left: "ArrowLeft",
			right: "ArrowRight",
			up: "ArrowUp",
			down: "ArrowDown",
		},
	};

	/** Smooth mouse movement settings */
	mouseSettings = {
		/** Fraction of movement inertia kept after 1s of friction in range (0,1) */
		movementInertia: 0.03,

		/** Fraction of rotation inertia kept after 1s of friction in range (0,1) */
		rotationInertia: 0.03,
	};

	/** @deprecated Use mouseSettings.rotationFriction instead */
	rotationInertia: number = DEFAULT_INERTIA;

	/** @deprecated Use mouseSettings.movementFriction instead */
	movementInertia: number = DEFAULT_INERTIA;

	/** Objects to check for obstacles while zooming  */
	obstacles?: Object3D[];

	/** Signal the target is not valid anymore */
	targetDismissed = new TypedEvent<void>();

	/** Event fired when the user interacts with the control */
	userInteracted = new TypedEvent<void>();

	/** Event fired when the user started moving the camera position, either via pointer or via keys. */
	cameraStartedTranslating = new TypedEvent<void>();

	/** Event fired when the user stopped moving the camera position */
	cameraStoppedTranslating = new TypedEvent<void>();

	/**
	 * Construct the walk/orbit control class
	 *
	 * @param camera The camera to control
	 * @param element The element to listen for events
	 */
	constructor(camera: SupportedCamera, element?: HTMLCanvasElement) {
		super();
		this.#camera = camera;
		this.updateFromCamera();
		this.removeTarget(true);

		this.onSingleTouchMove = this.onSingleTouchMove.bind(this);
		this.onDoubleTouchRotate = this.onDoubleTouchRotate.bind(this);
		this.onDoubleTouchTranslate = this.onDoubleTouchTranslate.bind(this);
		this.onPinchAndZoom = this.onPinchAndZoom.bind(this);
		this.onMouseDown = this.onMouseDown.bind(this);
		this.onMouseUp = this.onMouseUp.bind(this);
		this.onMouseDrag = this.onMouseDrag.bind(this);
		this.onWheel = this.onWheel.bind(this);

		this.updateMovementFromKeys = this.updateMovementFromKeys.bind(this);

		this.attachListeners();
		if (element) {
			this.attach(element);
		}
	}

	/**
	 *
	 * @param forward How many meters to translate in the forward direction
	 * @param side How many meters to translate in the right-side direction
	 * @param out Vector to return the result in (to avoid allocations)
	 * @returns A vector corresponding to a movement along the (camera view., camera right) plane
	 */
	#getFlyTranslation = memberWithPrivateData(() => {
		const diry = new Vector3();

		return (forward: number, side: number, out: Vector3): Vector3 => {
			const dir = this.#camera.getWorldDirection(out);
			diry.crossVectors(this.#camera.up, dir);
			return dir.multiplyScalar(forward).add(diry.multiplyScalar(side));
		};
	});

	/**
	 *
	 * @param forward How many meters to translate in the forward direction
	 * @param side How many meters to translate in the right-side direction
	 * @param out Vector to return the result in (to avoid allocations)
	 * @returns A vector corresponding to a horizontal camera movement.
	 */
	#getHorizontalTranslation = memberWithPrivateData(() => {
		// Avoid new allocations by reusing the same vector
		const diry = new Vector3();

		return (forward: number, side: number, out: Vector3): Vector3 => {
			const dir = this.#camera.getWorldDirection(out);
			if (this.#upDirection === Y_UP) {
				dir.y = 0;
			} else {
				dir.z = 0;
			}
			dir.normalize();

			diry.crossVectors(this.#camera.up, dir);

			return dir.multiplyScalar(forward).add(diry.multiplyScalar(side));
		};
	});

	/**
	 * @param y amount of movement.
	 * @param out Vector to return the result in (to avoid allocations)
	 * @returns A vector corresponding to a vertical camera movement.
	 */
	#getVerticalTranslation(y: number, out: Vector3): Vector3 {
		return out.copy(this.#camera.up).multiplyScalar(y);
	}

	/**
	 * Computes the #keyBoardInertia form the current users keyboard inputs.
	 *
	 * @param deltaTime The deltaTime of the current frame
	 */
	private computeKeyboardMotionStateFromInputs = memberWithPrivateData(() => {
		// Avoid allocations by reusing the same vectors
		const vecHoriz = new Vector3();
		const vecVert = new Vector3();

		return (deltaTime: number): void => {
			// Convert from keyboard-space to world-space
			const pivotDistanceMultiplier = this.computePivotDistanceFactor();

			let flyFowardAmount = 0;
			if (this.#forwardMovement === ForwardMovement.Walk) {
				// When entering here, WASD keys translate the camera along the horizontal plane
				this.#getHorizontalTranslation(this.#keyTranslation.z, this.#keyTranslation.x, vecHoriz);
			} else if (this.hasValidTarget) {
				// When entering here, WS keys reduce or increase the distance to the rotation pivot
				flyFowardAmount = -this.#keyTranslation.z;
				this.#getFlyTranslation(0, this.#keyTranslation.x, vecHoriz);
			} else {
				// When entering here, WASD keys translate the camera along the (camera view, camera right) plane
				this.#getFlyTranslation(this.#keyTranslation.z, this.#keyTranslation.x, vecHoriz);
			}
			this.#getVerticalTranslation(this.#keyTranslation.y, vecVert);

			const sprinting = this.#keyboardEvents.isCodePressed(this.keyboardBindings.movement.sprint);

			const speedFactor = sprinting ? this.keyboardSettings.sprintSpeedMultiplier : 1;

			const accelerationFactor = sprinting ? this.keyboardSettings.sprintAccelerationMultiplier : 1;

			const maxSpeed = this.keyboardSettings.movementSpeed * speedFactor * pivotDistanceMultiplier;

			// accelerate keyboard motion state towards target speed
			this.#keyboardMotionState.accelerateMovementSpeedTowards(
				vecHoriz.add(vecVert).normalize().multiplyScalar(maxSpeed),
				this.keyboardSettings.movementAcceleration * pivotDistanceMultiplier * accelerationFactor,
				deltaTime,
			);

			this.#keyboardMotionState.acceleratePivotDistanceTowards(
				flyFowardAmount * maxSpeed,
				this.keyboardSettings.movementAcceleration * pivotDistanceMultiplier * accelerationFactor,
				deltaTime,
			);

			// If the user was sprinting, and the sprint key is released before the movement key,
			// the user does not want to decelerate slowly.
			this.#keyboardMotionState.movementSpeed.clampLength(0, maxSpeed);

			this.#keyboardMotionState.accelerateRotationSpeedTowards(
				this.#keyRotation.x * this.keyboardSettings.rotationSpeed * this.#rotationMultiplier,
				this.#keyRotation.y * this.keyboardSettings.rotationSpeed,
				this.keyboardSettings.rotationAcceleration,
				deltaTime,
			);
		};
	});

	/** Updates the movement information from the currently pressed keys */
	private updateMovementFromKeys(): void {
		this.#keyTranslation.set(
			this.#keyboardEvents.twoCodesToAxis(
				this.keyboardBindings.movement.left,
				this.keyboardBindings.movement.right,
			),
			this.#keyboardEvents.twoCodesToAxis(this.keyboardBindings.movement.up, this.keyboardBindings.movement.down),
			this.#keyboardEvents.twoCodesToAxis(
				this.keyboardBindings.movement.forward,
				this.keyboardBindings.movement.backward,
			),
		);

		this.#keyRotation.set(
			this.#keyboardEvents.twoCodesToAxis(
				this.keyboardBindings.rotation.left,
				this.keyboardBindings.rotation.right,
			),
			this.#keyboardEvents.twoCodesToAxis(this.keyboardBindings.rotation.up, this.keyboardBindings.rotation.down),
		);
	}

	/** Updates the camera pose with the interactor's internal members. */
	private updateCamera = memberWithPrivateData(() => {
		// Reuse vector to reduce allocations
		const dir = new Vector3();

		return (): void => {
			if (!this.enabled) return;

			spherical2cartesian(
				{ theta: this.#currCamera.theta, phi: this.#currCamera.phi },
				this.#upDirection === Y_UP,
				dir,
			);
			this.#camera.position.copy(this.#currCamera.rotationPivot);
			this.#camera.position.add(dir.multiplyScalar(-this.#currCamera.camPivotDistance));

			if (this.#currCamera.camPivotDistance < PIVOT_DISABLED_DISTANCE) {
				this.removeTarget(true);
			} else {
				this.#camera.lookAt(this.#currCamera.rotationPivot);
			}
		};
	});

	/** @returns the reference height used for computation, the viewport one if defined or the canvas one */
	get #referenceHeight(): number {
		return this.viewportHeight ?? this.#touchEvents.elementHeight;
	}

	/**
	 * This function computes the constant that converts pixel movements into meter movements
	 * needed for computation of touch interactions such as pinch and dolly and two touch translate.
	 * Adjust it to make sure a minimum default is accounted when the camera is super close to the pivot
	 * and return the updated factor
	 *
	 * @returns a factor to use to map pixels to meters for movements
	 */
	private computePix2MeterFactor(): number {
		const pix2meters = pixels2m(1, this.#camera, this.#referenceHeight, this.#currCamera.camPivotDistance);
		return clamp(pix2meters, MIN_PIX2M_FACTOR, MAX_PIX2M_FACTOR);
	}

	/** @returns the scaling factor accounting for the pivot distance in keyboard controls */
	private computePivotDistanceFactor(): number {
		return clamp(
			this.#currCamera.camPivotDistance * DISTANCE_MULTIPLIER,
			MIN_PIVOT_DISTANCE_FACTOR,
			MAX_PIVOT_DISTANCE_FACTOR,
		);
	}

	/** @returns the current pixels/radians ratio, useful to convert pointer movements into rotations */
	#computePix2Radians(): number {
		return this.#currCamera.camPivotDistance <= PIVOT_DISABLED_DISTANCE
			? pixels2radians(this.#camera, this.#referenceHeight)
			: 5 / this.#referenceHeight;
	}

	/**
	 *
	 * @param pp The touch event that moved
	 */
	private onSingleTouchMove(pp: PointerCoords): void {
		const pix2radians = this.#computePix2Radians();
		const m = pp.getMovement(pp.deltaTime);
		this.#currCamera.theta += -m.x * pix2radians * this.#rotationMultiplier;
		this.#currCamera.phi += -m.y * pix2radians * this.#rotationMultiplier;
		this.#currCamera.phi = clamp(this.#currCamera.phi, this.minPhi, this.maxPhi);

		this.userInteracted.emit();
	}

	/**
	 * On double touch translate, we translate the camera along the projection
	 * of its focal axis on the horizontal plane.
	 *
	 * @param dt The double touch event
	 */
	private onDoubleTouchTranslate = memberWithPrivateData(() => {
		const translation = new Vector2();
		const horizontalTranslation = new Vector3();

		return (dt: DoubleTouch): void => {
			const p2m = this.computePix2MeterFactor();
			translation.addVectors(dt.movement1, dt.movement2);
			translation.multiplyScalar(0.5);
			this.#currCamera.rotationPivot.add(
				this.#getHorizontalTranslation(translation.y * p2m, translation.x * p2m, horizontalTranslation),
			);

			this.userInteracted.emit();
		};
	});

	/**
	 * On double touch rotate, we rotate the model around the vertical direction.
	 *
	 * @param dt The double touch event
	 */
	private onDoubleTouchRotate(dt: DoubleTouch): void {
		this.#currCamera.theta +=
			this.#rotationMultiplier *
			(this.#camera.up.dot(this.#camera.getWorldDirection(new Vector3())) <= 0
				? dt.rotationAngle
				: -dt.rotationAngle);

		this.userInteracted.emit();
	}

	/**
	 * If the user does a pinch and zoom gesture, we do not zoom
	 * but we translate the camera along its view direction (dollying)
	 *
	 * @param dt The double touch event
	 */
	private onPinchAndZoom(dt: DoubleTouch): void {
		if (
			this.#camera instanceof PerspectiveCamera &&
			pinchAndZoomPerspectiveCamera(this.#camera, dt.ratio, this.#defaultPerspFov)
		) {
			return;
		}
		const p2m = this.computePix2MeterFactor();
		const dNow = dt.mainTouch.position.distanceTo(dt.secondTouch.position);
		const dPrev = dt.prevPos1.distanceTo(dt.prevPos2);
		const dollyForward = (dNow - dPrev) * p2m;
		this.#currCamera.camPivotDistance -= dollyForward;

		this.userInteracted.emit();
	}

	/**
	 * @returns The distance in meters to the closest obstacle, or to the pivot if not obstacle is defined
	 */
	private distanceToClosestObstacle(): number {
		if (this.obstacles) {
			this.#raycaster.set(this.camera.position, this.camera.getWorldDirection(this.#raycaster.ray.direction));
			const hits = this.#raycaster.intersectObjects(this.obstacles);
			for (const hit of hits) {
				if (hit.distance > PASSTHROUGH_DISTANCE) {
					return hit.distance;
				}
			}
		}
		if (this.hasValidTarget && this.#currCamera.camPivotDistance > PASSTHROUGH_DISTANCE) {
			return this.#currCamera.camPivotDistance;
		}
		return NO_OBSTACLE_DISTANCE;
	}

	/**
	 * @param deltaY How much the mouse wheel was scrolled
	 */
	private onMouseWheelTranslate = memberWithPrivateData(() => {
		const camDir = new Vector3();

		return (deltaY: number) => {
			this.#lastCamera.assign(this.#currCamera);
			const d = this.distanceToClosestObstacle();
			const movement = (d * deltaY * this.wheelSensitivity) / WHEEL_SENSITIVITY_FACTOR;
			if (this.hasValidTarget) {
				this.#currCamera.camPivotDistance += movement;
				this.#movementType = MovementType.Dollying;
			} else {
				this.#currCamera.rotationPivot.add(this.camera.getWorldDirection(camDir).multiplyScalar(-movement));
				this.#movementType = MovementType.VerticalTranslation;
			}
		};
	});

	/**
	 * @param ev the wheel event
	 */
	private onWheel(ev: WheelEvent): void {
		if (this.#camera instanceof PerspectiveCamera) {
			if (!zoomPerspectiveCamera(this.#camera, ev.deltaY, this.#defaultPerspFov)) {
				this.onMouseWheelTranslate(ev.deltaY);
			}
		} else {
			// If the camera is orthographic, it does not make sense to translate along the focal axis.
			// Instead, we can change the vertical ortho size on mouse wheel.
			zoomOrthocamera(this.#camera, ev.deltaY);
		}
		this.userInteracted.emit();
	}

	/**
	 *
	 * @param ev The mouse down event
	 */
	private onMouseDown(ev: MouseEvent): void {
		if (!isMouseButton(ev.button)) return;
		this.#movementType = this.mouseBindings[ev.button];
		this.#lastMousePos.set(ev.clientX, ev.clientY);
		this.#lastCamera.assign(this.#currCamera);

		// Stop all existing mouse inertia
		this.#mouseMotionState.reset();
	}

	/**
	 * @param ev The mouse up event
	 */
	private onMouseUp(ev: MouseEvent): void {
		if (ev.buttons === 0) {
			this.#mouseMotionState.assign(this.#actualMotionState);
			// Subtract all the inertia that has been applied by other input methods
			this.#mouseMotionState.sub(this.#combinedMotionState);
		}
	}

	/**
	 *
	 * @param ev The mouse drag event
	 */
	private onMouseDrag(ev: MouseEvent): void {
		// How much did the mouse move since last time?
		// We can't use `ev.movementX/Y`, because the units differ by browser and OS
		const deltaX = ev.clientX - this.#lastMousePos.x;
		const deltaY = ev.clientY - this.#lastMousePos.y;

		switch (this.#movementType) {
			case MovementType.Rotating:
				this.onMouseRotate(deltaX, deltaY);
				break;
			case MovementType.HorizontalTranslation:
				this.onMouseWalk(deltaX, deltaY);
				break;
			case MovementType.CameraPan:
				this.onMousePan(deltaX, deltaY);
				break;
			case MovementType.Dollying:
				this.onMouseDolly(deltaY);
				break;
			case MovementType.VerticalTranslation:
				this.onMouseVerticalMove(deltaY);
				break;
		}

		this.#lastMousePos.set(ev.clientX, ev.clientY);

		this.userInteracted.emit();
	}

	/**
	 *
	 * @param x moved X coordinate
	 * @param y moved Y coordinate
	 */
	private onMouseRotate(x: number, y: number): void {
		const p2r = this.#computePix2Radians();
		this.#currCamera.theta += -x * p2r * this.#rotationMultiplier;
		this.#currCamera.phi += -y * p2r * this.#rotationMultiplier;
		this.#currCamera.phi = clamp(this.#currCamera.phi, this.minPhi, this.maxPhi);
	}

	/**
	 *
	 * @param x moved X coordinate
	 * @param y moved Y coordinate
	 */
	private onMouseWalk = memberWithPrivateData(() => {
		const horiz = new Vector3();

		return (x: number, y: number): void => {
			const p2m = this.computePix2MeterFactor();
			const dy = y * p2m;
			const dx = x * p2m;
			this.#currCamera.rotationPivot.add(this.#getHorizontalTranslation(dy, dx, horiz));
		};
	});

	/**
	 * Manage panning action given current mouse position
	 *
	 * @param x The x position of the mouse (in pixels)
	 * @param y The y position of the mouse (in pixels)
	 */
	private onMousePan = memberWithPrivateData(() => {
		const xAxis = new Vector3();
		const yAxis = new Vector3();

		return (x: number, y: number): void => {
			const p2m = this.computePix2MeterFactor();
			const me = this.#camera.matrixWorld.elements;
			xAxis.set(me[0], me[1], me[2]);
			yAxis.set(me[4], me[5], me[6]);
			const dx = -x * p2m;
			const dy = y * p2m;
			this.#currCamera.rotationPivot.addScaledVector(xAxis, dx);
			this.#currCamera.rotationPivot.addScaledVector(yAxis, dy);
		};
	});

	/**
	 *
	 * @param y The number of pixels the mouse moved since last time.
	 */
	private onMouseVerticalMove(y: number): void {
		const p2m = this.computePix2MeterFactor();
		this.#currCamera.rotationPivot.addScaledVector(this.#camera.up, y * p2m);
	}

	/**
	 *
	 * @param y new mouse Y coordinate
	 */
	private onMouseDolly(y: number): void {
		const p2m = this.computePix2MeterFactor();
		const dy = y * p2m;
		this.#currCamera.camPivotDistance += dy;
	}

	/** Currently accumulated time for the actual movement state calculation */
	#timeSinceActualMotionStateUpdate = 0;

	/**
	 * @param deltaTime Time interval on which the speed is computed.
	 */
	private storeActualMotionState(deltaTime: number): void {
		this.#timeSinceActualMotionStateUpdate += deltaTime;

		// Only update the motion state after a given time to make this frame rate independent.
		// Otherwise, on high frame rates, it can happen that the mouse up event triggers multiple
		// frames after the last drag event, resulting in an abrupt stop of the inertial movement.
		if (this.#timeSinceActualMotionStateUpdate > ACTUAL_MOTION_SMOOTHING_TIME) {
			const invD = 1 / this.#timeSinceActualMotionStateUpdate;
			this.#actualMotionState.camPivotDistanceSpeed =
				(this.#currCamera.camPivotDistance - this.#lastCamera.camPivotDistance) * invD;
			this.#actualMotionState.movementSpeed.subVectors(
				this.#currCamera.rotationPivot,
				this.#lastCamera.rotationPivot,
			);
			this.#actualMotionState.movementSpeed.multiplyScalar(invD);
			this.#actualMotionState.phiSpeed = (this.#currCamera.phi - this.#lastCamera.phi) * invD;
			this.#actualMotionState.thetaSpeed = (this.#currCamera.theta - this.#lastCamera.theta) * invD;
			this.#lastCamera.assign(this.#currCamera);

			this.#timeSinceActualMotionStateUpdate = 0;
		}
	}

	/** Check whether the camera is still or translating. Emits a signal when the camera start a translation. */
	#checkIsCameraMoving(): void {
		const isMoving = this.#currCamera.moved(this.#lastCamera);
		if (isMoving && !this.#isCameraMoving) {
			this.cameraStartedTranslating.emit();
		} else if (!isMoving && this.#isCameraMoving) {
			this.cameraStoppedTranslating.emit();
		}
		this.#isCameraMoving = isMoving;
	}

	/**
	 * @param deltaTime Time interval on which the inertial movement is computed.
	 */
	private applyInertialMovement(deltaTime: number): void {
		this.#currCamera.camPivotDistance += this.#combinedMotionState.camPivotDistanceSpeed * deltaTime;

		this.#currCamera.rotationPivot.addScaledVector(this.#combinedMotionState.movementSpeed, deltaTime);

		this.#currCamera.phi += this.#combinedMotionState.phiSpeed * deltaTime;
		this.#currCamera.theta += this.#combinedMotionState.thetaSpeed * deltaTime;
		this.#currCamera.phi = clamp(this.#currCamera.phi, this.minPhi, this.maxPhi);

		this.#checkIsCameraMoving();
	}

	/**
	 * Calculates the control's inertia and updates the camera's position.
	 *
	 * @param deltaTime Time elapsed since last interactor update.
	 */
	public update(deltaTime: number): void {
		if (!this.#enabled) return;

		// calculate target speed or move camera directly
		this.computeKeyboardMotionStateFromInputs(deltaTime);

		// combine mouse + keyboard + touch motion
		this.#combinedMotionState.assign(this.#mouseMotionState);
		this.#combinedMotionState.add(this.#keyboardMotionState);
		this.#combinedMotionState.add(this.#touchMotionState);

		this.applyInertialMovement(deltaTime);
		this.updateCamera();

		this.storeActualMotionState(deltaTime);

		this.#mouseMotionState.applyFriction(
			this.mouseSettings.movementInertia,
			this.mouseSettings.rotationInertia,
			deltaTime,
		);
	}

	/** */
	private attachListeners(): void {
		this.#touchEvents.singleTouchMoved.on(this.onSingleTouchMove);
		this.#touchEvents.doubleTouchTranslated.on(this.onDoubleTouchTranslate);
		this.#touchEvents.doubleTouchRotated.on(this.onDoubleTouchRotate);
		this.#touchEvents.pinchAndZoomed.on(this.onPinchAndZoom);
		this.#touchEvents.mousePressed.on(this.onMouseDown);
		this.#touchEvents.mouseReleased.on(this.onMouseUp);
		this.#touchEvents.mouseDragged.on(this.onMouseDrag);
		this.#touchEvents.mouseWheel.on(this.onWheel);

		this.#keyboardEvents.keyChanged.on(this.updateMovementFromKeys);
	}

	/** */
	private detachListeners(): void {
		this.#touchEvents.singleTouchMoved.off(this.onSingleTouchMove);
		this.#touchEvents.doubleTouchTranslated.off(this.onDoubleTouchTranslate);
		this.#touchEvents.doubleTouchRotated.off(this.onDoubleTouchRotate);
		this.#touchEvents.pinchAndZoomed.off(this.onPinchAndZoom);
		this.#touchEvents.mousePressed.off(this.onMouseDown);
		this.#touchEvents.mouseReleased.off(this.onMouseUp);
		this.#touchEvents.mouseDragged.off(this.onMouseDrag);
		this.#touchEvents.mouseWheel.off(this.onWheel);

		this.#keyboardEvents.keyChanged.off(this.updateMovementFromKeys);
	}

	/**
	 * Enables / disables this controller
	 */
	set enabled(val: boolean) {
		this.#touchEvents.enabled = val;
		this.#enabled = val;

		if (val) {
			// Sync the interactor with the camera because
			// an animation may have changed the camera pose
			this.updateFromCamera();
		} else {
			// reset motion state to avoid motion on resume
			this.#mouseMotionState.reset();
			this.#keyboardMotionState.reset();
			this.#touchMotionState.reset();
			this.#actualMotionState.reset();
		}
	}

	/** @returns true if this controller is enabled */
	get enabled(): boolean {
		return this.#enabled;
	}

	/** @returns true if we have a valid target */
	get hasValidTarget(): boolean {
		return this.#currCamera.camPivotDistance > PIVOT_DISABLED_DISTANCE;
	}

	/** @returns the target point around which the rotation is orbiting. The target point is always placed along the camera's focal axis. */
	get target(): Vector3 | undefined {
		if (!this.hasValidTarget) return;
		return this.#currCamera.rotationPivot;
	}

	/** Sets the target point around which the rotation is orbiting. */
	set target(t: Vector3 | undefined) {
		if (!t) {
			this.removeTarget();
			return;
		}
		this.#camera.lookAt(t);
		const distance = this.camera.position.distanceTo(t);
		this.#currCamera.camPivotDistance = distance;
		this.#currCamera.rotationPivot = t.clone();
		const dir = this.#camera.getWorldDirection(new Vector3());
		this.camera.position.copy(t.clone().sub(dir.clone().multiplyScalar(distance)));
		const angles = cartesian2spherical(dir, this.#upDirection === Y_UP);
		this.#currCamera.phi = angles.phi;
		this.#currCamera.theta = angles.theta;
	}

	/**
	 * Compute the best position for the camera if the change the pivot, used to animate
	 * the camera before changing the pivot
	 *
	 * @param target The new desired target position to use as a pivot
	 * @returns The position/quaternion for the best camera to look at the new pivot point
	 */
	computeBestCameraPoseForTarget(target: Vector3): { position: Vector3; quaternion: Quaternion } {
		const dir = this.camera.getWorldDirection(new Vector3());
		const currDistance = this.hasValidTarget ? this.#currCamera.camPivotDistance : this.focusDistance;
		const mayDistance = this.camera.position.distanceTo(target);
		const distance = Math.min(currDistance, mayDistance, this.focusDistance);
		const position = target.clone().sub(dir.multiplyScalar(distance));
		const quaternion = this.camera.quaternion.clone();
		return { position, quaternion };
	}

	/**
	 * Hide the target placing it a PIVOT_DISABLE_DISTANCE from the camera and notify the targetDismissed event
	 *
	 * @param force Force the removal of the target even if it's already invalid, will always trigger the event
	 */
	removeTarget(force = false): void {
		if (!force && !this.hasValidTarget) {
			return;
		}
		const dir = this.camera.getWorldDirection(new Vector3());
		this.#currCamera.rotationPivot
			.copy(this.#camera.position)
			.add(dir.normalize().multiplyScalar(PIVOT_DISABLED_DISTANCE));
		this.#currCamera.camPivotDistance = PIVOT_DISABLED_DISTANCE;
		this.targetDismissed.emit();
	}

	/**
	 * @returns the handler of the touch events. Useful if another class wants to be
	 * notified e.g. when a tap gesture happens.
	 */
	get touchEvents(): TouchEvents {
		return this.#touchEvents;
	}

	/** @returns The camera that the controls are handling. */
	get camera(): SupportedCamera {
		return this.#camera;
	}

	/** Sets the camera that the controls are handling. */
	set camera(c: SupportedCamera) {
		this.#camera = c;
		this.updateFromCamera();
	}

	/**
	 * Attach this controls to an element to receive events from
	 *
	 * @param target The element to listen for events
	 */
	attach(target: HTMLElement): void {
		// to receive keyboard events.
		target.tabIndex = -1;
		this.#touchEvents.attach(target);
		this.#keyboardEvents.attach(target);
	}

	/**
	 * If attached to an element, detach all event listeners
	 */
	detach(): void {
		this.#keyboardEvents.detach();
		this.#touchEvents.detach();
	}

	/** Disposes all resources and releases all event listeners registered by this object. */
	dispose(): void {
		this.detach();
		this.detachListeners();
		this.#touchEvents.dispose();
	}

	/**
	 * @returns whether the interactor rotates the view direction (like a first-person-shooter)
	 * or whether the interactor rotates the model (like a 360 image viewer)
	 */
	get pointerRotates(): PointerRotates {
		return this.#pointerRotates;
	}

	/** Sets the PointerRotates property. */
	set pointerRotates(p: PointerRotates) {
		this.#pointerRotates = p;
		this.#rotationMultiplier = this.#pointerRotates === PointerRotates.ViewDirection ? 1 : -1;
	}

	/** @returns whether a forward movement results in a fly or walk translation */
	get forwardMovement(): ForwardMovement {
		return this.#forwardMovement;
	}

	/** Sets whether a forward movement results in a fly or walk translation */
	set forwardMovement(m: ForwardMovement) {
		this.#forwardMovement = m;
	}

	/** Ensures that this interactor's internal members describe the same pose as the camera member. */
	updateFromCamera = memberWithPrivateData(() => {
		const cameraWorldDir = new Vector3();
		const cam2pivot = new Vector3();

		return (): void => {
			if (this.#camera.up.z === 1) {
				this.#upDirection = Z_UP;
			} else if (this.#camera.up.y === 1) {
				this.#upDirection = Y_UP;
			} else {
				console.warn("WalkOrbitControls: camera up direction not supported.");
			}
			this.#camera.getWorldDirection(cameraWorldDir);
			this.#currCamera.rotationPivot.copy(this.#camera.position);
			cam2pivot.copy(cameraWorldDir);
			cam2pivot.multiplyScalar(this.#currCamera.camPivotDistance);
			this.#currCamera.rotationPivot.add(cam2pivot);
			const angles = cartesian2spherical(cameraWorldDir, this.#upDirection === Y_UP);
			this.#currCamera.phi = angles.phi;
			this.#currCamera.theta = angles.theta;
		};
	});

	/** @returns whether the camera is translating or not. */
	get isCameraMoving(): boolean {
		return this.#isCameraMoving;
	}

	/** @returns the default FOV this interactor applies to the perspective camera */
	get defaultPerspFov(): number {
		return this.#defaultPerspFov;
	}

	/** Sets the default FOV this interactor applies to the perspective camera */
	set defaultPerspFov(fov: number) {
		if (fov > DEFAULT_MIN_PERSP_FOV && fov < DEFAULT_MAX_PERSP_FOV) {
			this.#defaultPerspFov = fov;
		}
	}
}
