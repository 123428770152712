import { BackendJobId } from "./registration-api-types";

/** The response of the backend with the initiated backend job. */
export interface RegistrationStartedJobResponse {
  /** The identifier of the backend job running the registration. */
  jobId: BackendJobId;
}

/**
 * @param response The response of the backend.
 * @returns `true`, if the response has the expected format, else `false`.
 */
export function isRegistrationStartedJobResponse(
  response: unknown,
): response is RegistrationStartedJobResponse {
  if (!response || typeof response !== "object") {
    return false;
  }
  const toCheck: Partial<RegistrationStartedJobResponse> = response;

  return typeof toCheck.jobId === "string";
}

export type CancelRegistrationResponse = {
  /** The ID of the registration job that was terminated. */
  terminatedJobId: string;
};

/**
 * @param response The response to validate.
 * @returns `true`, if the response is the expected cancellation response, else `false`.
 */
export function isCancelRegistrationResponse(
  response: unknown,
): response is CancelRegistrationResponse {
  if (!response || typeof response !== "object") {
    return false;
  }
  const toCheck: Partial<CancelRegistrationResponse> = response;

  return typeof toCheck.terminatedJobId === "string";
}

/** The response of the backend with the initiated backend job. */
export interface CancelRegistrationMultiCloudResponse {
  /** The identifier of the backend job running the registration. */
  successful: BackendJobId[];
  errors: BackendJobId[];
}

/**
 * @param response The response to validate.
 * @returns `true`, if the response is the expected cancellation response, else `false`.
 */
export function isCancelRegistrationMultiCloudResponse(
  response: unknown,
): response is CancelRegistrationMultiCloudResponse {
  if (!response || typeof response !== "object") {
    return false;
  }
  const toCheck: Partial<CancelRegistrationMultiCloudResponse> = response;

  return Array.isArray(toCheck.successful) && Array.isArray(toCheck.errors);
}
