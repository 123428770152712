import { useMemo } from "react";
import { Frustum, Plane } from "three";

/**
 * @returns a Frustum for a set of clipping planes (or undefined if clippingPlanes are not defined)
 * @param clippingPlanes the clipping planes to create a frustum for
 */
export function useClipFrustum(clippingPlanes?: Plane[]): Frustum | undefined {
  return useMemo(() => {
    const cp = clippingPlanes;
    return cp?.length === 6
      ? new Frustum(cp[0], cp[1], cp[2], cp[3], cp[4], cp[5])
      : undefined;
  }, [clippingPlanes]);
}
