import { TypedEvent } from "@faro-lotv/foundation";
import { Camera, Texture, Vector2 } from "three";
import { PanoTileMaterial } from "../Materials";
import { EquirectangularDepthImage } from "../Pano";
import { Pano } from "../Pano/Pano";
import { TiledPano } from "../Pano/TiledPano";
import { ImageTree } from "./ImageTree";
import { LodGroup } from "./LodGroup";
import { LodPanoTextureProvider } from "./LodPanoTextureProvider";
import { WeightedNode } from "./VisibleNodeStrategy";
import { DefaultVisibleTilesStrategy } from "./VisibleTilesStrategy";

/**
 * A class capable of rendering a level-of-detail tiled pano image.
 */
export class LodPano extends LodGroup<Texture> implements Pano {
	/** Notify when the depths have changed */
	depthsChanged = new TypedEvent<void>();

	/** Notify when the color texture changed */
	textureChanged = new TypedEvent<void>();

	/**
	 * The total number of textures that are currently being rendered.
	 */
	#texturesInGPU = 0;

	/** The collection of pano tiles */
	#tiledPano: TiledPano;

	/** The object that will provide the textures from the ImageTree */
	#textureProvider: LodPanoTextureProvider;

	/** Whether to show the depth image*/
	#showDepthImage = false;

	/** The strategy to decide what nodes are visible from a specific camera */
	visibleTilesStrategy = new DefaultVisibleTilesStrategy();

	/** @returns the ImageTree defining the different tiles for this LodPano*/
	get tree(): ImageTree {
		return this.#textureProvider.tree;
	}

	/** @returns the object that will provide the textures from the ImageTree */
	get textureProvider(): LodPanoTextureProvider {
		return this.#textureProvider;
	}

	/**
	 * Constructs an object responsible of rendering a Lod Tiled Pano
	 *
	 * @param source The lod tree or texture provider used to get textures
	 * @param overviewTexture Optional texture used as overview
	 */
	constructor(source: ImageTree | LodPanoTextureProvider, overviewTexture?: Texture) {
		super();
		this.#textureProvider = source instanceof ImageTree ? new LodPanoTextureProvider(source) : source;
		this.#tiledPano = new TiledPano(overviewTexture);
		this.#textureProvider.requestsNodes(this, [0]);
		this.add(this.#tiledPano);
		this.#tiledPano.depthsChanged.pipe(this.depthsChanged);
		this.#tiledPano.textureChanged.pipe(this.textureChanged);
		this.matrixWorldNeedsUpdate = true;
		this.visibleTilesStrategy.minPixelSize = 200;
	}

	/**
	 * @returns a new LodPano rendering the same tree
	 */
	createView(): LodPano {
		return new LodPano(this.#textureProvider);
	}

	/** @inheritdoc */
	protected computeVisibleNodes(camera: Camera, screenSize: Vector2): WeightedNode[] {
		// when showing the depth image, only the overview tile is visible.
		return this.#showDepthImage
			? [{ id: 0, weight: -1 }]
			: this.visibleTilesStrategy.compute(this.matrixWorld, this.tree, camera, screenSize);
	}

	/** @inheritdoc */
	protected requestNodes(nodes: WeightedNode[]): void {
		this.#textureProvider.requestsNodes(
			this,
			nodes.map((x) => x.id),
		);
	}

	/** @inheritdoc */
	setNodeVisible(nodeIdx: number, texture: Texture): void {
		const node = this.tree.getNode(nodeIdx);
		this.#tiledPano.addTile(texture, node.rect, node.depth);
		this.#tiledPano.textureChanged.emit();
		this.#texturesInGPU++;
	}

	/** @inheritdoc */
	protected setNodeNotVisible(nodeIdx: number, texture: Texture): void {
		this.#tiledPano.removeTile(texture);
		this.#tiledPano.textureChanged.emit();
		this.#texturesInGPU--;
	}

	/** @inheritdoc */
	protected cacheOrDisposeNodes(): void {
		// For LodPano we dispose all the texture when all the LodPano using the same
		// LodPanoTextureProvider are disposed and not when a tile goes offscreen
	}

	/** @inheritdoc */
	protected getNodeInGPU(nodeIdx: number): Texture | undefined {
		// when showing the depth image, only the overview tile is present in GPU
		if (this.#showDepthImage && nodeIdx === 0) {
			return this.#tiledPano.overviewTile.texture;
		}
		const texture = this.tree.getNode(nodeIdx).image;
		if (texture && this.#tiledPano.hasTile(texture)) {
			return texture;
		}
	}

	/** @inheritdoc */
	protected getNodeInMemory(nodeIdx: number): Texture | undefined {
		return this.tree.getNode(nodeIdx).image;
	}

	/** @returns how many textures are being rendered right now. */
	get totTexturesInGPU(): number {
		return this.#texturesInGPU;
	}

	/** Disposes the LodPano and its GPU resources. */
	dispose(): void {
		this.#tiledPano.dispose(false);
		this.#tiledPano.texture?.dispose();
		this.#textureProvider.removeClient(this);
	}

	/** @returns The material used to render the pano tiles. */
	get material(): PanoTileMaterial {
		return this.#tiledPano.material;
	}

	/** @returns the width of this pano */
	get width(): number {
		return this.#tiledPano.width;
	}

	/** @returns the height of this pano */
	get height(): number {
		return this.#tiledPano.height;
	}

	/** @returns the opacity of this pano */
	get opacity(): number {
		return this.#tiledPano.opacity;
	}

	/** Sets the opacity of this pano */
	set opacity(o: number) {
		this.#tiledPano.opacity = o;
	}

	/** @returns whether this pano is animating */
	get animating(): boolean {
		return this.#tiledPano.animating;
	}

	/** Sets whether this pano is animating */
	set animating(a: boolean) {
		this.#tiledPano.animating = a;
	}

	/** @returns the depth image associated to this pano */
	get depths(): EquirectangularDepthImage | undefined {
		return this.#tiledPano.depths;
	}

	/** @returns the TildePano data structure rendered by this object */
	get tiledPano(): TiledPano {
		return this.#tiledPano;
	}

	/** @returns Whether the color or the depth image is shown */
	get showDepthImage(): boolean {
		return this.#showDepthImage;
	}

	/** Sets Whether the color or the depth image is shown */
	set showDepthImage(s: boolean) {
		if (!this.#tiledPano.depths) {
			return;
		}
		this.#tiledPano.showDepthImage = s;
		this.#showDepthImage = s;
	}
}
