varying vec2 vUv;

uniform float uAltitudeOffset;

#include <clipping_planes_pars_vertex>

out vec4 mvPosition;

void main() {
    vUv = uv;
    mvPosition = modelViewMatrix * vec4(position, 1.f);

    // set clip position before offsetting
    #include <clipping_planes_vertex>

    // offset along the up direction
    vec4 upDirection = viewMatrix * vec4(0.f, 1.f, 0.f, 0.f);
    mvPosition += upDirection * uAltitudeOffset;
    gl_Position = projectionMatrix * mvPosition;
}
