import {
  FormControl,
  InputBaseProps,
  TextFieldProps as MUITextFieldProps,
  OutlinedInput,
  OutlinedInputProps,
  Stack,
  SxProps,
  Theme,
} from "@mui/material";
import { FunctionComponent, ReactNode, forwardRef } from "react";
import { neutral } from "../colors";
import { FontWeights } from "../faro-theme";
import {
  FormHelperTextDark,
  FormHelperTextLight,
  OutlinedInputDark,
  UnderlinedInput,
  UnderlinedInputDark,
} from "./base-inputs";
import { INPUT_DARK_COLORS, INPUT_LIGHT_COLORS } from "./input-colors";
import { FaroInputLabel } from "./input-label";
import { TagTypes } from "./input-label-tag";

/** Default width for the text field */
export const DEFAULT_FIELD_WIDTH = "13.75rem";

/** Common properties between TextField and MUITextField's InputProps */
type CommonTextFieldProps =
  | "disabled"
  | "readOnly"
  | "multiline"
  | "rows"
  | "fullWidth"
  | "placeholder"
  | "slots"
  | "inputProps"
  | "endAdornment"
  | "type"
  | "sx";

/** MUITextField InputProps minus the props already available in TextFieldProps. This is done to avoid having a property twice. */
type InputProps = Omit<
  MUITextFieldProps<"outlined" | "standard">["InputProps"],
  CommonTextFieldProps
>;

export type TextFieldVariant = "outlined" | "underlined";

export type TextFieldProps = Pick<InputBaseProps, CommonTextFieldProps> & {
  /** Label describing the content of the text field */
  label?: ReactNode;

  /** Additional tag to show next to the label, e.g. to mark a field as required */
  tag?: TagTypes;

  /** Help text to show to the user */
  helpText?: ReactNode;

  /** The initial input text */
  text?: string;

  /** Validation error to show to the user */
  error?: ReactNode;

  /** Whether a character counter should be shown */
  shouldShowCounter?: boolean;

  /** The maximum amount of characters to allow */
  maxCharacterCount?: number;

  /** Callback executed when the text changed */
  onTextChanged?(text: string): void;

  /** Callback executed when the user want to copy the field content */
  onCopy?: OutlinedInputProps["onCopy"];

  /** Callback called when the user focus on the text field */
  onFocus?: OutlinedInputProps["onFocus"];

  /** Callback called when the user press a key on the keyboard */
  onKeyDown?: OutlinedInputProps["onKeyDown"];

  /** Style properties for the FormControl */
  controlStyle?: SxProps<Theme>;

  /** Optional props assigned to the input element. This property allow to add adornments component to the TextField */
  InputProps?: InputProps;

  /** Ref to the input element */
  inputRef?: MUITextFieldProps["inputRef"];

  /**
   * The text field variant to use
   *
   * @default outlined
   */
  variant?: TextFieldVariant;

  /**
   * If true the input field will be focused when first shown.
   *
   * @default false
   */
  autoFocus?: boolean;

  /**
   * Enables dark mode styles
   *
   * @default false
   */
  dark?: boolean;
};

/** Maps a variant to a input component to use in the light theme */
const inputComponentMap: Record<
  TextFieldVariant,
  FunctionComponent<InputBaseProps>
> = {
  outlined: OutlinedInput,
  underlined: UnderlinedInput,
};

/** Maps a variant to a input component to use in the dark theme */
const inputComponentMapDark: Record<
  TextFieldVariant,
  FunctionComponent<InputBaseProps>
> = {
  outlined: OutlinedInputDark,
  underlined: UnderlinedInputDark,
};

/**
 * @returns An input element
 */
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  function TextField(
    {
      label,
      tag,
      helpText,
      text,
      error,
      shouldShowCounter,
      maxCharacterCount,
      onCopy,
      onFocus,
      onTextChanged,
      onKeyDown,
      controlStyle,
      InputProps,
      inputRef,
      disabled,
      readOnly,
      multiline,
      rows,
      fullWidth,
      placeholder,
      inputProps,
      type,
      variant = "outlined",
      dark = false,
      autoFocus = false,
      slots,
      endAdornment,
      sx,
    },
    ref,
  ): JSX.Element {
    const DEFAULT_WIDTH = fullWidth ? "100%" : DEFAULT_FIELD_WIDTH;
    const DEFAULT_MIN_WIDTH = "3.75rem";
    const MULTILINE_MIN_WIDTH = "16rem";

    const minWidth = multiline ? MULTILINE_MIN_WIDTH : DEFAULT_MIN_WIDTH;

    const colors = dark ? INPUT_DARK_COLORS : INPUT_LIGHT_COLORS;

    const InputVariant = dark
      ? inputComponentMapDark[variant]
      : inputComponentMap[variant];

    const FormHelperTextVariant = dark
      ? FormHelperTextDark
      : FormHelperTextLight;

    const hasTooManyCharacters =
      !!maxCharacterCount && (text?.length ?? 0) > maxCharacterCount;
    if (!error && hasTooManyCharacters) {
      error = "Character limit exceeded";
    }

    return (
      <FormControl
        fullWidth={fullWidth}
        disabled={disabled}
        sx={{ gap: 0.5, minWidth, width: DEFAULT_WIDTH, ...controlStyle }}
      >
        <FaroInputLabel
          tag={tag}
          disabled={disabled}
          error={!!error}
          dark={dark}
        >
          {label}
        </FaroInputLabel>

        <InputVariant
          slots={slots}
          inputProps={inputProps}
          ref={ref}
          inputRef={inputRef}
          placeholder={placeholder}
          type={type}
          value={text}
          multiline={multiline}
          rows={rows}
          disabled={disabled}
          readOnly={readOnly}
          endAdornment={endAdornment}
          fullWidth
          onCopy={onCopy}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onChange={(ev) => onTextChanged?.(ev.target.value)}
          autoFocus={autoFocus}
          error={!!error}
          sx={{
            input: {
              p: 3 / 2,
              cursor: disabled ? "not-allowed" : undefined,
              height: "auto",
            },

            "label + &.MuiInput-root": {
              // Avoids there being a gap caused by a mui style for underlined inputs
              mt: 0,
            },

            ...sx,
          }}
          {...InputProps}
        />

        {/* Helper text and counter below the input */}
        <Stack direction="row">
          {helpText && !error && (
            <FormHelperTextVariant
              sx={{
                m: 0,
                overflow: "auto",
                wordWrap: "break-word",
              }}
            >
              {helpText}
            </FormHelperTextVariant>
          )}

          {error && (
            <FormHelperTextVariant
              color={colors.error}
              sx={{
                m: 0,
                overflow: "auto",
                wordWrap: "break-word",
                fontWeight: FontWeights.SemiBold,
              }}
            >
              {error}
            </FormHelperTextVariant>
          )}

          {shouldShowCounter && (
            <FormHelperTextVariant
              color={dark ? neutral[300] : neutral[600]}
              error={hasTooManyCharacters}
              sx={{
                m: 0,
                ml: 1.5,
                "&.Mui-error": {
                  color: colors.error,
                  fontWeight: FontWeights.SemiBold,
                },

                // Making sure the counter is sticking to the bottom right of the input
                flex: 1,
                textAlign: "end",
              }}
            >
              {text?.length ?? 0}
              {maxCharacterCount ? `/${maxCharacterCount}` : ""}
            </FormHelperTextVariant>
          )}
        </Stack>
      </FormControl>
    );
  },
);
