import {
  Box,
  SxProps,
  Tooltip,
  Typography,
  TypographyProps,
} from "@mui/material";
import { ReactNode, useMemo } from "react";
import { useCheckForOverflow } from "../../hooks/use-check-for-overflow";
import { NO_TRANSLATE_CLASS } from "../../types";
import { FaroText, FaroTextProps } from "./faro-text/faro-text";

/**
 * @returns a text node that will not overflow, instead will truncate and show a tooltip
 * @deprecated Use TruncatedFaroText instead to get the proper FaroText styling for the content
 */
export function TruncatedText({
  children,
  ...rest
}: TypographyProps): JSX.Element {
  const { hasOverflown, checkForOverflow } = useCheckForOverflow();

  return (
    <Tooltip title={children} disableHoverListener={!hasOverflown}>
      <Typography
        onMouseEnter={(ev) => checkForOverflow(ev.currentTarget)}
        noWrap
        {...rest}
      >
        {children}
      </Typography>
    </Tooltip>
  );
}

type BaseTruncatedFaroTextProps = Omit<FaroTextProps, "title"> & {
  containerSx?: SxProps;
  children: React.ReactNode;
  hasOverflown: boolean;

  title?: React.ReactNode;
};

/**
 * @returns Base component for where a truncated text is needed so that we will be able to
 * reuse the styling and tooltip logic for different use truncation use cases
 */
function BaseTruncatedFaroText({
  children,
  containerSx,
  title,
  hasOverflown,
}: BaseTruncatedFaroTextProps): JSX.Element {
  return (
    <Tooltip title={hasOverflown && title}>
      <Box
        component="div"
        sx={{
          minWidth: 0,
          // Making sure that all wrappers are getting the style for truncating text
          // This is important, for example, for text wrapped within <NoTranslate>
          "& *": {
            overflow: "clip",
            textOverflow: "ellipsis",
          },
          ...containerSx,
        }}
      >
        {children}
      </Box>
    </Tooltip>
  );
}

export type TruncatedFaroTextProps = FaroTextProps & {
  /** Styling for the container div that is used to manage the truncation */
  containerSx?: SxProps;

  /** Optional component to show in the tooltip if it's different from the children */
  tooltip?: ReactNode;
};

/** @returns a text node that will not overflow, instead will truncate and show a tooltip */
export function TruncatedFaroText({
  children,
  containerSx,
  tooltip,
  ...rest
}: TruncatedFaroTextProps): JSX.Element {
  const { hasOverflown, checkForOverflow } = useCheckForOverflow();

  return (
    <BaseTruncatedFaroText
      title={tooltip ?? children}
      hasOverflown={hasOverflown}
      containerSx={containerSx}
      {...rest}
    >
      <Box
        component="div"
        onMouseEnter={(ev) => checkForOverflow(ev.currentTarget)}
      >
        {
          // Here we use a div because span components always have clientWidth=0 and since
          // this value is used in the checkForOverflow function, it would flag the component
          // as "overflown" even if it's not the case when `children` is a simple string.
        }
        <FaroText component="div" shouldElide {...rest}>
          {children}
        </FaroText>
      </Box>
    </BaseTruncatedFaroText>
  );
}

export type TruncatedFaroTextWithSuffixProps = Omit<
  FaroTextProps,
  "children"
> & {
  /** Whether the text should be translated */
  shouldTranslate?: boolean;
  /** How many characters should we calculate from the original text's end to create the suffix */
  lengthOfSuffix?: number;
  /** The full text from which we will generate the suffix if needed */
  text: string;
  /** Styling for the container div that is used to manage the truncation */
  containerSx?: SxProps;
};

/**
 * @returns a text node that will not overflow, instead will truncate and show a tooltip
 * it will also provide a suffix generated from the truncated text's last characters if needed
 */
export function TruncatedFaroTextWithSuffix({
  shouldTranslate,
  containerSx,
  lengthOfSuffix,
  text,
  ...rest
}: TruncatedFaroTextWithSuffixProps): JSX.Element {
  const { hasOverflown, checkForOverflow } = useCheckForOverflow();

  const { truncatedTextWithoutSuffix, generatedSuffix } = useMemo(() => {
    const truncatedTextWithoutSuffix = lengthOfSuffix
      ? text.slice(0, -lengthOfSuffix)
      : text;

    const generatedSuffix = lengthOfSuffix ? text.slice(-lengthOfSuffix) : "";

    return { truncatedTextWithoutSuffix, generatedSuffix };
  }, [lengthOfSuffix, text]);

  return (
    <BaseTruncatedFaroText
      hasOverflown={hasOverflown}
      containerSx={containerSx}
      title={text}
      {...rest}
    >
      <Box
        component="div"
        onMouseEnter={(ev) => checkForOverflow(ev.currentTarget)}
        className={shouldTranslate ? undefined : NO_TRANSLATE_CLASS}
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FaroText shouldElide {...rest}>
          {truncatedTextWithoutSuffix}
        </FaroText>
        <FaroText shouldElide={false} {...rest}>
          {generatedSuffix}
        </FaroText>
      </Box>
    </BaseTruncatedFaroText>
  );
}
