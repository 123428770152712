import { Box } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { LanguageCodes } from "../../types/localize-types";
import { orange } from "../colors";
import { FaroIconButton } from "../icon-button/faro-icon-button";
import {
  ChatIcon,
  FeedbackIcon,
  HelpCenterIcon,
  HelpIcon,
  VideoIcon,
} from "../icons";
import {
  FaroMenu,
  FaroMenuItem,
  FaroMenuProps,
  FaroMenuSection,
} from "../menu";

/**
 * Exports the name of all the names displayed in the help center.
 * Useful to identify which link was clicked and track it.
 * These are also labels used to track events in Amplitude: check
 * before changing them.
 */
export type HelpCenterMenuLinkNames =
  | "help-center"
  | "videos"
  | "send-feedback";

interface HelpCenterMenuProps extends Pick<FaroMenuProps, "anchorOrigin"> {
  /** The language to open the help center [NOTE for now the help desk contains only english articles on Sphere XG] */
  helpCenterLanguages?: LanguageCodes;

  /** Optional callback when the user clicks to open the help center */
  onOpenHelpCenter?(): void;

  /** Optional callback when the user clicks to close the help center */
  onCloseHelpCenter?(): void;

  /** Optional callback when the user clicks on any help link */
  onOpenHelpLink?(link: HelpCenterMenuLinkNames): void;

  /** Optional callback when the user clicks on Send Feedback */
  sendFeedback?(): void;

  /**
   * Optional callback when the user clicks to open the Intercom chat.
   * If not provided, the menu entry isn't shown.
   */
  openChat?(): void;

  /**
   * Optional number of unread messages in the Intercom chat. If a number greater than 0 is given,
   * notification icons get attached to the help button and the chat menu entry.
   * Only has an effect if openChat is also provided.
   */
  numUnreadMsg?: number;
}

/**
 * Renders a menu component that provides access to various resources, including the Help Center, walkthrough videos, and a feedback option.
 *
 * @returns JSX.Element The rendered menu component.
 */
export function HelpCenterMenu({
  helpCenterLanguages = LanguageCodes.English,
  sendFeedback,
  onOpenHelpCenter,
  onCloseHelpCenter,
  onOpenHelpLink,
  anchorOrigin = { horizontal: "left", vertical: "bottom" },
  openChat,
  numUnreadMsg,
}: HelpCenterMenuProps): JSX.Element {
  const ref = useRef<HTMLButtonElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  /**
   * Closes the help center menu.
   */
  const closeMenu = useCallback(() => {
    onCloseHelpCenter?.();
    setIsMenuOpen(false);
  }, [onCloseHelpCenter, setIsMenuOpen]);

  /**
   * Opens the Sphere XG Help Center documentation in a new browser tab.
   *
   * This method constructs the URL for the Help Center documentation based on the selected language
   * and then opens it in a new tab. After opening the Help Center, it closes the current menu.
   */
  const openHelpCenter = useCallback(() => {
    onOpenHelpLink?.("help-center");
    const url = `https://help.holobuilder.com/${helpCenterLanguages}/collections/9864205-sphere-xg`;

    window.open(url, "_blank");
    setIsMenuOpen(false);
  }, [helpCenterLanguages, onOpenHelpLink]);

  /**
   * Opens the Sphere-XG walkthrough videos page in a new browser tab.
   *
   * This method is designed to be triggered when a user clicks a specific button or menu item.
   * It constructs the URL for the Sphere-XG walkthrough videos on the HoloBuilder website and
   * opens it in a new browser tab.
   */
  const openWalkthroughVideo = useCallback(() => {
    onOpenHelpLink?.("videos");
    const url = "https://www.holobuilder.com/sphere-xg-walkthrough-videos/";

    window.open(url, "_blank");
    setIsMenuOpen(false);
  }, [onOpenHelpLink]);

  /**
   * Triggers the app defined send feedback workflow
   */
  const triggerSendFeedback = useCallback(() => {
    if (!sendFeedback) return;
    onOpenHelpLink?.("send-feedback");
    sendFeedback();
    setIsMenuOpen(false);
  }, [onOpenHelpLink, sendFeedback]);

  return (
    <>
      <FaroIconButton
        aria-label="expand help center menu"
        ref={ref}
        onClick={() => {
          onOpenHelpCenter?.();
          setIsMenuOpen(true);
        }}
        color={isMenuOpen ? "primary.main" : "secondary.main"}
        pressed={isMenuOpen}
      >
        <HelpIcon
          sx={{
            width: "24px",
            height: "24px",
          }}
        />

        {!!openChat && !!numUnreadMsg && numUnreadMsg > 0 && (
          <Box
            component="div"
            data-testid="unread-messages-icon-1"
            sx={{
              position: "absolute",
              top: "-2px",
              left: "14px",
              // !important is needed to override a competing style from MuiButtonBase.
              // We want the colored dot to be a child of FaroIconButton so that the mouse events are handled as desired.
              width: "12px !important",
              height: "12px !important",
              border: "2px solid white",
              borderRadius: "50%",
              backgroundColor: orange[400],
            }}
          />
        )}
      </FaroIconButton>

      <FaroMenu
        open={isMenuOpen}
        anchorEl={ref.current}
        onClose={() => closeMenu()}
        anchorOrigin={anchorOrigin}
      >
        <FaroMenuSection>Resource center</FaroMenuSection>

        {!!openChat && (
          <Box component="div">
            <FaroMenuItem
              label="Chat with us"
              Icon={ChatIcon}
              onClick={() => {
                openChat();
                setIsMenuOpen(false);
              }}
            />
            {!!numUnreadMsg && numUnreadMsg > 0 && (
              <Box
                component="div"
                data-testid="unread-messages-icon-2"
                sx={{
                  position: "absolute",
                  right: "20px",
                  marginTop: "-28px",
                  paddingTop: "1px",
                  width: "20px",
                  height: "20px",
                  textAlign: "center",
                  borderRadius: "50%",
                  fontSize: "12px",
                  backgroundColor: orange[400],
                  color: "white",
                }}
              >
                {numUnreadMsg}
              </Box>
            )}
          </Box>
        )}

        <FaroMenuItem
          label="Help Center"
          Icon={HelpCenterIcon}
          onClick={openHelpCenter}
        />
        <FaroMenuItem
          label="Videos"
          Icon={VideoIcon}
          onClick={openWalkthroughVideo}
        />

        {sendFeedback && (
          <FaroMenuItem
            label="Send feedback"
            Icon={FeedbackIcon}
            onClick={triggerSendFeedback}
          />
        )}
      </FaroMenu>
    </>
  );
}
