import { StoreFboPass as LotvStoreFboPass } from "@faro-lotv/lotv";
import { forwardRef, useEffect, useState } from "react";
import { attachPass } from "../attach-utils";

export type StoreFboPassProps = {
  /**
   * Whether the pass is enabled
   *
   * @default true
   */
  enabled?: boolean;
};

export type StoreFboPassObj = LotvStoreFboPass;

/**
 * @returns An EffectPipeline pass that stores a rendering intermediate result onto an offscreen FBO, for later use
 */
export const StoreFboPass = forwardRef<StoreFboPassObj, StoreFboPassProps>(
  function StoreFboPass(
    { enabled = true }: StoreFboPassProps,
    ref,
  ): JSX.Element {
    const [pass] = useState(() => new LotvStoreFboPass());

    // Disposing the GPU resources owned by the pass on component unmount
    useEffect(() => () => pass.dispose(), [pass]);

    return (
      <primitive
        ref={ref}
        object={pass}
        attach={attachPass}
        // Following props are part of the pass object
        enabled={enabled}
      />
    );
  },
);
