import { GUID } from "@faro-lotv/foundation";
import { useEffect } from "react";
import { Analytics } from "./analytics-service";
import { PrivateData } from "./analytics-types";

/**
 * Initialize the analytics client for the application unless the user is from either HoloBuilder or Faro
 *
 * @param key amplitude key
 * @param currentUserEmail current user email if an user is logged in
 * @param companyId Optional ID of the company to attach to every event
 * @param privateData Optional private data that should be censored from the event properties
 *  This should be a stable reference to avoid unnecessary plugin removals and additions
 */
export function useAnalyticsInitialization(
  key?: string,
  currentUserEmail?: string,
  companyId?: GUID,
  privateData?: PrivateData,
): void {
  // Censor private data from all events
  // The private data needs to be updated _before_ the analytics client is initialized
  // Otherwise some events might not be censored
  useEffect(() => {
    Analytics.privateData = privateData;
  }, [privateData]);

  // Add company ID to user data
  useEffect(() => {
    const props = companyId ? { userProps: { companyId } } : undefined;

    if (props !== undefined) {
      Analytics.props = { ...props };
    }
  }, [companyId]);

  // Initialize Amplitude client
  // This will also send the built-in page view event
  useEffect(() => {
    // Exclude @faro.com and @holobuilder.com and anonymous users from the analytics
    // we can't track anonymous users without a consent dialog that we're not implementing
    if (!key || isInternalUserEmail(currentUserEmail)) {
      return;
    }
    Analytics.init(key);
  }, [currentUserEmail, key]);
}

/**
 * check if user email is from @faro.com or @holobuilder.com or anonymous
 *
 * @param userEmail the given user email
 * @returns true if the given usr email is from @faro.com or @holobuilder.com or anonymous, false otherwise
 */
export function isInternalUserEmail(userEmail?: string): boolean {
  return (
    !userEmail ||
    userEmail.includes("@faro.com") ||
    userEmail.includes("@holobuilder.com")
  );
}
