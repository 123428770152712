import { useEffect } from "react";

/**
 * Override the current cursor with a different one when a condition is met
 *
 * @param cursor to apply
 * @param condition when to use the override cursor
 * @param domElement the element to apply the cursor override to
 */
export function useOverrideCursor(
  cursor: string,
  condition: boolean,
  domElement?: HTMLElement,
): void {
  useEffect(() => {
    const dom = domElement ?? document.body;
    const prevCursor = dom.style.cursor;
    if (condition && prevCursor !== cursor) {
      dom.style.cursor = cursor;
      return () => {
        dom.style.cursor = prevCursor;
      };
    }
  }, [condition, cursor, domElement]);
}
