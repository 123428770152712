import { MapPlaceholders, PlaceholdersTexture } from "@faro-lotv/lotv";
import {
  UPDATE_LOD_STRUCTURES_PRIORITY,
  useLotvDispose,
} from "@faro-lotv/spatial-ui";
import { useFrame, useThree } from "@react-three/fiber";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { Color, Vector3 } from "three";
import { useViewportRef } from "../hooks";
import {
  IMapPlaceholderVariants,
  useMapPlaceholderTextures,
} from "../hooks/use-map-placeholder-textures";
import {
  HiddenWaypointsComputer,
  useHideWaypoints,
} from "./use-hide-waypoints";
import { useWaypointsEvents } from "./use-waypoints-events";

/** Default pixel size of a placeholder */
const DEFAULT_BASE_SIZE = 20;

/** Default size factor applied to hovered/selected placeholders */
const DEFAULT_SIZE_FACTOR = 1.5;

/** Default size of the texture computed from the svg input files */
export const DEFAULT_WAYPOINT_TEXTURE_SIZE = 512;

export type MapWaypointsRendererProps = {
  /** Show/Hide placeholders @default true */
  visible?: boolean;

  /** Base pixel size to use to render the placeholder @default 20 */
  baseSize?: number;

  /** Size factor applied to the hovered placeholder @default 1.5 */
  hoveredSizeFactor?: number;

  /** Size factor applied to the selected placeholder @default 1.5 */
  selectedSizeFactor?: number;

  /** Position of the placeholders */
  waypoints: Vector3[];

  /** Overrides the hover state of the internal hover */
  hoveredId?: number;

  /** Id of the placeholder shown as selected */
  selectedId?: number;

  /** Callback executed when the id of the hovered element changes */
  onPlaceholderHovered?(id?: number): void;

  /** Callback to signal a placeholder have been clicked*/
  onPlaceholderClick?(id: number, ev: MouseEvent): void;

  /** Override specific textures */
  textures?: Partial<IMapPlaceholderVariants>;

  /**
   * Optional function to compute the visible placeholders at each frame.
   * The function should return an array of hidden indices.
   */
  computeHidden?: HiddenWaypointsComputer;

  /** Optional array with a custom color for each placeholder */
  customColors?: Color[];

  /** The render order */
  renderOrder?: number;
};

/**
 * Type of the reference returned by the MapWaypointsRenderer. It coincides
 * with the internal lotv MapPlaceholders object. However, we still prefer
 * to keep the two types separate as tomorrow the ref type may change
 * according to a new internal implementation.
 */
export type MapWaypointsRendererRef = MapPlaceholders;

/** @returns a renderer for placeholders on a 2d map */
export const MapWaypointsRenderer = forwardRef<
  MapWaypointsRendererRef,
  MapWaypointsRendererProps
>(function MapWaypointsRenderer(
  {
    waypoints: inputWaypoints,
    hoveredId: inputHoveredId,
    selectedId: inputSelectedId,
    baseSize = DEFAULT_BASE_SIZE,
    computeHidden,
    hoveredSizeFactor = DEFAULT_SIZE_FACTOR,
    onPlaceholderClick,
    onPlaceholderHovered,
    selectedSizeFactor = DEFAULT_SIZE_FACTOR,
    textures,
    visible = true,
    customColors,
    renderOrder,
  }: MapWaypointsRendererProps,
  ref,
): JSX.Element | null {
  const gl = useThree((s) => s.gl);
  const viewport = useViewportRef();
  const defaultTextures = useMapPlaceholderTextures(
    DEFAULT_WAYPOINT_TEXTURE_SIZE,
  );

  // True if the points have changed from the last time the sub-sampling was computed
  const havePointsChanged = useRef(true);

  const [placeholders, setPlaceholders] = useState(inputWaypoints);

  const mapPlaceholders = useMemo(() => {
    if (placeholders.length === 0) {
      return null;
    }
    return new MapPlaceholders(placeholders);
  }, [placeholders]);

  useEffect(() => {
    if (mapPlaceholders) {
      const disposeFnc = mapPlaceholders.onPlaceholdersChanged.on(
        () => (havePointsChanged.current = true),
      );
      return () => disposeFnc.dispose();
    }
  }, [mapPlaceholders, havePointsChanged]);

  // Assigning textures to the unlabeled placeholders
  useEffect(() => {
    if (!mapPlaceholders) return;
    mapPlaceholders.setMap(
      PlaceholdersTexture.Default,
      textures?.defaultTexture ?? defaultTextures.defaultTexture,
    );
    mapPlaceholders.setMap(
      PlaceholdersTexture.Hovered,
      textures?.hoveredTexture ?? defaultTextures.hoveredTexture,
    );
    mapPlaceholders.setMap(
      PlaceholdersTexture.Selected,
      textures?.selectedTexture ?? defaultTextures.selectedTexture,
    );
  }, [mapPlaceholders, textures, defaultTextures]);

  // Assigning size to the unlabeled placeholders
  useEffect(() => {
    if (!mapPlaceholders) return;
    const sz = baseSize * gl.getPixelRatio();
    mapPlaceholders.setSizes(0, {
      default: sz,
      hovered: sz * hoveredSizeFactor,
      selected: sz * selectedSizeFactor,
    });
  }, [mapPlaceholders, baseSize, hoveredSizeFactor, selectedSizeFactor, gl]);

  // Efficient placeholders position update, if the number of placeholders
  // have not changed the object can be kept alive and just updated
  useEffect(() => {
    if (inputWaypoints.length !== placeholders.length) {
      setPlaceholders(inputWaypoints);
    } else if (mapPlaceholders) {
      mapPlaceholders.updatePositions(inputWaypoints);
      havePointsChanged.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputWaypoints]);

  // Coloring the waypoints must be done after having updated the placeholders positions
  useEffect(() => {
    if (!mapPlaceholders) return;

    if (!customColors) {
      mapPlaceholders.colors = undefined;
      return;
    }

    // Map placeholders must be updated before updating the colors
    if (mapPlaceholders.count !== customColors.length) {
      return;
    }

    mapPlaceholders.colors = new Float32Array(
      customColors.map((c) => c.toArray()).flat(),
    );
  }, [customColors, mapPlaceholders]);

  const {
    hoveredIndex,
    selectedIndex,
    onClick,
    onPointerLeave,
    onPointerMove,
  } = useWaypointsEvents({
    hoveredPlaceholderId: inputHoveredId,
    selectedPlaceholderId: inputSelectedId,
    onWaypointClicked: onPlaceholderClick,
    onWaypointHovered: onPlaceholderHovered,
    indexFromHit: (hit) => hit?.index,
  });

  useHideWaypoints({
    waypointsObject: mapPlaceholders,
    updateHiddenWaypoints: computeHidden,
    allWaypoints: inputWaypoints,
    selectedIndex,
    havePointsChanged,
  });

  useEffect(() => {
    if (!mapPlaceholders) return;
    mapPlaceholders.hovered = hoveredIndex;
  }, [mapPlaceholders, hoveredIndex]);

  useEffect(() => {
    if (!mapPlaceholders) return;
    mapPlaceholders.selected = selectedIndex;
  }, [mapPlaceholders, selectedIndex]);

  useFrame(() => {
    if (!mapPlaceholders) return;
    mapPlaceholders.viewportHeight =
      viewport.current?.height && viewport.current.height * gl.getPixelRatio();
  }, UPDATE_LOD_STRUCTURES_PRIORITY);

  useLotvDispose(mapPlaceholders);

  if (!mapPlaceholders || inputWaypoints.length === 0 || !visible) {
    return null;
  }

  return (
    <primitive
      object={mapPlaceholders}
      ref={ref}
      // In map views (path adjustment mode, sheet mode) placeholders are
      // rendered in overlay to be always above the path and the floorplan.
      material-depthTest={false}
      material-depthWrite={false}
      onPointerMove={onPointerMove}
      onPointerLeave={onPointerLeave}
      onClick={onClick}
      renderOrder={renderOrder}
    />
  );
});

const EPSILON = 1e-6;

/**
 *
 * @param mapPlaceholders rendering object
 * @param points to render as placeholders
 * @returns true if the rendering object is updated and ready to rendering the new positions
 */
export function isMapPlaceholdersUpdated(
  mapPlaceholders: MapPlaceholders | null | undefined,
  points: Vector3[],
): mapPlaceholders is NonNullable<MapPlaceholders> {
  return (
    !!mapPlaceholders &&
    mapPlaceholders.count === points.length &&
    points.length > 0 &&
    mapPlaceholders.getPoint(0).distanceTo(points[0]) < EPSILON
  );
}
