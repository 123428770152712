/**
 * All functions in this file will take a number and return a number
 * having to repeat that documentation over and over is just noise
 * so I'll disable this lint rules for this file
 */
/* eslint-disable @typescript-eslint/no-magic-numbers */
/**
 * An easing function is a function that maps a value in the range [0:1] to a specific value following a curve
 * The most simple easing function is the linear (x) => x that return the passed value
 * Mostly used in animations to map between the time point to a point in the animation trajectory/curve
 * We will provide some easing function but you can always pass your desired easing if it meets this simple interface
 */
export type EasingFunction = (t: number) => number;

/**
 * A set of easing function to modify a 0-1 input to follow a given curve.
 * See {@link https://easings.net/} for a visualization of the various curves.
 */
export const Easing = {
  linear(pos: number): number {
    return pos;
  },

  inQuad(pos: number): number {
    return Math.pow(pos, 2);
  },

  outQuad(pos: number): number {
    return -(Math.pow(pos - 1, 2) - 1);
  },

  inOutQuad(pos: number): number {
    if ((pos /= 0.5) < 1) return 0.5 * Math.pow(pos, 2);
    return -0.5 * ((pos -= 2) * pos - 2);
  },

  inCubic(pos: number): number {
    return Math.pow(pos, 3);
  },

  outCubic(pos: number): number {
    return Math.pow(pos - 1, 3) + 1;
  },

  inOutCubic(pos: number): number {
    if ((pos /= 0.5) < 1) return 0.5 * Math.pow(pos, 3);
    return 0.5 * (Math.pow(pos - 2, 3) + 2);
  },

  inQuart(pos: number): number {
    return Math.pow(pos, 4);
  },

  outQuart(pos: number): number {
    return -(Math.pow(pos - 1, 4) - 1);
  },

  inOutQuart(pos: number): number {
    if ((pos /= 0.5) < 1) return 0.5 * Math.pow(pos, 4);
    return -0.5 * ((pos -= 2) * Math.pow(pos, 3) - 2);
  },

  inQuint(pos: number): number {
    return Math.pow(pos, 5);
  },

  outQuint(pos: number): number {
    return Math.pow(pos - 1, 5) + 1;
  },

  inOutQuint(pos: number): number {
    if ((pos /= 0.5) < 1) return 0.5 * Math.pow(pos, 5);
    return 0.5 * (Math.pow(pos - 2, 5) + 2);
  },

  inSine(pos: number): number {
    return -Math.cos(pos * (Math.PI / 2)) + 1;
  },

  outSine(pos: number): number {
    return Math.sin(pos * (Math.PI / 2));
  },

  inOutSine(pos: number): number {
    return -0.5 * (Math.cos(Math.PI * pos) - 1);
  },

  inExpo(pos: number): number {
    return pos === 0 ? 0 : Math.pow(2, 10 * (pos - 1));
  },

  outExpo(pos: number): number {
    return pos === 1 ? 1 : -Math.pow(2, -10 * pos) + 1;
  },

  inOutExpo(pos: number): number {
    if (pos === 0) return 0;
    if (pos === 1) return 1;
    if ((pos /= 0.5) < 1) return 0.5 * Math.pow(2, 10 * (pos - 1));
    return 0.5 * (-Math.pow(2, -10 * --pos) + 2);
  },

  inCirc(pos: number): number {
    return -(Math.sqrt(1 - pos * pos) - 1);
  },

  outCirc(pos: number): number {
    return Math.sqrt(1 - Math.pow(pos - 1, 2));
  },

  inOutCirc(pos: number): number {
    if ((pos /= 0.5) < 1) return -0.5 * (Math.sqrt(1 - pos * pos) - 1);
    return 0.5 * (Math.sqrt(1 - (pos -= 2) * pos) + 1);
  },

  outBounce(pos: number): number {
    if (pos < 1 / 2.75) {
      return 7.5625 * pos * pos;
    } else if (pos < 2 / 2.75) {
      return 7.5625 * (pos -= 1.5 / 2.75) * pos + 0.75;
    } else if (pos < 2.5 / 2.75) {
      return 7.5625 * (pos -= 2.25 / 2.75) * pos + 0.9375;
    }
    return 7.5625 * (pos -= 2.625 / 2.75) * pos + 0.984375;
  },

  inBack(pos: number): number {
    const s = 1.70158;
    return pos * pos * ((s + 1) * pos - s);
  },

  outBack(pos: number): number {
    const s = 1.70158;
    return (pos = pos - 1) * pos * ((s + 1) * pos + s) + 1;
  },

  inOutBack(pos: number): number {
    let s = 1.70158;
    if ((pos /= 0.5) < 1) {
      return 0.5 * (pos * pos * (((s *= 1.525) + 1) * pos - s));
    }
    return 0.5 * ((pos -= 2) * pos * (((s *= 1.525) + 1) * pos + s) + 2);
  },

  swingFromTo(pos: number): number {
    let s = 1.70158;
    return (pos /= 0.5) < 1
      ? 0.5 * (pos * pos * (((s *= 1.525) + 1) * pos - s))
      : 0.5 * ((pos -= 2) * pos * (((s *= 1.525) + 1) * pos + s) + 2);
  },

  swingFrom(pos: number): number {
    const s = 1.70158;
    return pos * pos * ((s + 1) * pos - s);
  },

  swingTo(pos: number): number {
    const s = 1.70158;
    return (pos -= 1) * pos * ((s + 1) * pos + s) + 1;
  },

  bouncePast(pos: number): number {
    if (pos < 1 / 2.75) {
      return 7.5625 * pos * pos;
    } else if (pos < 2 / 2.75) {
      return 2 - (7.5625 * (pos -= 1.5 / 2.75) * pos + 0.75);
    } else if (pos < 2.5 / 2.75) {
      return 2 - (7.5625 * (pos -= 2.25 / 2.75) * pos + 0.9375);
    }
    return 2 - (7.5625 * (pos -= 2.625 / 2.75) * pos + 0.984375);
  },

  fromTo(pos: number): number {
    if ((pos /= 0.5) < 1) return 0.5 * Math.pow(pos, 4);
    return -0.5 * ((pos -= 2) * Math.pow(pos, 3) - 2);
  },

  from(pos: number): number {
    return Math.pow(pos, 4);
  },

  to(pos: number): number {
    return Math.pow(pos, 0.25);
  },

  inOutElastic(pos: number): number {
    const c5 = (2 * Math.PI) / 4.5;
    if (pos === 0) {
      return 0;
    } else if (pos === 1) {
      return 1;
    }
    return pos < 0.5
      ? -(Math.pow(2, 20 * pos - 10) * Math.sin((20 * pos - 11.125) * c5)) / 2
      : (Math.pow(2, -20 * pos + 10) * Math.sin((20 * pos - 11.125) * c5)) / 2 +
          1;
  },
};

/**
 * Get the 0-1 progress value between a start time and and end time
 * (May go outside 0-1 if tNow > t2 or tNow < t1)
 *
 * @param t1 The start time for the animation
 * @param t2 The end time for the animation
 * @param tNow The current time
 * @returns percent progress between t1 and t2
 */
export function getProgress(t1: number, t2: number, tNow: number): number {
  return (tNow - t1) / (t2 - t1);
}
