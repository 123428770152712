import {
  RetryOptions,
  TypeGuard,
  exponentialBackOff,
  log,
  retry,
} from "@faro-lotv/foundation";
import { StatusCodes } from "http-status-codes";
import { clientIdHeader } from "../utils/headers";
import { ApiResponseError, ApiValidationError } from "./api-error";
import { TokenProvider } from "./auth-types";

export interface SendAuthenticatedRequestParams {
  /** The base URL of the endpoint. */
  baseUrl?: string;

  /** The request path, _without_ the base URL. */
  path: string;

  /** Additional headers to the http request. */
  additionalHeaders?: HeadersInit;

  /** Use to include credentials in the request */
  credentials?: RequestCredentials;

  /** A signal to abort this fetch */
  signal?: AbortSignal;

  /** Payload to send with the request. */
  requestBody?: RequestInit["body"];

  /** Query parameters to attach to the request URL. */
  queryParams?: Record<string, string | undefined>;

  /** HTTP method to use for the request (e.g. `"GET"` or `"POST"`). */
  httpMethod?: RequestInit["method"];

  /** The ID of the client that the request originates from. */
  clientId?: string;

  /** A function to generate valid authentication tokens. */
  tokenProvider?: TokenProvider;

  /** Set true to throw an ApiResponseError if the returned call status != 2xx @default true*/
  throwOnError?: boolean;

  /** A callback function which will be executed on an authentication error */
  onAuthenticationError?(): void;

  /**
   * Delay to apply between failed requests.
   *
   * @default exponentialBackOff
   */
  retryDelay?: RetryOptions["delay"];
}

/** the minimum error code value that will be automatically retried */
const MIN_ERROR_CODE_TO_RETRY = 500;

/** max number of retries for a network request */
const MAX_RETRIES = 3;

/**
 * Send a request to a backend and authenticate it with the given `tokenProvider`.
 * Retry it with exponential back-off in case of network/server failures
 *
 * @param params to use for the request
 * @returns The response of the request.
 * @throws if the request failed (non 200 response)
 */
export function sendAuthenticatedRequest({
  retryDelay = exponentialBackOff,
  ...params
}: SendAuthenticatedRequestParams): Promise<Response> {
  return retry(() => sendAuthenticatedRequestBase(params), {
    max: MAX_RETRIES,
    delay: retryDelay,
    shouldRetry: (reason) =>
      reason instanceof ApiResponseError &&
      reason.status >= MIN_ERROR_CODE_TO_RETRY &&
      (params.httpMethod ?? "GET").toUpperCase() === "GET",
  });
}

/**
 * Send a request to a backend and authenticate it with the given `tokenProvider`.
 *
 * This function uses `fetch` to execute the request.
 * The bearer token is generated with the `tokenProvider`.
 * The `clientId` is used to identify the client to the endpoint.
 *
 * @returns The response of the request.
 * It has already been checked that the status code is ok.
 */
async function sendAuthenticatedRequestBase({
  baseUrl,
  path,
  additionalHeaders = {},
  signal,
  queryParams = {},
  credentials,
  httpMethod: method = "GET",
  requestBody: body,
  clientId,
  throwOnError = true,
  tokenProvider,
  onAuthenticationError,
}: SendAuthenticatedRequestParams): Promise<Response> {
  const url = new URL(path, baseUrl);

  for (const [key, value] of Object.entries(queryParams)) {
    if (value) url.searchParams.append(key, value);
  }

  const token = tokenProvider ? await tokenProvider() : undefined;

  const response = await retry(
    () =>
      fetch(url.toString(), {
        method,
        signal,
        credentials,
        headers: new Headers({
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
          ...clientIdHeader(clientId),
          ...additionalHeaders,
        }),
        body,
      }),
    {
      delay: exponentialBackOff,
    },
  );

  if (response.status === StatusCodes.FORBIDDEN) {
    onAuthenticationError?.();
  }

  if (!response.ok && throwOnError) {
    let responseBody;

    try {
      responseBody = await response.json();
    } catch {
      // The API did not respond with valid JSON
      responseBody = response.body;
    }

    throw new ApiResponseError(
      response.status,
      response.statusText,
      responseBody,
    );
  }

  return response;
}

export type SendAuthenticatedJsonRequestParams<T> = Omit<
  SendAuthenticatedRequestParams,
  "requestBody"
> & {
  /** An object to use as the request body, will be `JSON.strigify`ed. */
  requestBody?: unknown;

  /** A type guard to validate the response object. */
  typeGuard?: TypeGuard<T>;
};
export type SendTypedAuthenticatedJsonRequestParams<T> =
  SendAuthenticatedJsonRequestParams<T> & { typeGuard: TypeGuard<T> };

export async function sendAuthenticatedJsonRequest<T>(
  params: SendTypedAuthenticatedJsonRequestParams<T>,
): Promise<T>;
/**
 * Send an authenticated request to the backend which contains and expects JSON.
 *
 * - Request authentication via the `tokenProvider`.
 * - Sets headers to enforce JSON format.
 * - Checks if the response status code is ok.
 * - Deserializes the JSON response.
 * - If given, validates the response object with the `typeGuard`.
 *
 * @param params The configuration for the API request.
 * @returns The deserialized JSON response.
 */
export async function sendAuthenticatedJsonRequest<T>(
  params: SendAuthenticatedJsonRequestParams<T>,
): Promise<unknown> {
  const { requestBody, additionalHeaders, typeGuard } = params;

  const response = await sendAuthenticatedRequest({
    ...params,
    requestBody: requestBody ? JSON.stringify(requestBody) : undefined,
    additionalHeaders: {
      ...additionalHeaders,
      // We send JSON in the request body (if provided)
      "content-type": "application/json",
      // The response must contain JSON in the body
      accept: "application/json",
    },
  });

  // response.json() cannot handle an empty response
  const responseText = await response.text();
  const deserializedResponse: unknown =
    responseText.length === 0 ? undefined : JSON.parse(responseText);

  if (typeGuard && !typeGuard(deserializedResponse)) {
    log(
      "API response validation failed:",
      params.httpMethod ?? "GET",
      params.path,
      deserializedResponse,
    );
    throw new ApiValidationError(deserializedResponse);
  }

  return deserializedResponse;
}
