import { GUID, SignedUrl, SignedUrlData } from "@faro-lotv/foundation";
import { IElementWithFileUri } from "@faro-lotv/ielement-types";
import { ProjectApi, makeIElementUriSigner } from "@faro-lotv/service-wires";

/**
 * Request an updated and valid signed url for a project resource.
 * When the signed URL is required immediately by a component use useSignedUrl hook
 *
 * @param projectApi client class to request new signatures
 * @param uri the uri to sign
 * @param elementId the id of the element that contains this resource
 * @param initialSignature the initial signature for this uri if available
 * @returns a valid signed url for the requested resource if available
 */
export async function requestSignedUrlForIElement(
  projectApi: ProjectApi,
  uri: string,
  elementId: GUID,
  initialSignature?: SignedUrlData,
): Promise<string | Error> {
  return await new SignedUrl(
    uri,
    makeIElementUriSigner(projectApi, elementId),
    initialSignature,
  )
    .requestSignedUrl()
    .catch((reason) => (reason instanceof Error ? reason : new Error(reason)));
}

/**
 * @returns a SignedUrl instance for the `uri` field of an `IElementWithFileUri`.
 * @param projectApi The project api instance to use for signing
 * @param element The element to create a SignedUrl for
 */
export function createSignedUrlForIElementUri(
  projectApi: ProjectApi,
  element: IElementWithFileUri,
): SignedUrl {
  return new SignedUrl(
    element.uri,
    makeIElementUriSigner(projectApi, element.id),
    element.signedUri && element.signedUriExpiresOn
      ? {
          signedUrl: element.signedUri,
          expiresOn: new Date(element.signedUriExpiresOn),
        }
      : undefined,
  );
}
