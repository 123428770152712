import { assert, Easing, EasingFunction, getProgress } from "@faro-lotv/foundation";
import { Interpolation, InterpolationFunction } from "../Utils/Interpolation";
import { LotvAnimation } from "./Animation";

/** Generic interface of an object with a copy function */
interface CopyableObject<T> {
	copy(value: T): void;
}

/**
 * Check if the input object contains a copy function
 *
 * @param object The input object
 * @returns True if the object has a copy function
 */
function hasCopy<T>(object: T): object is T & CopyableObject<T> {
	return typeof object === "object" && object !== null && "copy" in object;
}

/**
 * Check the description of a class member in the entire prototype chain
 *
 * @param obj The object we want to check the property descriptor
 * @param prop The property name
 * @returns The descriptor
 */
function getPropertyDescriptor<T>(obj: T, prop: keyof T): PropertyDescriptor | undefined {
	let desc;
	do {
		desc = Object.getOwnPropertyDescriptor(obj, prop);
		if (desc !== undefined) return desc;
	} while ((obj = Object.getPrototypeOf(obj)));
	return undefined;
}

/**
 * Check if the input object property is writable
 *
 * @param object The input object
 * @param key The property to test
 * @returns True if the property is not write protected
 */
function isWritable<T>(object: T, key: keyof T): boolean {
	const desc = getPropertyDescriptor(object, key);
	if (desc === undefined) return false;
	return Boolean(desc.writable ?? desc.set);
}

/**
 * Copy a value into an object property by using the copy function
 *
 * @param object The object containing the property
 * @param key The property to update
 * @param newValue The new value of the property
 */
function useCopyFunction<T, K extends keyof T>(object: T, key: K, newValue: T[K]): void {
	const property = object[key];
	assert(hasCopy(property), "useCopyFunction cannot be used with a not copyable object");
	property.copy(newValue);
}

/**
 * Copy a value into an object property by using the assignment operator
 *
 * @param object The object containing the property
 * @param key The property to update
 * @param newValue The new value of the property
 */
function useAssignOperator<T, K extends keyof T>(object: T, key: K, newValue: T[K]): void {
	object[key] = newValue;
}

/**
 * Copy a value into an object property by using the assign function of an object
 *
 * @param object The object containing the property
 * @param key The property to update
 * @param newValue The new value of the property
 */
function useAssignFunction<T, K extends keyof T>(object: T, key: K, newValue: T[K]): void {
	const property = object[key];
	assert(property, "Property must be a valid object");
	assert(newValue, "NewValue must be a valid object");
	Object.assign(property, newValue);
}

type CopyFunction<T, K extends keyof T> = (object: T, key: K, newValue: T[K]) => void;

/**
 * Options used to customize an object property animation
 */
export type ObjectPropertyAnimationOptions<T> = {
	/** The animation duration in seconds */
	duration: number;
	/** The function used to manipulate the percentage of completion of the animation */
	ease: EasingFunction;
	/** The function used to interpolate the property during the animation */
	interpolate: InterpolationFunction<T>;
};

/**
 * A type predicate to check if something is an object
 *
 * @param x The variable to test
 * @returns True if it is an object
 */
function isObject(x: unknown): x is object {
	return !!x && typeof x === "object";
}

/**
 * An animation that can animate any numerical property of an object
 */
export class ObjectPropertyAnimation<T, K extends keyof T> extends LotvAnimation {
	/** The current time of the animation */
	elapsed = 0;

	/** The value from which the property animation should start */
	from: T[K];

	/** The value to which the property animation should end */
	to: T[K];

	/**
	 * The overall duration of the animation, in seconds
	 */
	duration: number;

	/** The easing function used to manipulate the time percentage */
	ease: EasingFunction;

	/** The function used to interpolate the desired property */
	interpolate: InterpolationFunction<T[K]>;

	/**
	 * The function that performs the actual copy of the new value
	 * to the stored property.
	 */
	#copyValue: CopyFunction<T, K>;

	/**
	 *
	 * @param object The @see Object3D to move
	 * @param property The name of the property to animate, must be of type T
	 * @param from The start value
	 * @param to The end value
	 * @param options The options for this animation
	 */
	constructor(
		private object: T,
		private property: K,
		from: T[K],
		to: T[K],
		options: Partial<ObjectPropertyAnimationOptions<T[K]>> = {},
	) {
		super();

		if (isObject(to) && isObject(from)) {
			this.to = Object.create(to);
			this.from = Object.create(from);

			assert(this.to, "'to' must be a valid object");
			assert(this.from, "'from' must be a valid object");

			Object.assign(this.to, to);
			Object.assign(this.from, from);
		} else if (typeof to === "bigint" || typeof to === "number") {
			this.to = to;
			this.from = from;
		} else {
			throw new TypeError(`Cannot animate type of ${typeof to}`);
		}

		this.duration = options.duration ?? 1;
		this.ease = options.ease ?? Easing.inOutCubic;
		if (options.interpolate) {
			this.interpolate = options.interpolate;
		} else {
			const interpolation = Interpolation.getDefaultInterpolant<T[K]>(object[property]);
			if (!interpolation) {
				throw new Error("Unknown property type in ObjectPropertyAnimation.");
			}
			this.interpolate = interpolation;
		}

		if (hasCopy(object[property])) {
			this.#copyValue = useCopyFunction;
		} else if (isWritable<T>(object, property)) {
			this.#copyValue = useAssignOperator;
		} else {
			this.#copyValue = useAssignFunction;
		}
	}
	/**
	 * Updates the objects rotation given the elapsed time
	 *
	 * @param elapsed the seconds since the last call to update
	 * @returns true if this update completed the animation, otherwise false
	 */
	update(elapsed: number): boolean {
		this.elapsed += elapsed;
		const progress = getProgress(0, this.duration, this.elapsed);
		if (progress >= 1 || this.canceled) {
			this.#copyValue(this.object, this.property, this.to);
			this.completed.emit(this);
			return true;
		}
		const easedProgress = this.ease(progress);
		this.#copyValue(this.object, this.property, this.interpolate(this.from, this.to, easedProgress));
		return false;
	}
}
