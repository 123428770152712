import { PropsWithChildren, ReactNode } from "react";
import { FaroButton } from "../button/faro-button";
import { FaroText } from "../text/faro-text/faro-text";
import { ToolGroup } from "./tool-group";
import { Toolbar } from "./toolbar";

export type ContextualToolbarProps = PropsWithChildren<{
  /** The label to show at the start of the toolbar, e.g. the count of selected elements. */
  label: ReactNode;

  /** The action to execute when the user clears the selection. */
  clearSelection?(): void;
}>;

/**
 * @returns A toolbar offering contextual actions on the selected elements.
 *
 * It offers:
 * - A label, e.g. showing the number of selected elements.
 * - Optionally, a set of actions to perform on the selected elements.
 *    This can be provided via the `children` prop, which should be a set of `ToolGroup` components.
 * - A button to clear the selection.
 */
export function ContextualToolbar({
  label,
  clearSelection,
  children,
}: ContextualToolbarProps): JSX.Element | null {
  return (
    <Toolbar
      direction="row"
      sx={{ alignItems: "center", width: "fit-content" }}
    >
      <ToolGroup sx={{ p: 1.5 }}>
        <FaroText variant="bodyM" dark>
          {label}
        </FaroText>
      </ToolGroup>
      {children}
      <ToolGroup>
        <FaroButton
          variant="ghost"
          dark
          sx={{ p: 1.5 }}
          onClick={clearSelection}
        >
          Clear selection
        </FaroButton>
      </ToolGroup>
    </Toolbar>
  );
}
